.modal-overlay .modal-window.how-get-card-modal {
  margin: auto;
  background: #fff;
  max-width: 1060px;
  padding: 0 0 50px;
}
@media (max-width: 768px) {
  .how-get-card__panel {
    position: relative;
  }
}
.how-get-card__back-link {
  margin: 3px 0 0;
  display: inline-block;
}
.how-get-card__head {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  margin: 40px 0 30px;
}
.how-get-card__heading {
  margin: 0;
}
.how-get-card__rules {
  margin-left: auto;
}
@media (max-width: 768px) {
  .how-get-card__rules {
    position: absolute;
    left: 20px;
    bottom: 102px;
  }
}
.how-get-card__ol {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 12px 0 0 -30px;
  min-height: 130px;
}
@media (max-width: 768px) {
  .how-get-card__ol {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
  }
}
.how-get-card__ol > li {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 33%;
  -ms-flex: 0 1 33%;
  flex: 0 1 33%;
  margin-left: 30px;
}
@media (max-width: 768px) {
  .how-get-card__ol > li {
    margin-left: 0;
  }
}
.how-get-card__ol > li:before {
  font-size: 80px;
  margin-top: -12px;
}
@media (max-width: 768px) {
  .how-get-card__ol > li:before {
    font-size: 50px;
    min-width: 18px;
    margin-top: -8px;
  }
}
.how-get-card__btn-holder {
  margin: 50px auto 0;
  text-align: center;
}
.how-get-card__go-home {
  min-width: 180px;
}
/*# sourceMappingURL=src/js/components/Modals/HowGetCardModal/HowGetCardModal.css.map */