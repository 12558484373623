.no-purchase
	display flex
	flex-direction column
	align-items center
	img
		width 176px
		margin 16px
	.no-purchase-text
		margin 25px
		width 260px
		font-family Exo
		font-size 16px
		font-weight 100
		lh(20)
		text-align center
	.btn.btn-primary
		margin 5px
		width 130px
		height 40px
		font-family Exo
		font-size 14px
		font-weight 700
		lh(16)
		text-align center
		text-transform none
		cursor pointer


