@import "~vars"

.card-select-store-modal
	+phone()
		padding 40px 0!important
		margin-left 20px!important


.nearest-store-panel
	+phone()
		padding 0!important

.nearest-store-form
	margin auto
	max-width 650px
	padding 0
	font-size 16px
	lh(20)
	+tablet()
		padding 0
	.subscribe-stores
		display flex
		.store-fields-wrapper
			width 100%
			align-items: flex-start
			+phone()
				box-flex 1
				flex 0 0 calc(100% - 50px)
				width calc(100% - 50px)
			.form__field
				.form__select-holder
					+phone()
						padding-top 0
					.form__label--select
						+phone()
							display none
			.form__field .form__select-holder .form__label--select:not(.not-empty):not(.focused)
				+phone()
					display block
		.store-buttons-wrapper
			box-flex 1
			flex 0 0 30px
			align-self center
			.notvisible
				visibility hidden

		flex: 0 0 30px;
	.subscribe-form__stores
		padding 0
	.subscribe-form__stores-list
		border 0
		padding 0

		&:before
		&:after
			display none

.nearest-store-form__message
	opacity .7
	margin 36px 0
	max-width 450px
	+phone()
		max-width 100%

.select-form__stores-btn
	padding 0

.select-store
	+phone()
		margin 30px auto 20px
		min-width 180px
		display block