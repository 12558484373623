.my-contacts__data {
  margin: 0;
}
@media (max-width: 500px) {
  .my-contacts__data {
    padding: 15px;
  }
}
.my-contacts__data hr {
  margin-bottom: 50px;
}
@media (max-width: 500px) {
  .my-contacts__data hr {
    margin-bottom: 15px;
  }
}
.my-contacts__data .form__input-holder {
  padding-top: 0;
}
@media (max-width: 500px) {
  .my-contacts__data .form__input-holder {
    padding-top: 28px;
  }
}
.my-contacts__data .my-contacts__data-heading {
  margin: 22px 0;
}
@media (max-width: 768px) {
  .my-contacts__data .my-contacts__data-heading {
    margin: 0;
  }
}
.my-contacts__data .my-contacts__list {
  max-width: 520px;
  margin: 22px 0 25px;
  padding: 0;
}
@media (max-width: 500px) {
  .my-contacts__data .my-contacts__list {
    margin: 0 0 25px 0;
  }
}
.my-contacts__data .my-contacts__list li:not(:first-child) {
  margin-top: 40px;
}
@media (max-width: 500px) {
  .my-contacts__data .my-contacts__list li:not(:first-child) {
    margin-top: 20px;
  }
}
.my-contacts__data .my-contacts__list-item {
  border-bottom: 1px solid #e8e8e8;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  position: relative;
}
@media (max-width: 500px) {
  .my-contacts__data .my-contacts__list-item {
    padding-top: 28px;
  }
}
@media (max-width: 768px) {
  .my-contacts__data .my-contacts__list-item {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    position: relative;
  }
}
@media (max-width: 768px) {
  .my-contacts__data .my-contacts__list .inline-row {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.my-contacts__data .my-contacts__list-item-label {
  position: absolute;
  bottom: 100%;
}
@media (max-width: 500px) {
  .my-contacts__data .my-contacts__list-item-label {
    top: 0;
    bottom: unset;
  }
}
.my-contacts__data .my-contacts__list-item-value {
  margin: auto 0;
  min-height: 41px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .my-contacts__data .my-contacts__list-item-value {
    margin: 0;
  }
}
.my-contacts__data .my-contacts__list-item-value .innerText {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.my-contacts__data .my-contacts__list .inline-row__buttons,
.my-contacts__data .my-contacts__list-item-controls {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.my-contacts__data .my-contacts__list-item-controls {
  margin: auto 0;
}
@media (max-width: 768px) {
  .my-contacts__data .my-contacts__list-item-controls {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.my-contacts__data .my-contacts__list-item-button {
  padding: 10px;
  color: #a2a2a2;
}
.my-contacts__data .my-contacts__list-item-button:not(:disabled):hover {
  color: #333;
}
/*# sourceMappingURL=src/js/components/MyContactsData/MyContactsData.css.map */