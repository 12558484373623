.purchase-product-wrapper {
  height: 78px;
  border-top: 2px solid #f7f7f7;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  margin: 0 14px;
}
.purchase-product-wrapper.isDeliveryItem:hover {
  background: none;
  cursor: default;
}
@media (max-width: 768px) {
  .purchase-product-wrapper {
    margin: 0 8px;
    padding: 10px 0;
    height: 58px;
  }
}
.purchase-product-wrapper:hover {
  padding: 16px 14px;
  margin: 0;
  background: #f8f8f8;
}
.purchase-product-wrapper:hover + li {
  border-top: 2px solid transparent;
}
@media (max-width: 768px) {
  .purchase-product-wrapper:hover {
    padding: 10px 8px;
  }
}
.purchase-product-wrapper:first-child {
  border-top: none;
}
.purchase-product-wrapper .purchase-product-img {
  width: 46px;
  height: 46px;
}
@media (max-width: 768px) {
  .purchase-product-wrapper .purchase-product-img {
    width: 32px;
    height: 32px;
  }
}
.purchase-product-wrapper .purchase-product-info {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-left: 12px;
  height: 100%;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .title {
  font-size: 16px;
  line-height: 16px;
  max-height: 18px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
@media (max-width: 768px) {
  .purchase-product-wrapper .purchase-product-info .purchase-product-info-row .title {
    font-size: 12px;
  }
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .title span {
  height: 16px;
  display: inline-block;
  padding: 1px 4px;
  vertical-align: text-bottom;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px;
  background: #79b928;
  color: #fff;
  margin-right: 6px;
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .status,
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .price {
  font-size: 14px;
  line-height: 16px;
  font-family: "ExoBold", Helvetica, Arial, sans-serif;
}
@media (max-width: 768px) {
  .purchase-product-wrapper .purchase-product-info .purchase-product-info-row .status,
  .purchase-product-wrapper .purchase-product-info .purchase-product-info-row .price {
    font-size: 12px;
  }
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .unit {
  color: #a2a2a2;
  font-size: 14px;
  line-height: 16px;
  position: relative;
}
@media (max-width: 768px) {
  .purchase-product-wrapper .purchase-product-info .purchase-product-info-row .unit {
    font-size: 12px;
  }
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .unit:hover .hint-text {
  display: inline;
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .hint {
  margin-left: 4px;
}
.purchase-product-wrapper .purchase-product-info .purchase-product-info-row .hint-text {
  position: absolute;
  background: #383838;
  padding: 10px;
  color: #fff;
  max-width: 195px;
  width: max-content;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
  margin-left: 8px;
  display: none;
}
.isDeleted {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
.item-checkbox {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
}
.item-checkbox,
.item-checkbox-label {
  cursor: pointer;
  margin-right: 5px;
}
.item-checkbox + .item-checkbox-label:before {
  content: '';
  background-image: url("/images/fora/icon-checkbox-default.svg");
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
}
.item-checkbox:checked + .item-checkbox-label:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("/images/fora/icon-checkbox-checked.svg");
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/PurchasesList/components/PurchaseProducts/PurchaseProducts.css.map */