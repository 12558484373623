@import "~vars"

.row
	@media screen and (max-width: 1024px)
		flex-direction column
		text-align center
		align-content center
		align-items center

.footer
	background-color: $footer-bg-color
	font-size: 14px
	padding-bottom 110px
	line-height: 24px
	color: $gray
	&.fora
		padding: 40px 20px
		font-family: $font
		.logo-column
			padding-right 10px
	&.silpo
		padding: 15px 75px 15px 95px
		font-family: $pfdintextcond
	@media screen and (max-width: 1624px)
		padding 30px
	.row
		.col-3:first-child
			+tablet()
				order 9
				padding 35px 0 0 0

.logo
		#logo
			padding 0
			#logo-svg
				width 200px
				max-width 100%

	@media screen and (max-width: 1024px)
		width: 150px

.phone-block
	margin-top: 35px
	margin-bottom: 16px

	@media screen and (max-width:1024px)
		margin-bottom 19px

span.footer-title
	font-size: 18px
	font-family $font
	cursor: text
	transition 1s
	&:hover
		color:#fff

.footer-title
	color: #ffffff
	font-size: 16px
	line-height: 32px
	transition: 1s
	&.s
		text-transform uppercase
		font-weight $medium
	&:hover
		text-decoration: none
		color: $primary
		cursor: pointer

.footer-block  div  a.footer-title
	font-size: 18px

.footer-widget-title
	cursor: text
	font-family  $font
	font-weight 700
	font-size : 16px
	color : #ffffff
	line-height : 18px
	margin-bottom : 7px
	&.s
		font-family $pfdintextcond
		cursor pointer
		text-transform uppercase
		font-weight $medium
		&:hover
			color: $primary
			transition 1s
	&:hover
		color: #ffffff

.footer-phone-number
	display: block
	margin-top: 2px
	font-size: 24px
	color: $primary
	font-weight:400
	font-family $font
	transition 1s
	&:hover
		color: #fff
		text-decoration: none

.callback-link
	display: block
	margin-bottom: 55px
	font-family: $font
	transition 1s

	@media screen and (max-width: 1024px)
		display none
	&:hover
		color: $primary
		text-decoration: none

.email-label
	font-size: 14px
	margin-bottom: 16px
	font-family: $font
	transition : 1s

	@media screen and (max-width:1024px)
		font-size 18px
	&:hover
		color:#fff

#email-input
	width: 100%
	height: 40px
	border: none
	padding: 15px 15px 10px
	color: #969696!important
	font-size: 18px
	font-family: $font
	font-style: italic

.input-block
	@media screen and (max-width:1024px)
		margin: 0 13px

.socials
	display flex
	flex-direction row
	margin-top: 42px
	@media screen and (max-width:1024px)
		text-align -webkit-center
		justify-content center
		margin: 22px auto

	.social-link
		display flex
		flex-direction column
		align-items center
		justify-content center
		size(45px)
		margin-right 5px
		text-decoration none
		svg
			size(35px)
			transition: 0.3s
		&:hover
			svg
				size(45px)

.footer-list
	list-style: none
	padding: 0
	margin-bottom: 29px

.footer-widget:nth-child(2)
	padding-left: 11.5px

	@media screen and (max-width: 1024px)
		order:2
		padding:0

.footer-widget:nth-child(3)
	padding-left: 8px

	@media screen and (max-width: 1024px)
		order: 1
		padding 0
		margin-top 20px

.footer-widget:nth-child(4)
	padding-left: 4.5px
	margin-top: -5px

	@media screen and (max-width: 1024px)
		padding 0 15px
		margin-top $gutter
		margin-bottom 65px

li.footer-list-item
	font-family $font
	line-height 27px
	&.s
		font-family $proxima
		a
			font-weight 400
	.footer-list-link
		&:hover
			color: #fff

.footer .version-link
	float right
	color #7e7d7d
	padding 3px
