.password-panel {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.password-panel .password-holder {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.password-panel .password-button {
  padding: 0 10px;
}
.password-panel .password-button .icon {
  color: #a2a2a2;
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=src/js/components/Form/PasswordInput/PasswordInput.css.map */