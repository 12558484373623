.mobile-apps-block {
  background-color: transparent;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.mobile-apps-block .mobile-apps-block__links-wrapper {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
@media (max-width: 500px) {
  .mobile-apps-block .mobile-apps-block__links-wrapper {
    display: block;
  }
}
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block-link:first-child {
  margin-right: 40px;
}
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__iOS-link-block,
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__android-link-block {
  width: 154px;
}
.mobile-apps-block .mobile-apps-block__iOS-link-block-mobile,
.mobile-apps-block .mobile-apps-block__android-link-block-mobile {
  width: 154px;
}
.mobile-apps-block .mobile-apps-block__iOS-link-block-mobile a svg,
.mobile-apps-block .mobile-apps-block__android-link-block-mobile a svg {
  width: 100%;
  height: auto;
}
.mobile-apps-block .mobile-apps-block__title {
  font-size: 16px;
  padding-bottom: 10px;
  font-family: "Lato";
}
.mobile-apps-block .mobile-apps-block__title a {
  outline: none;
}
.mobile-apps-block .mobile-apps-block__title a span {
  text-transform: uppercase;
  font-size: 13px;
  border-bottom: 2px solid rgba(138,138,138,0.3);
}
.mobile-apps-block .mobile-apps-block__qr-code-wrapper {
  margin-top: 10px;
}
.mobile-apps-block .mobile-apps-block__qr-code-wrapper img {
  width: 200px;
}
@media (max-width: 500px) {
  .mobile-apps-block .mobile-apps-block__qr-code-wrapper img {
    display: none;
  }
}
.mobile-apps-block .mobile-apps-block__title-custom a {
  outline: none;
  border-bottom: 2px solid rgba(138,138,138,0.3);
  margin-bottom: 10px;
  display: inline-block;
  font-family: "Lato";
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .mobile-apps-block .mobile-apps-block__title-custom a {
    font-size: 16px;
  }
}
.mobile-apps-block .mobile-apps-block__links-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .mobile-apps-block .mobile-apps-block__links-wrapper {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__iOS-link-block,
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__android-link-block {
  width: 45%;
  text-align: center;
}
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__iOS-link-block a,
.mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__android-link-block a {
  outline: none;
}
@media (max-width: 1300px) {
  .mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__iOS-link-block,
  .mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__android-link-block {
    width: 45%;
  }
}
.mobile-apps-block .mobile-apps-block__links-wrapper svg {
  width: 100%;
  height: auto;
}
.mobile-apps-block {
  background: #79b928;
}
.mobile-apps-block__title-custom a {
  color: #fff;
  font-size: 16px;
}
/*# sourceMappingURL=src/js/components/Layout/Menu/SidebarMenu/AppsBlock/AppsBlock.base.css.map */