@import "~vars"

.icon
	display inline-block
	vertical-align middle
	img
	svg
		display block
	&:not(.raw)
		svg
			path
			polygon
			rect
			circle
				fill currentColor


// SHAPE
.icon-shape--circle
	border-radius 100%
	size(28px)
	display inline-flex
	background $primary
	color white
	margin -2px 15px 0 10px

	svg
		margin auto

// THEME
.icon-theme--orange
.icon-theme--violet
.icon-theme--cyan
.icon-theme--green
.icon-theme--olive
	color white
	background-color $color

.icon-theme--orange
	background-color $orange

.icon-theme--violet
	background-color $violet

.icon-theme--cyan
	background-color $cyan

.icon-theme--green
	background-color $green

.icon-theme--ltgreen
	background-color $ltgreen

.icon-theme--olive
	background-color $olive

.icon-theme--brown
	background-color $brown


.icon-phone
	size(16px, 25px)
	color $primary

.icon-user-account
.icon-cross
.icon-cross-thin
.icon-clock
.icon-credit-card
.icon-arrow-right
.icon-price-cut
.icon-search
.icon-right-arrow
.icon-left-arrow
.icon-circle-check
.icon-mail
.icon-print
.icon-invoice
.icon-note
.icon-faq
.icon-cart-block-basket
.icon-cart-block-order
	svg
		size(100%)

.icon-user-account
	size(22px, 25px)

.icon-small-calendar
	size(22px)

.icon-calendar
	size(22px)

.icon-marker-outline
	size(22px)

.icon-cross
	size(18px)

.icon-cross-thin
	size(44px)

.icon-clock
	size(30px)

.icon-credit-card
	size(22px)

.icon-arrow-right
	size(13px, 12px)

.icon-price-cut
	size(46px, 13px)

.icon-right-arrow
.back-link__icon
	size(18px, 16px)

.icon-search
	size(20px)

.icon-circle-check
	size(15px)

.icon-print
	size(30px)

.icon-invoice
	size(28px, 36px)

.icon-exit
	size(25px)

.icon-mail
	size(30px, 26px)

.icon-clock-thin
	size(22px)
	svg
		size(22px)

.icon-delete
	size(20px)

.icon-proposal-map
	size(24px)

.icon-basket
	size(30px)
	color $gray

.icon-timer-clock
	size(24px)
	color white

.icon-money-coins-blue
.icon-phone-blue
	size(30px)

.icon-youtube-logo
	width 92px
	height 92px

.icon.icon-transfer
	size(20px)
	color $primary


@import projectStylesheet($project)
