#logo
	display block
	padding 30px

#logo-svg
	width 250px

.logo-st0
	fill:#79B928

.logo-st1
	fill:#FFFFFF

.logo-st2
	fill:#E72F2A

.logo-st3
	fill:none
