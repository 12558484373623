.radio-label {
  display: inline-block;
  padding-left: 27px;
}
.radio-label__control {
  margin-left: -27px;
  float: left;
}
.radio-label__body {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.radio-label__title {
  font-size: 13px;
  display: block;
  text-transform: uppercase;
  padding-top: 2px;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.radio-label__title.checked {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.radio-label__content {
  display: block;
  margin-top: 3px;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.radio-label__error-holder {
  position: relative;
}
.radio-label__error {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.radio-label.with-error {
  padding-top: 28px;
}
/*# sourceMappingURL=src/js/components/Form/RadioLabel/RadioLabel.css.map */