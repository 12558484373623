@import "~vars"

#sidebar
	position fixed
	left 0
	top 0
	width 100%
	height 100%
	pointer-events none
	z-index 11
	min-width 320px
	opacity 1
	transition opacity 1s
	&[hidden]
		opacity 0
		transition opacity 1s


.menu-sidebar
	position absolute
	left 0
	top 0
	height 100%
	border-right 1px solid $sidebar-border
	background white
	width 326px
	pointer-events auto
	overflow-y auto
	box-shadow 1px 0 20px -10px black
	transition left .35s

	.navigation-block
		padding 0 30px
		margin-bottom 15px
		+tablet()
			padding 0 20px
			margin-bottom 10px

	.menu__body
		padding 10px 0
		border-bottom 1px solid $gray-2
		+tablet()
			padding 5px 0

		&.another-section
			padding-top 0
			max-height 40px
			overflow hidden
			transition max-height 0.7s
			+tablet()
				padding-top 5px
				max-height 50px
			&.open
				max-height 320px
				.main-menu-title
					padding-bottom 0
					color $primary
					.dropdown-icon
						color currentColor

			.main-menu-title
				padding-bottom 20px
				display flex
				align-items baseline
				justify-content space-between
				text-transform uppercase
				transition padding-bottom 0.7s
				font-size 13px
				+tablet()
					padding 14px 0 5px

				.to-main-menu-title
					cursor pointer
					user-select none

				.dropdown-icon
					cursor pointer
					color $gray-dark
					svg
						width 9px

.close-button
	display none
	float right
	cursor pointer
	padding 15px
	width initial
	+isMobileMenu()
		display block
	svg
		size(15px)

.sidebar-hr
	margin 0
	height 1px
	background white
	&::before
		background none

.menu-button
	margin 0 auto 40px
	width calc(100% - 58px)
	display flex
	font-family $lato
	font-weight 400
	font-size 13px
	line-height 1
	padding 8px 20px
	align-items center
	justify-content center
	+tablet()
		width calc(100% - 40px)
		margin 0 auto 5px
	&:hover
	&:focus
		background $white!important
		color $primary!important
		border 1px solid $primary
	&>i
		margin-right 8px

@import projectStylesheet($project)
