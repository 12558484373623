@import "nib"
@import "~vars"

.verify-email-wrapper
	text-align center
	background url("/images/fora/vegatables.png")
	display flex
	min-height 100%
	position absolute
	top 0
	left 0
	right 0
	height 100vh
	min-width $minWidth

	.verify-email__bg
		absolute left top
		size(100%)
		background embedurl("../../../img/pattern.svg", "utf8") $primary repeat center
		background-size cover
		+tablet()
			position fixed

		&:after
			content ''
			absolute left top
			size(100%)
			+phone()
				position fixed

	.verify-email__body
		text-align left
		margin auto
		position relative
		z-index 2
		max-width 806px
		width 100%
		min-height 400px
		background-color white
		padding 40px 20px
		border-radius 5px
		+phone()
			height 100vh
			width  100vw
			border-radius 0
			padding 25px 10px

		.logo-row
			display flex
			justify-content center
			.verify-email__logo
				height 66px
				+phone()
					height 45px

		.verify-email__text
			font-family $exo
			font-size 24px
			font-weight 700
			color $color
			margin 20px
			text-align center
			+phone()
				font-size 22px
				margin 10px 40px 20px

		.verify-email__error
			font-family $lato
			font-weight 400
			font-size 20px
			color $color
			margin 20px 40px
			text-align center
			+phone()
				font-size 16px
				margin 10px 20px

		.verify-email__hint
			color #666666
			font-family $lato
			font-size 18px
			line-height 49px
			text-align center
			+phone()
				font-size 16px

		.verify-email__auth
			text-align center

		.btn-min
			padding-left 50px
			padding-right 50px

		.btn-react
			display flex
			flex-direction column
			font-size 27px
			color #ffffff
			margin 10px auto
			position absolute
			bottom 10px
			+laptop()
				font-size 20px

		.verify-email__btn-wrapper
			display flex
			flex-direction row
			justify-content center
			align-items center
			+phone()
				flex-direction column
		.verify-email__btn
			position relative
			display flex
			justify-content center
			cursor pointer
			width 282px
			height 348px
			border-radius 10px
			margin 12px
			-webkit-background-size cover
			-moz-background-size cover
			-o-background-size cover
			background-size cover
			background-position center
			&:hover
				box-shadow 0 0 14px rgba(black 0.5)
			&.active
				box-shadow 0 0 14px rgba(black 0.8)
			+laptop()
				height 163px
			+phone()
				margin 5px
		.btn_to-personal-page
			background-image linear-gradient(180deg, #00000000 0%, #545454 100%), url("../../../img/go-to-personal-page.png")
		.btn_to-shop
			background-image linear-gradient(180deg, #00000000 0%, #7ab929 100%), url("../../../img/go-to-shop.png")
		.verify-email__btn-img
			height 80px
			margin 15px
			+laptop()
				height 56px
		.verify-email__footer
			display flex
			justify-content center
			padding-top 20px
			.btn-primary
				width 260px
				height 47px
				text-transform capitalize
				font-size 18px
				font-family $exo-extra-light
				+phone()
					font-size 16px
					width 182px
					height 34px
					padding 0

	.verify-email__container
		margin 50px
		display flex
		justify-content center
		align-items center
		font-size 24px
		height 100%

