.drop-down-menu {
  background: #fff;
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 13px;
  min-width: 235px;
  max-height: 252px;
  overflow-y: auto;
  color: #000;
  padding: 10px 0;
}
@media (max-width: 768px) {
  .drop-down-menu {
    min-width: 260px;
  }
}
.pull-right .drop-down-menu {
  right: 0;
  left: auto;
}
ul.drop-down-menu > li,
.drop-down-menu-item {
  padding: 6px 25px;
  -webkit-transition: color 0.35s;
  -moz-transition: color 0.35s;
  -o-transition: color 0.35s;
  -ms-transition: color 0.35s;
  transition: color 0.35s;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
ul.drop-down-menu > li.active,
.drop-down-menu-item.active {
  cursor: default;
}
ul.drop-down-menu > li.active,
.drop-down-menu-item.active,
ul.drop-down-menu > li:hover,
.drop-down-menu-item:hover {
  color: #79b928;
}
/*# sourceMappingURL=src/js/components/DropDown/DropDownMenu/DropDownMenu.css.map */