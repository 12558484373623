@import "nib"
@import "~vars"

.modal-overlay
	.modal-window.how-get-card-modal
		margin auto
		background white
		max-width 1060px
		padding 0 0 50px

.how-get-card__panel
	+tablet()
		position relative

.how-get-card__back-link
	margin 3px 0 0
	display inline-block

.how-get-card__head
	display flex
	align-items baseline
	margin 40px 0 30px

.how-get-card__heading
	margin 0

.how-get-card__rules
	margin-left auto
	+tablet()
		position absolute
		left 20px
		bottom 102px

.how-get-card__ol
	display flex
	margin 12px 0 0 -30px
	min-height 130px
	+tablet()
		flex-direction column
		margin-left 0

	> li
		flex 0 1 33%
		margin-left 30px
		+tablet()
			margin-left 0

		&:before
			font-size 80px
			margin-top -12px
			+tablet()
				font-size 50px
				min-width 18px
				margin-top -8px

.how-get-card__btn-holder
	margin 50px auto 0
	text-align center

.how-get-card__go-home
	min-width 180px
