@media (max-width: 500px) {
  .card-select-store-modal {
    padding: 40px 0 !important;
    margin-left: 20px !important;
  }
}
@media (max-width: 500px) {
  .nearest-store-panel {
    padding: 0 !important;
  }
}
.nearest-store-form {
  margin: auto;
  max-width: 650px;
  padding: 0;
  font-size: 16px;
  line-height: 1.25;
}
@media (max-width: 768px) {
  .nearest-store-form {
    padding: 0;
  }
}
.nearest-store-form .subscribe-stores {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 30px;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
}
.nearest-store-form .subscribe-stores .store-fields-wrapper {
  width: 100%;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 500px) {
  .nearest-store-form .subscribe-stores .store-fields-wrapper {
    box-flex: 1;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 calc(100% - 50px);
    -ms-flex: 0 0 calc(100% - 50px);
    flex: 0 0 calc(100% - 50px);
    width: calc(100% - 50px);
  }
}
@media (max-width: 500px) {
  .nearest-store-form .subscribe-stores .store-fields-wrapper .form__field .form__select-holder {
    padding-top: 0;
  }
}
@media (max-width: 500px) {
  .nearest-store-form .subscribe-stores .store-fields-wrapper .form__field .form__select-holder .form__label--select {
    display: none;
  }
}
@media (max-width: 500px) {
  .nearest-store-form .subscribe-stores .store-fields-wrapper .form__field .form__select-holder .form__label--select:not(.not-empty):not(.focused) {
    display: block;
  }
}
.nearest-store-form .subscribe-stores .store-buttons-wrapper {
  box-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 30px;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
.nearest-store-form .subscribe-stores .store-buttons-wrapper .notvisible {
  visibility: hidden;
}
.nearest-store-form .subscribe-form__stores {
  padding: 0;
}
.nearest-store-form .subscribe-form__stores-list {
  border: 0;
  padding: 0;
}
.nearest-store-form .subscribe-form__stores-list:before,
.nearest-store-form .subscribe-form__stores-list:after {
  display: none;
}
.nearest-store-form__message {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  margin: 36px 0;
  max-width: 450px;
}
@media (max-width: 500px) {
  .nearest-store-form__message {
    max-width: 100%;
  }
}
.select-form__stores-btn {
  padding: 0;
}
@media (max-width: 500px) {
  .select-store {
    margin: 30px auto 20px;
    min-width: 180px;
    display: block;
  }
}
/*# sourceMappingURL=src/js/forms/NearestStoreForm/NearestStoreForm.css.map */