@import "~vars"

.modal-overlay
	.modal-window.registration-contact-center__dialog
		max-width 590px

.registration-contact-center__panel
	text-align center
	color #6e6d6d
	min-height 200px
	display flex
	align-items center
	justify-content center

	a[href^="tel:"]
		color $color
