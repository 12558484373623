.ui-date-range-picker {
  min-width: 210px;
  border: 1px solid transparent;
  display: inline-block;
  color: #333;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 40px;
  vertical-align: middle;
  position: relative;
  background: #f2f2f2;
  font-family: "Lato";
}
.ui-date-range-picker:hover {
  color: #79b928;
}
.ui-date-range-picker.filled,
.ui-date-range-picker.focused {
  background: #fff;
  border-color: #79b928;
  color: #333;
}
.ui-date-range-picker.filled .DateRangePickerInput,
.ui-date-range-picker.focused .DateRangePickerInput {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.ui-date-range-picker.filled .ui-date-range-placeholder,
.ui-date-range-picker.focused .ui-date-range-placeholder {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.ui-date-range-picker .DateRangePickerInput {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.ui-date-range-picker .ui-date-range-placeholder {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.ui-date-range-picker .DateRangePicker {
  display: block;
}
.ui-date-range-picker .DateInput {
  background: transparent;
  width: 80px;
}
.ui-date-range-picker .DateInput_input {
  text-transform: uppercase;
  font-size: 12px;
  background: transparent;
  padding: 0;
  text-align: center;
}
.ui-date-range-picker .DateRangePickerInput {
  background: transparent;
}
.ui-date-range-picker .DateRangePickerInput__withBorder {
  border: none;
  padding: 4px 0 4px 8px;
}
.ui-date-range-picker .DateInput_input__focused {
  border: none;
  background: #b6e37e;
  border-radius: 30px;
}
.ui-date-range-picker .CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit;
}
.ui-date-range-picker .DateRangePickerInput_clearDates {
  outline: none;
  margin: 0 2px 0 5px;
  padding-top: 6px;
}
.ui-date-range-picker .DateRangePickerInput_clearDates_default:hover {
  background: transparent;
  color: #79b928;
}
.ui-date-range-picker .DateRangePickerInput_clearDates_svg:hover {
  fill: #79b928;
}
.ui-date-range-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-weight: 600;
}
.DateRangePickerInput_arrow {
  margin: 9px 3px 0;
  vertical-align: top;
}
.DateRangePickerInput_arrow:before {
  content: '';
  width: 8px;
  height: 1px;
  background: #333;
  margin-top: 3px;
  display: block;
}
.DateRangePickerInput_arrow svg {
  display: none;
}
.DateRangePickerInput__clear-dates:focus,
.DateRangePickerInput__clear-dates--hover {
  background: none;
  border-radius: 0;
  color: #79b928;
}
.DayPickerNavigation_button:nth-of-type(1) {
  left: 17px;
}
.DayPickerNavigation_button:nth-of-type(2) {
  right: 17px;
}
.DayPickerNavigation_button {
  position: absolute;
  top: 16px;
  width: 32px;
  height: 32px;
  padding: 10px !important;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-transition: color 0.2s, border-color 0.2s;
  -moz-transition: color 0.2s, border-color 0.2s;
  -o-transition: color 0.2s, border-color 0.2s;
  -ms-transition: color 0.2s, border-color 0.2s;
  transition: color 0.2s, border-color 0.2s;
}
.DayPickerNavigation_button:hover {
  color: #e72f2a;
  border-color: #cec8d7;
}
.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  background: #b6e37e;
  border-color: #daf1bc;
  color: #000;
}
.CalendarDay__selected {
  background: #79b928;
  border-color: #fff;
}
.DateRangePicker__picker {
  z-index: 10;
}
/*# sourceMappingURL=src/js/components/UI/DateRangePicker/DateRangePicker.css.map */