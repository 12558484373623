@import "~vars"

.change-time-modal__dialog
	.change-time-modal__switcher.bonus-history-filter
		justify-content flex-start
		margin 30px 0

		.button-switch
			width 310px
			border none
			border-radius 0
			margin 0 auto

		.button-switch-item
			border-right none
			border-radius 17px
			border 1px solid $primary
			margin-right 5px
			transition background-color $tdur
			font-weight 600
			padding 0 12px
			+phone()
				padding 0 8px
			&:hover:not(.active)
				color black
				background-color $gray-5
	.radio-btn-like-list
		display grid
		grid-template-columns repeat(auto-fill,minmax(130px,1fr))
		grid-gap 0 10px
		.radio-btn-like-item
			transition background-color $tdur
			border 1px solid $primary
			margin-bottom 15px
			min-width 135px
			font-weight 600
			font-size 13px
			+phone()
				min-width 130px
			&:not(.radio-btn-like-disabled)
				&:hover:not(.radio-btn-like-active)
					background-color $gray-5
			&.radio-btn-like-disabled
				border-color rgba(#8a8a8a, .3)
				color rgba(#8a8a8a, .3)
				background-color $white



.change-time-modal__heading
	text-align center
	margin-bottom 0
	font-family $proxima
	font-weight bold
	font-size 22px
	color #111

.change-time-modal__subtitle
	margin-bottom 10px

.change-time-modal__buttons-group
	display flex
	align-items center
	justify-content center
	margin-top 20px
	button
		text-transform initial
		font-size 16px
		min-width 182px
		font-weight 500
		display block
		margin 0 auto
		height 34px
		padding 0
