@import "~vars"

.my-contacts__numbers
	margin-top $gutter

.my-contacts__numbers-heading
	margin 22px 0 25px

.my-contacts__numbers-text
	margin 25px 0
	width 648px
	+tablet()
		width 100%


.my-contacts__numbers__list
	max-width 520px
	margin 25px 0

.my-contacts__numbers__list-add-holder
	display inline-block
	width 100%
	+tablet()
		position relative
		.inline-row
			flex-wrap wrap
		.inline-row__buttons
			position absolute
			border-bottom none
			right 0
			top 0

.my-contacts__numbers__list-item
	border-bottom 1px solid $borderColor
	display flex
	height 60px
	+phone()
		display block

.my-contacts__numbers__list-item-title
	margin auto 0
	font-size 18px
	lh(22)
	padding 0 15px 0 0
	+phone()
		float: left
		width 100%
		padding-top 5px

.my-contacts__numbers__list-item-value
	font-size 14px
	lh(18)
	+phone()
		float: left
		padding-top 10px

.my-contacts__numbers__list-item-value
.my-contacts__numbers__list-item-title
	flex 1 1 50%
	max-width 225px
	margin auto 0

	.innerText
		overflow ellipsis

.my-contacts__numbers__list-item-controls
	flex 0 0 70px
	display flex
	justify-content flex-end
	margin auto 0
	+phone()
		float: left
		padding-left 15px

.my-contacts__numbers__list-item-button
	padding 0
	size(35px)
	color $gray
	+phone()
		height 32px

	&:not(:disabled)
		&:hover
			color $color

	&:disabled
		opacity .5

.my-contacts__numbers__add-button
	display block
	margin 20px auto
	border-width 0
	float left
	padding 0
	color $gray

	.icon
		margin -3px 7px 0 0

	&:not(:disabled)
		&:hover
			color $color