.auth {
  width: 100%;
  padding: 70px 70px 80px;
}
@media (max-width: 768px) {
  .auth {
    padding: 50px 30px 60px;
  }
}
.auth .tooltip__create-password ul {
  list-style: disc inside;
}
.auth .form {
  margin: 0;
  max-width: unset;
}
.auth .form .form__field {
  margin-bottom: 20px;
}
.auth .form .btn-submit {
  width: 200px;
  margin-top: 30px;
}
@media (max-width: 500px) {
  .auth .form .btn-submit {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
.auth .form.registration-personal-info-form {
  margin: 0 auto 20px auto;
}
.auth .modal-title {
  margin-bottom: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.auth .modal-title img {
  width: 45px;
  height: 45px;
  margin-right: 14px;
}
.auth .modal-description {
  font-size: 16px;
  padding-bottom: 10px;
}
@media (max-width: 500px) {
  .auth .modal-description {
    font-size: 13px;
  }
}
.auth.welcome-modal .modal-title {
  margin-bottom: 20px;
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/UnitedEntranceWrapper/UnitedEntranceWrapper.css.map */