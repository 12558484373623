.controls-panel {
  margin: 0;
  max-width: initial;
  zoom: initial;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.35s;
  -moz-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  -ms-transition: max-height 0.35s;
  transition: max-height 0.35s;
}
.controls-panel.open {
  max-height: 500px;
}
.controls-panel .switch-col {
  margin-top: 20px;
  margin-left: 37px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 400px;
  -ms-flex: 0 1 400px;
  flex: 0 1 400px;
}
@media (max-width: 500px) {
  .controls-panel .switch-col {
    margin-left: 0;
  }
}
.controls-panel .switch-col .radio-label {
  margin-bottom: 20px;
}
.controls-panel .switch-col .radio-label .radio-label__title {
  text-transform: initial;
  color: #4f4f4f;
  font-size: 15px;
  font-weight: bold;
}
@media (max-width: 500px) {
  .controls-panel .switch-col .radio-label .radio-label__title {
    font-size: 14px;
  }
}
.controls-panel .switch-col .radio-label .radio-label__title.checked {
  color: #79b928;
}
.controls-panel .switch-col .radio-label .radio-label__content {
  color: #7b7b7b;
  font-size: 14px;
}
@media (max-width: 500px) {
  .controls-panel .switch-col .radio-label .radio-label__content {
    font-size: 12px;
  }
}
.controls-panel .description-col {
  margin-top: 20px;
  margin-left: 37px;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  box-flex: 2;
  -webkit-flex: 0 2 400px;
  -ms-flex: 0 2 400px;
  flex: 0 2 400px;
  font-size: 15px;
}
@media (max-width: 768px) {
  .controls-panel .description-col {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .controls-panel .description-col {
    display: none;
  }
}
.controls-panel .button-col {
  margin: 20px 0 0 auto;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  -ms-flex-item-align: end;
}
@media (max-width: 500px) {
  .controls-panel .button-col {
    margin: 20px auto 0;
  }
}
.controls-panel .button-col .moneybox-btn {
  width: 220px;
  height: 50px;
  border-radius: 25px 0;
  font-size: 18px;
  font-weight: normal;
  text-transform: initial;
}
@media (max-width: 768px) {
  .controls-panel .button-col .moneybox-btn {
    height: initial;
    width: 160px;
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .controls-panel .button-col .moneybox-btn {
    font-size: 14px;
  }
}
/*# sourceMappingURL=src/js/components/Moneybox/MoneyboxControls/MoneyboxControls.css.map */