@import "~vars"

.proposal-like-control
	.icon
		width 22px
		height 24px
		cursor pointer
		&.active svg path
			fill $primary
			transition fill $tdur
	.dislike
		margin-right 20px
	.like svg .a, .dislike svg .a
		transition fill $tdur
		stroke none
	
