.btn-show-more {
  border-radius: 0;
  background: #fff;
  margin-top: 15px;
  font-size: 22px;
  line-height: 1.090909090909091;
  color: #848484;
  text-transform: none;
  font-family: $pfdintextcomp;
  font-weight: normal;
  padding: 25px 20px 21px;
  border: 0;
}
.btn-show-more:hover {
  color: #79b928;
}
.btn-show-more .icon {
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: baseline;
}
/*# sourceMappingURL=src/js/components/UI/ShowMore/ShowMore.css.map */