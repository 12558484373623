@import "~vars"

.personal-page-intro
	margin-bottom 20px
	background-position bgPosition(966 * 100 / 1143, 10%) calc(15% \+ 25px)
	+media(600px)
		background-position bgPosition(966 * 100 / 1143, 10%) calc(50% \+ 25px)
	padding: 15px 0 0 0

	h1.intro-title
		padding 30px 50px
		@media (min-width: 770px) and (max-width: 860px)
			font-size 40px
		@media (min-width: 1101px) and (max-width: 1200px)
			font-size 40px
		+phone()
			padding 30px
	.intro-content
		width 100%

	+tablet()
		margin 0 $negativeGutter $gutter
		display flex
		flex-direction row-reverse

.drop-down .drop-down-title
	width 100%
	position relative
	float right

.personal-page__user_and_card
	border-top 1px solid #e6e6e6
	background white
	padding ($padding/ 2) $padding
	display flex
	font-size 18px
	lh(22)
	min-height 110px
	align-items center
	+tablet()
		flex-direction column
		min-height auto
		font-size 16px
		padding 20px
		align-items flex-start
		margin-right -15px
		margin-left -15px
	+phone()
		margin-bottom 20px

	.user__name
		max-width 600px
		padding-right $padding
		flex 1 1 600px
		+tablet()
			flex-basis auto
			padding-right 0

	.user__card
		flex 0 0 auto
		text-align right
		+tablet()
			text-align left
			margin-top 20px

	.card__number
		margin-left $gutter

.personal-page__history
	background white
	padding $padding
	margin-top $padding

	.no-data:before
		display none
	+tablet()
		padding 25px 15px

.personal-page__history-heading
	margin 10px 0 28px

.personal-page__history-btn-all
	margin 40px auto 0
	min-width 150px
	display block
	max-width 180px

.personal-page__dropdown-links
	position absolute
	top 55px
	right 20px
	+phone()
		top 36px

.personal-page .special-proposals__intro
	margin-left 0
	margin-right 0