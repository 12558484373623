@import "~vars"

.menu-header-wrapper
	display block
	margin-bottom 15px
	box-shadow 0 3px 7px #8a8a8a33
	position fixed
	top 0
	right 0
	z-index 10
	width calc(100% - 325px)

	.menu-header
		.menu-header-search
			display none
			background-color white
			width 100%
			margin-right 7px
			border-top 1px solid $gray-2
			padding 0 5px
			+media($menuToggle)
				margin-right 0
				.btn
					padding-right 0

			+media($menuToggle)
				display none
				&.search-bar-shown
					display block
				.side-menu-search
					padding 12px 8px
					.side-menu-search__input-icon
						bottom 12px
						right 10px

						.icon
							size(16px)
					input
						border-bottom none
			+phone()
				display none
				&.search-bar-shown-scrolled
					display block
		.min-width
			padding 0
			display flex

	+media($menuToggle)
		margin 0
		width 100%
		left 0
		.menu-header .min-width
			min-height 60px
			padding 5px


.menu-header
	pointer-events auto
	background white
	border-bottom 1px solid $borderColor
	z-index 50
	.menu-logo
		margin 0
		cursor pointer
		size(50px, 50px)
		max-height 100%
		absolute left 50% top 50%
		transform translate(-50%, -50%)
		img
			max-height 100%
			max-width unset

		+media($menuToggle)
			display flex
			padding-left 10px
			position static
			transform translate(0,0)
		+tablet()
			size(50px, 50px)


	.min-width
		position relative
		display flex
		justify-content space-between
		align-items center
		padding $gutter
		height 100%
		+media($menuToggle)
			padding 15px 10px
		+phone()
			justify-content flex-start


.menu-header-content
	display flex
	margin-left auto
	margin-right 30px
	+tablet()
		margin-right 15px
	+phone()
		margin-right 0
	.menu-header-item
		text-align center
		position relative
		cursor pointer
		height 75px
		display flex
		padding 0 10px
		box-sizing border-box
		+media($menuToggle)
			padding 5px 7px 0
			height unset
			order 3
			min-width 40px
			align-items center
		+phone()
			padding 0 5px
			min-width 35px
		&.account
			padding 0 15px 0 10px
			+tablet()
				padding 0
			+phone()
				padding 0 10px
			cursor auto
			+phone()
				position static
			.menu-header_button
				margin-right 10px
				cursor pointer
				+tablet()
					margin-right 0
				&:hover
				&:focus
					.menu-header-item_title
						color $primary
					.icon
						color $primary

		&:hover:not(.account), &:focus:not(.account)
			.menu-header-item_title
				color $primary
			.icon:not(.icon-pencil)
				color $primary

		&.person
			+phone()
				order 2

		&:last-child
			&:after
				display none
		&:after
			content ""
			display block
			position absolute
			left 100%
			height 55%
			width 1px
			background-color #f2f2f2
			top 50%
			transform translateY(-50%)
			+phone()
				display none
		.icon
			&.icon-person
				color #999
				width 28px
				text-align center
				height 28px
				margin 0
				+tablet()
					display none
				+media($menuToggle)
					width 26px
					height 26px
					&.icon-header-search
						display flex
						align-items center
						margin auto
						svg
							height auto
							width auto
				svg
					margin 0 auto

		.menu-header_user-vouchers
			color black
			text-align left
			display block
			font-size 12px
			padding 12px 8px 0
			+media($menuToggle)
				display none
			.account-available__vouchers__amount
				margin-left 0
				font-size 28px
				font-family $pfdintextcomp
				padding-right 5px
			.account-available__vouchers__currency
				margin 0
		.menu-header-item_title
			display block
			padding-top 5px
			lh(15)
			word-break normal
			white-space pre-wrap
			text-transform none
			color $gray-dark
			font-size 14px
			min-width 35px
			max-width 122px
			text-overflow ellipsis
			overflow hidden
			height 35px
			+tablet()
				font-size 12px
			+phone()
				display none
		.menu-header_button
			font-weight normal
			margin 0 auto
			padding 10px 0 0 0
			border none
			lh(15)
			display block
			+media($menuToggle)
				padding 0
			.menu-header_button-number
				background-color $basketCounter
				color white
				font-weight 700
				display inline-block
				width 20px
				height 20px
				font-size 12px
				text-align center
				position absolute
				right 0
				border-radius 10px
				.menu-header_button-value
					position absolute
					top 50%
					left 50%
					transform translate(-50%, -50%)
				+phone()
					right 0

.menu-btn
	padding 0
	border 0
	color $primary
	border-radius 0
	size(40px)
	margin-left -1px
	display none
	+media($menuToggle)
		display block

.context-list
	box-shadow $boxShadow
	position absolute
	padding 15px 10px
	font-size 13px
	right 0
	width 210px
	z-index 99
	top 100%
	background-color white
	.context-list_item
		cursor pointer
		color #666
		display block
		font-size 14px
		text-align left
		text-transform none
		margin-bottom 10px
		&:hover
			color $primary
		.context_list_item-icon
			margin-right 10px
			.icon
				size(20px)
		&:last-child
			margin-bottom 0
		.cart-buttons-block__icon-block
			padding-right 5px
