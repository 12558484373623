@import "~vars"

.my-contacts__data
	margin 0
	+phone()
		padding 15px

	hr
		margin-bottom 50px
		+phone()
			margin-bottom 15px

	.form__input-holder
		padding-top 0
		+phone()
			padding-top 28px

	.my-contacts__data-heading
		margin 22px 0
		+tablet()
			margin 0
	.my-contacts__list
		max-width 520px
		margin 22px 0 25px
		padding 0

		+phone()
			margin 0 0 25px 0
		li
			&:not(:first-child)
				margin-top 40px
				+phone()
					margin-top 20px
	.my-contacts__list-item
		border-bottom 1px solid $borderColor
		display flex
		position relative

		+phone()
			padding-top 28px

		+tablet()
			flex-direction column
			height auto
			position relative
			//margin-bottom 20px

	.my-contacts__list .inline-row
		+tablet()
			flex-wrap wrap

	.my-contacts__list-item-label
		position absolute
		bottom 100%
		+phone()
			top 0
			bottom unset


	.my-contacts__list-item-value
		margin auto 0
		min-height 41px
		flex 1
		font-size 14px
		display flex
		align-items center

		+tablet()
			margin 0

		.innerText
			overflow ellipsis

	.my-contacts__list .inline-row__buttons,
	.my-contacts__list-item-controls
		flex 0 0 70px
		justify-content flex-end
		display flex

	.my-contacts__list-item-controls
		margin auto 0
		+tablet()
			position absolute
			top 0
			right 0

	.my-contacts__list-item-button
		padding 10px
		color $gray

		&:not(:disabled)
			&:hover
				color $color