@import "~vars"

.intro
	padding 50px
	background white
	font-family $font
	min-height 200px
	+tablet()
		margin 0 $negativeGutter
	+phone()
		margin-bottom 20px
	h1
		margin 1em 0 
		text-transform none

.intro-menu
	margin 0 0 10px 10px
	padding 25px
	background $nav-bg
	color white
	+tablet()
		display none
	a
		margin 0 10px
		text-transform  uppercase

.intro-hr
	size(28, 2)
	&:before
		content ""
		height 100%
		width 28px
		display inline-block
		vertical-align top
		background currentColor
.intro-text
	max-width 475px

@import projectStylesheet($project)
