.bread-crumbs {
  color: #a2a2a2;
  font-size: 13px;
  margin: 4px 0;
  padding: 20px 0 10px 10px;
}
@media (max-width: 500px) {
  .bread-crumbs {
    padding: 10px 0 10px 10px;
  }
}
.bread-crumbs > li {
  vertical-align: top;
  position: relative;
  display: inline-block;
}
.bread-crumbs-link {
  font-family: "Lato";
}
a.bread-crumbs-link:hover {
  color: #333;
}
.icon-bread-crumbs-arrow {
  color: rgba(138,138,138,0.3);
  vertical-align: middle;
  margin: 0 8px;
  width: 11px;
  height: 10px;
}
.icon-bread-crumbs-arrow svg {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=src/js/components/BreadCrumbs/BreadCrumbs.css.map */