@import "~vars"

.ui-date-range-picker
	min-width 210px
	border 1px solid transparent
	display: inline-block;
	color $color
	text-transform uppercase
	font-size $fontSize1
	border-radius 40px
	vertical-align middle
	position relative
	background $disabledBg
	font-family $font
	&:hover
		color $primary
	&.filled
	&.focused
		background white
		border-color $primary
		color @color
		.DateRangePickerInput
			opacity 1
			visibility visible
		.ui-date-range-placeholder
			opacity 0
			visibility hidden

	.DateRangePickerInput
		opacity 0
		visibility hidden

	.ui-date-range-placeholder
		opacity 1
		visibility visible

	.DateRangePicker
		display block
	.DateInput
		background transparent
		width 80px
	.DateInput_input
		text-transform uppercase
		font-size $fontSize1
		background transparent
		padding 0
		text-align center
	.DateRangePickerInput
		background transparent
	.DateRangePickerInput__withBorder
		border none
		padding 4px 0 4px 8px
	.DateInput_input__focused
		border none
		background lighten($primary, 25)
		border-radius 30px
	.CalendarDay__default:hover
		background #e4e7e7
		border 1px solid #e4e7e7
		color inherit
	.DateRangePickerInput_clearDates
		outline none
		margin 0 2px 0 5px
		padding-top 6px
	.DateRangePickerInput_clearDates_default:hover
		background transparent
		color $primary
	.DateRangePickerInput_clearDates_svg:hover
		fill $primary
//.ui-date-range-picker


.ui-date-range-placeholder
	position absolute
	left 0
	top 0
	//absolute left top
	right 0
	bottom 0
	cursor pointer
	display flex
	opacity 0
	visibility hidden
	align-items center
	justify-content center
	font-weight $semibold

//.ui-date-range-picker
//	font-size 12px
//	lh(15)

	.DateRangePickerInput_arrow
		margin 9px 3px 0
		vertical-align top

		&:before
			content ''
			size(8px, 1px)
			background $color
			margin-top 3px
			display block

		svg
			display none

	.DateRangePickerInput__clear-dates:focus
	.DateRangePickerInput__clear-dates--hover
		background none
		border-radius 0
		color $primary

	.DayPickerNavigation_button:nth-of-type(1)
		left 17px
	.DayPickerNavigation_button:nth-of-type(2)
		right 17px

	.DayPickerNavigation_button
		position absolute
		top 16px
		size(32px)
		padding 10px !important
		background white
		border 1px solid $borderColor
		border-radius 0
		display flex
		align-items center
		justify-content center
		transition color .2s, border-color .2s
		&:hover
			color $secondary
			border-color #cec8d7

	.CalendarDay__hovered_span
	.CalendarDay__selected_span
		background lighten($primary, 25)
		border-color lighten($primary, 40)
		color black
	.CalendarDay__selected
		background $primary
		border-color white

.DateRangePicker__picker
	z-index 10
