@import "~vars"

.my-contacts-number-form
	max-width none
	margin 0

	.form-row.inline-row
		margin-left 0

	.field--title
		input
			font-size 18px
			lh(22)