@import "nib"
@import "~vars"

.modal-overlay.welcome-overlay
	+phone()
		padding 0
	.modal-window.auth-modal
		width 650px
		padding 0
		+tablet()
			max-width 590px

		.welcome__wrapper
			display flex
			justify-content space-evenly
			flex-direction column
			+tablet()
				width auto
			+phone()
				max-width none
				width 100%
				margin-bottom 0
				height auto

			.mobile-apps-block
				background-color transparent
				.mobile-apps-block__iOS-link-block-mobile
				.mobile-apps-block__android-link-block-mobile
					text-align center
					a
						span
							svg
								width 100%
								height 100%
			.modal-description
				margin 20px 0
				+phone()
					margin 0
				p
					font-size 16px
					max-width 490px
			.modal-title
				align-items flex-start
				h3
					margin 0


			.welcome__qr
				text-align center
				max-width 200px
				margin auto
				padding-top 13px

			.text-divider
				width 100%
				color #ccc
				text-align center
				position relative
				padding 10px
				display block
				font-size 12px
				margin 25px 0
				+phone()
					margin 15px 0
				&:before, &:after
					content ""
					position absolute
					top 50%
					left 0
					height 1px
					width 45%
					background-color #ccc
				&:after
					left unset
					right 0
					+phone()
						margin-right 0
						width 45%
				&:before
					+phone()
						margin-left 0
						width 45%