@import "~vars"

.drop-down
	display inline-block
	vertical-align middle
	font-size 14px
	lh(20)
	cursor pointer
	color $color
	text-align left

	&.open
		position relative
		z-index 10

		.icon-caret
			transform rotate(180deg)

	&.disabled
		opacity .4
		cursor not-allowed

.drop-down:not(.disabled)
	&.open
		.drop-down-arrow
			display block

		.drop-down-title
			border-color $primary

	&.open
	&:hover
		.drop-down-title-value
		.drop-down-title-caret
			color $primary

	&.has-value
		.drop-down-title-value
			color $primary

.drop-down-arrow
	position absolute
	//right 100%
	//top 100%
	margin 8px 26px
	display none

	&:before
	&:after
		content ''
		absolute left top
		border-style solid
		border-width 6px
		border-top-width 0 !important
		border-color #e0e0e0 transparent

	&:after
		margin 1px
		border-color white transparent
		border-width 5px


.drop-down--inline
	font-size inherit
	line-height inherit
	color currentColor

	.drop-down-title
		border-color #d6d6d6
		border-width 0 0 1px
		border-radius 0
		background none
		padding 0 30px 0 0
		text-transform none
		font-size inherit
		line-height inherit
		color currentColor

.drop-down--inline:not(.disabled)
	&.open
		.drop-down-arrow
			display none
			+laptop()
				display block

		.drop-down-title
			border-bottom-color #d6d6d6

	&.open
	&:hover
		.drop-down-title-value
		.drop-down-title-caret
			color currentColor

	&.has-value
		.drop-down-title-value
			color currentColor


.dropdown-dottes
	z-index 5
	cursor pointer
	position absolute
	-webkit-tap-highlight-color transparent

	.drop-down-title
		background-color transparent
		padding 0

	//.drop-down-title-caret
	//	display none

.dropdown-dottes-drop-down
	.drop-down-title
		border none

		.drop-down-title-value.drop-down-title-value
			color rgba(black, 0.8)

	.drop-down-menu
		margin-top 5px

	.drop-down-menu-wrapper
		width 100%
		height 100%
		position fixed
		top 0
		left 0
		outline none
		outline-offset 0
		z-index -1

	.drop-down-arrow
		margin-top 0

.dropdown-dottes__button
	position relative
	padding 12px

	&:before
		content ''
		width 5px
		height 5px
		border-radius 50%
		background black
		position absolute
		top 50%
		left 50%
		box-shadow 8px 0 0 0 black, -8px 0 0 0 black
		transform translate(-50%, -50%)

		.-white &
			background white
			box-shadow 8px 0 0 0 white, -8px 0 0 0 white

		.-orange &
			background $primary
			box-shadow 8px 0 0 0 $primary, -8px 0 0 0 $primary


