@import "~vars"


.cookie-banner
	width 100%
	padding 20px 30px 15px
	background-color white
	display grid
	grid-template-columns repeat(2, minmax(auto, 250px))
	grid-template-rows repeat(2, min-content);
	grid-column-gap 5px
	grid-row-gap 10px
	justify-content center
	align-content start
	justify-items center
	position fixed
	left 0
	bottom 0
	z-index 400
	font-size 13px
	letter-spacing 0
	text-align center
	box-shadow 0px -3px 6px rgba(black, 41/256)
	+tablet()
		padding 10px 30px

.cookie-message
	grid-column 1 / span 2
.cookie-confirm-btn
	padding 7px 19px
	justify-self end
.cookie-more-btn
	padding 7px 19px
	justify-self start