.birthday-banner {
  margin-top: 15px;
  background: #573f7b;
  color: #fff;
  height: 480px;
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  display: block;
  overflow: hidden;
}
@media (max-width: 768px) {
  .birthday-banner {
    height: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
a.birthday-banner {
  cursor: pointer;
  color: #fff;
  -webkit-transition-duration: 2s;
  -moz-transition-duration: 2s;
  -o-transition-duration: 2s;
  -ms-transition-duration: 2s;
  transition-duration: 2s;
  -webkit-transition-property: background-color;
  -moz-transition-property: background-color;
  -o-transition-property: background-color;
  -ms-transition-property: background-color;
  transition-property: background-color;
}
a.birthday-banner:hover .promo__image {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition-duration: 2s;
  -moz-transition-duration: 2s;
  -o-transition-duration: 2s;
  -ms-transition-duration: 2s;
  transition-duration: 2s;
}
@media (max-width: 768px) {
  a.birthday-banner {
    height: auto;
    padding: 60px;
  }
}
@media (max-width: 500px) {
  a.birthday-banner {
    padding: 36px 15px;
    margin: 0 -15px;
  }
}
.product-list .birthday-banner {
  margin-top: 0;
}
@media (max-width: 768px) {
  .product-list .birthday-banner {
    margin: 0;
  }
}
.birthday-banner.theme-violet {
  background: #573f7b;
}
a.birthday-banner.theme-violet:hover {
  background: #6f5a8e;
}
@media (max-width: 768px) {
  a.birthday-banner.theme-violet:hover {
    background: #573f7b;
  }
}
.birthday-banner.theme-cyan {
  background: #218fa3;
}
a.birthday-banner.theme-cyan:hover {
  background: #3c9dae;
}
.birthday-banner.theme-orange {
  background: #faa30c;
}
a.birthday-banner.theme-orange:hover {
  background: #fbae2a;
}
.birthday-banner.theme-olive {
  background: #b7be00;
}
a.birthday-banner.theme-olive:hover {
  background: #c1c724;
}
.birthday-banner.theme-green {
  background: #4bb7a1;
}
a.birthday-banner.theme-green:hover {
  background: #6fc6b4;
}
.birthday-banner .banner__image {
  position: absolute;
  right: 25%;
  top: 50%;
  text-align: center;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
}
@media (max-width: 1919px) {
  .birthday-banner .banner__image {
    right: 22%;
  }
}
@media (max-width: 768px) {
  .birthday-banner .banner__image {
    display: block;
    position: unset;
  }
}
.birthday-banner .banner__image img {
  margin: -500px;
  max-width: none;
}
@media (max-width: 1300px) {
  .birthday-banner .banner__image img {
    max-width: 500px;
  }
}
@media (max-width: 1023px) {
  .birthday-banner .banner__image img {
    max-width: 450px;
  }
}
@media (max-width: 768px) {
  .birthday-banner .banner__image img {
    margin: 0;
    width: 100%;
  }
}
.birthday-banner .promo__info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 100%;
  max-width: 55%;
  padding: 0 65px;
  position: relative;
  min-height: 280px;
}
@media (max-width: 768px) {
  .birthday-banner .promo__info {
    padding: 0;
    max-width: 100%;
    font-size: 13px;
    min-height: auto;
  }
}
.birthday-banner .promo__info-content {
  margin: auto 0;
}
@media (max-width: 768px) {
  .birthday-banner .promo__info-content {
    margin: 0 auto;
  }
}
.birthday-banner .promo__title {
  font-size: 72px;
  line-height: 0.902777777777778;
  text-transform: uppercase;
  font-family: $novito;
  margin: 0 0 23px;
}
@media (max-width: 768px) {
  .birthday-banner .promo__title {
    font-size: 56px;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .birthday-banner .promo__title {
    font-size: 40px;
  }
}
.birthday-banner .promo__description {
  margin: 0;
}
.birthday-banner .promo__btn-more {
  font-size: 13px;
  line-height: 1.230769230769231;
  margin: 39px 0 0;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding: 20px 33px;
}
@media (max-width: 768px) {
  .birthday-banner .promo__btn-more {
    padding: 15px 26px 13px;
    margin-top: 30px;
    font-size: 12px;
  }
}
.birthday-banner .normal .promo__info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 100%;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  min-height: 280px;
}
@media (max-width: 768px) {
  .birthday-banner .normal .promo__info {
    padding: 0;
    max-width: 100%;
    font-size: 13px;
  }
}
/*# sourceMappingURL=src/js/components/Banner/Banner.css.map */