//@import "nib"
@import "~vars"


.form__error
	display flex
	font-size 12px
	lh(14)
	align-items flex-end
	min-height $errorHeight
	color $danger
	&.-center
		justify-content center
		align-items center

	&.-start
		align-items flex-start

.form__footer-error
	min-height 0
	margin 4px 0 0