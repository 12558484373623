.create-password-with-email-verification__form {
  margin: 0;
  max-width: 450px;
}
.create-password-with-email-verification__form .create-password-with-email-verification_description {
  font-size: 16px;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .create-password-with-email-verification_description {
    font-size: 14px;
  }
}
.create-password-with-email-verification__form .btn-submit {
  margin-top: 40px;
}
.create-password-with-email-verification__form .email_from-field {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .email_from-field {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.create-password-with-email-verification__form .email_from-field .form__input-holder {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 20px 0 0;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .email_from-field .form__input-holder {
    width: 100%;
    -webkit-box-flex: unset;
    -moz-box-flex: unset;
    -o-box-flex: unset;
    box-flex: unset;
    -webkit-flex: unset;
    -ms-flex: unset;
    flex: unset;
  }
}
.create-password-with-email-verification__form .email_from-field .email-controls {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  margin: 0 20px 0 0;
}
@media (max-width: 768px) {
  .create-password-with-email-verification__form .email_from-field .email-controls .info-icon__control .ui-tooltip {
    display: block;
  }
}
.create-password-with-email-verification__form .email_from-field .email-controls .info-icon__control .info-icon {
  width: 25px;
  height: 25px;
}
@media (max-width: 768px) {
  .create-password-with-email-verification__form .email_from-field .email-controls .info-icon__control {
    display: inline-block;
  }
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .email_from-field .email-controls .info-icon__control {
    position: absolute;
    right: 20px;
    top: 28px;
  }
}
.create-password-with-email-verification__form .email_from-field .email-controls .create-password-with-email__form-btn {
  margin-left: 20px;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .email_from-field .email-controls .create-password-with-email__form-btn {
    margin-top: 30px;
    margin-left: 0;
  }
}
.create-password-with-email-verification__form .email_from-field .email-controls .create-password-with-email__form-btn .btn {
  height: 40px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.create-password-with-email-verification__form .password-form-field {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .password-form-field {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.create-password-with-email-verification__form .password-form-field .form__input-holder {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 20px 0 0;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .password-form-field .form__input-holder:first-child {
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .password-form-field .form__input-holder {
    width: 100%;
  }
}
.create-password-with-email-verification__form .otp_form-field {
  position: relative;
  margin: 0 20px 0 0;
}
.create-password-with-email-verification__form .otp_form-field .try-again-btn {
  margin-left: auto;
  margin-top: 5px;
  text-align: right;
  width: 110px;
  font-size: 11px;
  line-height: 1.272727272727273;
  color: #a2a2a2;
}
.create-password-with-email-verification__form .otp_form-field .try-again-btn:hover {
  color: #333;
  text-decoration: underline;
  cursor: pointer;
}
.create-password-with-email-verification__form .otp_form-field .form__input.disabled {
  line-height: 28px;
  margin-top: -11px;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .otp_form-field .form__input.disabled {
    line-height: 40px;
    margin-top: -23px;
  }
}
.create-password-with-email-verification__form .form__label.two-line-label.focused .form__label-value {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.create-password-with-email-verification__form .form__label.two-line-label .form__label-value {
  font-size: 12px;
  -webkit-transform: translate(0, 22px);
  -moz-transform: translate(0, 22px);
  -o-transform: translate(0, 22px);
  -ms-transform: translate(0, 22px);
  transform: translate(0, 22px);
  height: 33px;
  white-space: normal;
  overflow: auto;
  -o-text-overflow: unset;
  text-overflow: unset;
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .form__label.two-line-label .form__label-value {
    height: 42px;
  }
}
.create-password-with-email-verification__form .form__label.two-line-label.not-empty .form__label-value {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media (max-width: 500px) {
  .create-password-with-email-verification__form .form__label.two-line-label.not-empty {
    height: 42px;
  }
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/RecoveryPasswordAndEmailEntry/RecoveryPasswordAndEmailEntry.css.map */