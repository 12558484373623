@import "~vars"

.fixed-mark
	fixed right top 50%
	margin -1px 0
	height 2px
	width 50px
	background red
	z-index 1000

.change-coupon-type-bar-dummy
	min-height 84px
	margin ($padding/ 2) (- $padding) (- $padding)
	+tablet()
		margin-right -34px
		margin-left -34px

.change-coupon-type-bar
	&.fixed
		fixed left bottom
		width 100%
		overflow hidden
		z-index 1

		.change-coupon-type-bar__content
			margin 0 $gutter 0 ($menuWidth + $gutter)
			@media only screen and (max-width $menuToggle)
				margin 0 $paddingXS
			+tablet()
				padding 10px 20px
				margin 0
		@media only screen and (max-width $menuToggle)
			.change-coupon-type-bar__body
				min-width $minWidth

.change-coupon-type-bar__body
	max-width $maxWidth
	min-width $minWidth

.change-coupon-type-bar__content
	background #fbfbfb
	border-top 1px solid #ededed
	display flex
	padding 23px $padding
	+tablet()
		padding 10px 20px
		align-items center
		justify-content space-between

.change-coupon-type-bar__right-part
	margin-left auto
	+tablet()
		display flex
		flex-wrap wrap
		align-items center
		justify-content flex-end

.change-coupon-type-bar__btn-change-selected
.change-coupon-type-bar__btn-change-all
	margin-left 20px
	min-width 180px
	+tablet()
		min-width 140px
		margin 5px 0 0 20px

.change-coupon-type-bar__btn-cancel
	+tablet()
		width 120px
		min-width 120px

.change-coupon-type-bar__message
	margin-left auto
	font-size 13px
	lh(16)
	text-transform uppercase
	font-weight $semibold
	padding 11px 0

	.icon-check
		margin-left 10px
		margin-top -3px