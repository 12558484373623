.personal-proposals-block-intro {
  position: relative;
}
.personal-proposals-block-intro h1 {
  padding: 0;
}
.personal-proposals-block-intro .personal-proposals-block__intro-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #a2a2a2;
}
@media (max-width: 768px) {
  .personal-proposals-block-intro .personal-proposals-block__intro-content {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .personal-proposals-block-intro .personal-proposals-block__intro-content {
    font-size: 13px;
  }
}
.personal-proposals-block-intro .personal-proposals-block__intro-content .personal-proposals-block__intro-previous-link {
  font-size: 14px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .personal-proposals-block-intro .personal-proposals-block__intro-content .personal-proposals-block__intro-previous-link {
    margin-top: 20px;
  }
}
@media (max-width: 500px) {
  .personal-proposals-block-intro .personal-proposals-block__intro-content .personal-proposals-block__intro-previous-link {
    font-size: 13px;
  }
}
.personal-proposals-block-intro .personal-proposals-block__intro-content .personal-proposals-block__intro-previous-link .icon-arrow-right {
  width: 11px;
  height: 10px;
  margin-left: 8px;
}
.personal-proposals-block-intro .personal-proposals-block__intro-content .personal-proposals-block__intro-previous-link .icon-arrow-right svg {
  width: 100%;
  height: 100%;
}
.personal-proposals-block-intro .personal-proposals-block__intro-text {
  margin: 0 50px 25px 0;
}
.personal-proposal-block {
  background: #f9f9f9;
  padding: 40px 50px 50px;
}
@media (max-width: 768px) {
  .personal-proposal-block {
    padding: 40px 30px 50px;
    margin: 0 -15px;
  }
}
@media (max-width: 500px) {
  .personal-proposal-block {
    margin: 0;
    padding: 40px 15px 0;
  }
}
.electronic-proposals-list,
.paper-proposals-list {
  position: relative;
}
@media (max-width: 768px) {
  .electronic-proposals-list,
  .paper-proposals-list {
    padding: 0;
  }
}
.electronic-proposals-list .personal-proposals__electronic .ui-tooltip,
.paper-proposals-list .personal-proposals__electronic .ui-tooltip {
  max-width: 385px;
}
.electronic-proposals-list .personal-proposals__paper .ui-tooltip,
.paper-proposals-list .personal-proposals__paper .ui-tooltip {
  max-width: 385px;
}
.electronic-proposals-list .special-proposals__list-heading,
.paper-proposals-list .special-proposals__list-heading {
  margin: 64px 0 10px;
  font-family: $proxima;
  font-weight: 600;
  font-size: 20px;
}
.electronic-proposals-list .special-proposals__list-heading:first-child,
.paper-proposals-list .special-proposals__list-heading:first-child {
  margin-top: 0;
}
@media (max-width: 500px) {
  .electronic-proposals-list .special-proposals__list-heading,
  .paper-proposals-list .special-proposals__list-heading {
    font-size: 18px;
  }
}
.withoutBefore:before {
  display: none;
}
@media (max-width: 500px) {
  .withoutBefore:before {
    display: initial;
  }
}
/*# sourceMappingURL=src/js/components/PersonalProposalsBlock/PersonalProposalsBlock.css.map */