.filters-wrapper {
  margin-left: 6px;
  position: relative;
}
.filter-btn {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #d3d3d3;
  padding: 9px;
  outline: none;
  text-decoration: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.filter-btn.active {
  background: #79b928;
  border: 1px solid #79b928;
}
.filter-btn.active .icon svg > path {
  stroke: #fff;
}
.filters-list {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  width: 188px;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  -webkit-box-shadow: 0px 4px 24px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 24px rgba(0,0,0,0.08);
}
.filters-list span {
  font-size: 14px;
  line-height: 16px;
}
.filters-list ul {
  margin: 16px 0 0;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/Filters/Filters.css.map */