.success-repeat-modal__data {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 20px;
}
@media (max-width: 500px) {
  .success-repeat-modal__data {
    padding: 0;
    height: 90vh;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
.success-repeat-modal__data .success-repeat-modal__button.reversed {
  border: 1px solid #79b928;
  margin-right: 8px;
}
.success-repeat-modal__data .success-repeat-modal__button {
  text-transform: none;
}
.success-repeat-modal__data .success-repeat-modal__button:hover {
  background: #60a51b !important;
  color: #fff !important;
}
.success-repeat-modal__data .success-repeat-modal__title {
  font-size: 22px;
  font-weight: 600;
  margin: 35px 0 30px;
  text-align: center;
}
@media (max-width: 500px) {
  .success-repeat-modal__data .success-repeat-modal__title {
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  .success-repeat-modal {
    padding: 0 !important;
  }
}
/*# sourceMappingURL=src/js/components/Modals/SuccessRepeatOrder/SuccessRepeatOrder.css.map */