@import "~vars"

.modal-overlay
	-webkit-tap-highlight-color transparent
	fixed left top
	right 0
	bottom 0
	cursor pointer
	pointer-events all
	background rgba(#f2f2f2, .9)
	+phone()
		background #ffffff

	.modal-window
		-webkit-tap-highlight-color transparent
		pointer-events all
		background white
		box-shadow $boxShadow
		position relative
		margin auto
		width 100%
		max-width 930px
		min-height 300px
		cursor auto
		padding 30px
		box-sizing border-box
		+tablet()
			padding 20px
		+phone()
			box-shadow none
			margin: 0;
			padding: 40px 20px 0;

		.modal-closeButton
			cursor pointer
			position: absolute;
			right: 0;
			top: 0;
			padding 10px
			margin 20px
			+tablet()
				margin 5px
			+phone()
				margin 0;
				padding 0

			.modal-closeIcon
				fill #a4a4a4
				transition fill  0.3s ease-in-out
				&:hover
					fill #494949