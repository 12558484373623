@import "~vars"

.confirm-modal__dialog
	padding 70px 0 73px 0
	+phone()
		padding-right 20px
		padding-left 20px

.confirm-modal__heading
	text-align center

.confirm-modal__snippet
	font-size 16px
	line-height 1.375
	text-align center

	&:before
		content ''
		width 28px
		height 2px
		background $primary
		display block
		margin 25px auto 21px

.confirm-modal__buttons-group
	display flex
	align-items center
	justify-content center
	margin 55px 0 0 -20px

	button
		min-width 100px
		margin 0 0 0 20px

	.button-no
		background: white;
		color: $primaryHover;
		border: 1px $primaryHover solid;

