.bonus-history-filter {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-top: 15px;
}
.bonus-history-filter .button-switch {
  width: 280px;
  height: 34px;
  border: 1px solid $gray-shop;
  border-radius: 17px;
  background-color: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  max-width: 100%;
}
@media (max-width: 500px) {
  .bonus-history-filter .button-switch {
    width: 100%;
  }
}
.bonus-history-filter .button-switch.many {
  width: auto;
}
.bonus-history-filter .button-switch .button-switch-item {
  background-color: #fff;
  border-right: 1px solid $gray-shop;
  color: #000;
  font-size: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 33%;
  -ms-flex: 1 1 33%;
  flex: 1 1 33%;
}
.bonus-history-filter .button-switch .button-switch-item:last-child {
  border-right: initial;
}
.bonus-history-filter .button-switch .button-switch-item.active {
  background-color: #79b928;
  color: #fff;
  cursor: unset;
}
.bonus-history-filter .button-switch .button-switch-item.disabled {
  background: #a2a2a2;
  color: #fff;
  cursor: not-allowed;
  border-right: 1px solid #fff;
}
/*# sourceMappingURL=src/js/components/BonusHistoryFilter/BonusHistoryFilter.css.map */