@import "~vars"

.personal-proposals-page
	.personal-proposals-intro
		position relative
		h1
			padding 0
		.personal-proposals__intro-content
			display flex
			justify-content space-between
			margin-bottom $gutter
			color $gray
			+tablet()
				flex-direction column
			+phone()
				font-size 13px

			.personal-proposals__intro-previous-link
				font-size 14px
				white-space nowrap
				+tablet()
					margin-top 20px
				+phone()
					font-size 13px
				.icon-arrow-right
					size(11px, 10px)
					margin-left 8px
					svg
						size(100%, 100%)
		.personal-proposals__intro-text
			margin 0 50px 25px 0


