.proposal-like-control .icon {
  width: 22px;
  height: 24px;
  cursor: pointer;
}
.proposal-like-control .icon.active svg path {
  fill: #79b928;
  -webkit-transition: fill 0.35s;
  -moz-transition: fill 0.35s;
  -o-transition: fill 0.35s;
  -ms-transition: fill 0.35s;
  transition: fill 0.35s;
}
.proposal-like-control .dislike {
  margin-right: 20px;
}
.proposal-like-control .like svg .a,
.proposal-like-control .dislike svg .a {
  -webkit-transition: fill 0.35s;
  -moz-transition: fill 0.35s;
  -o-transition: fill 0.35s;
  -ms-transition: fill 0.35s;
  transition: fill 0.35s;
  stroke: none;
}
/*# sourceMappingURL=src/js/components/ProposalsList/ProposalLike/ProposalLike.css.map */