.modal-overlay .modal-window.recovery-modal {
  max-width: 550px;
}
.modal-overlay .modal-window.recovery-modal .card-recovery-dialog__panel {
  margin: auto;
}
.modal-overlay .modal-window.recovery-modal .get-card__back-link {
  margin: 0;
  display: inline-block;
  color: #a2a2a2;
  font-size: 14px;
  line-height: 1.285714285714286;
  letter-spacing: 0.015em;
}
.modal-overlay .modal-window.recovery-modal .get-card__back-link:hover {
  color: #79b928;
}
.modal-overlay .modal-window.recovery-modal .get-card__back-link:hover .back-link__icon path {
  fill: #79b928;
}
.modal-overlay .modal-window.recovery-modal .get-card__back-link .back-link__icon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.modal-overlay .modal-window.recovery-modal .get-card__back-link .back-link__icon path {
  fill: #a2a2a2;
}
.modal-overlay .modal-window.recovery-modal .card-recovery-dialog__text {
  font-size: 16px;
  line-height: 1.25;
  color: #a2a2a2;
  margin: 20px 0;
}
.modal-overlay .modal-window.recovery-modal .card-recovery-dialog__ol {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-left: -15px;
  margin-bottom: 10px;
}
.modal-overlay .modal-window.recovery-modal .card-recovery-dialog__ol > li {
  margin-left: 15px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.modal-overlay .modal-window.recovery-modal .panel-footer {
  border-top: 1px solid #e8e8e8;
  margin-bottom: -25px;
  padding: 25px 0;
}
.modal-overlay .modal-window.recovery-modal .card-recovery-dialog__hint {
  font-size: 12px;
  line-height: 1.333333333333333;
  color: #a2a2a2;
  margin: 0;
}
.modal-overlay .modal-window.recovery-modal .ordered-list {
  counter-reset: list_item;
  margin: auto;
}
.modal-overlay .modal-window.recovery-modal .ordered-list > li {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 10px 0;
}
@media (max-width: 1023px) {
  .modal-overlay .modal-window.recovery-modal .ordered-list > li {
    margin-bottom: 30px;
  }
}
.modal-overlay .modal-window.recovery-modal .ordered-list > li:before {
  content: counter(list_item);
  counter-increment: list_item;
  font-size: 72px;
  line-height: 0.972222222222222;
  color: #79b928;
  margin: -20px 15px 0 0;
}
@media (max-width: 768px) {
  .modal-overlay .modal-window.recovery-modal .ordered-list > li:before {
    font-size: 50px;
    min-width: 15px;
    margin-top: -15px;
  }
}
/*# sourceMappingURL=src/js/components/Modals/СardRecoveryModal/СardRecoveryModal.css.map */