@media (max-width: 500px) {
  .modal-overlay.welcome-overlay {
    padding: 0;
  }
}
.modal-overlay.welcome-overlay .modal-window.auth-modal {
  width: 650px;
  padding: 0;
}
@media (max-width: 768px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal {
    max-width: 590px;
  }
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: space-evenly;
  -moz-box-pack: space-evenly;
  -o-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 768px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper {
    width: auto;
  }
}
@media (max-width: 500px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper {
    max-width: none;
    width: 100%;
    margin-bottom: 0;
    height: auto;
  }
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .mobile-apps-block {
  background-color: transparent;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .mobile-apps-block .mobile-apps-block__iOS-link-block-mobile,
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .mobile-apps-block .mobile-apps-block__android-link-block-mobile {
  text-align: center;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .mobile-apps-block .mobile-apps-block__iOS-link-block-mobile a span svg,
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .mobile-apps-block .mobile-apps-block__android-link-block-mobile a span svg {
  width: 100%;
  height: 100%;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .modal-description {
  margin: 20px 0;
}
@media (max-width: 500px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .modal-description {
    margin: 0;
  }
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .modal-description p {
  font-size: 16px;
  max-width: 490px;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .modal-title {
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .modal-title h3 {
  margin: 0;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .welcome__qr {
  text-align: center;
  max-width: 200px;
  margin: auto;
  padding-top: 13px;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider {
  width: 100%;
  color: #ccc;
  text-align: center;
  position: relative;
  padding: 10px;
  display: block;
  font-size: 12px;
  margin: 25px 0;
}
@media (max-width: 500px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider {
    margin: 15px 0;
  }
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider:before,
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 45%;
  background-color: #ccc;
}
.modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider:after {
  left: unset;
  right: 0;
}
@media (max-width: 500px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider:after {
    margin-right: 0;
    width: 45%;
  }
}
@media (max-width: 500px) {
  .modal-overlay.welcome-overlay .modal-window.auth-modal .welcome__wrapper .text-divider:before {
    margin-left: 0;
    width: 45%;
  }
}
/*# sourceMappingURL=src/js/components/Modals/WelcomeModal/WelcomeModal.css.map */