.change-time-modal__dialog .change-time-modal__switcher.bonus-history-filter {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin: 30px 0;
}
.change-time-modal__dialog .change-time-modal__switcher.bonus-history-filter .button-switch {
  width: 310px;
  border: none;
  border-radius: 0;
  margin: 0 auto;
}
.change-time-modal__dialog .change-time-modal__switcher.bonus-history-filter .button-switch-item {
  border-right: none;
  border-radius: 17px;
  border: 1px solid #79b928;
  margin-right: 5px;
  -webkit-transition: background-color 0.35s;
  -moz-transition: background-color 0.35s;
  -o-transition: background-color 0.35s;
  -ms-transition: background-color 0.35s;
  transition: background-color 0.35s;
  font-weight: 600;
  padding: 0 12px;
}
@media (max-width: 500px) {
  .change-time-modal__dialog .change-time-modal__switcher.bonus-history-filter .button-switch-item {
    padding: 0 8px;
  }
}
.change-time-modal__dialog .change-time-modal__switcher.bonus-history-filter .button-switch-item:hover:not(.active) {
  color: #000;
  background-color: #efefef;
}
.change-time-modal__dialog .radio-btn-like-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 0 10px;
}
.change-time-modal__dialog .radio-btn-like-list .radio-btn-like-item {
  -webkit-transition: background-color 0.35s;
  -moz-transition: background-color 0.35s;
  -o-transition: background-color 0.35s;
  -ms-transition: background-color 0.35s;
  transition: background-color 0.35s;
  border: 1px solid #79b928;
  margin-bottom: 15px;
  min-width: 135px;
  font-weight: 600;
  font-size: 13px;
}
@media (max-width: 500px) {
  .change-time-modal__dialog .radio-btn-like-list .radio-btn-like-item {
    min-width: 130px;
  }
}
.change-time-modal__dialog .radio-btn-like-list .radio-btn-like-item:not(.radio-btn-like-disabled):hover:not(.radio-btn-like-active) {
  background-color: #efefef;
}
.change-time-modal__dialog .radio-btn-like-list .radio-btn-like-item.radio-btn-like-disabled {
  border-color: rgba(138,138,138,0.3);
  color: rgba(138,138,138,0.3);
  background-color: #fff;
}
.change-time-modal__heading {
  text-align: center;
  margin-bottom: 0;
  font-family: $proxima;
  font-weight: bold;
  font-size: 22px;
  color: #111;
}
.change-time-modal__subtitle {
  margin-bottom: 10px;
}
.change-time-modal__buttons-group {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 20px;
}
.change-time-modal__buttons-group button {
  text-transform: initial;
  font-size: 16px;
  min-width: 182px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  height: 34px;
  padding: 0;
}
/*# sourceMappingURL=src/js/components/MyOrdersList/EcomOrderItem/ChangeTimeModal/ChangeTimeModal.css.map */