@import "nib"
@import "~vars"

.link-all
	text-transform uppercase
	font-weight $semibold
	font-size 13px
	lh(16)
	color $primary
	display inline-flex
	text-decoration none
	transition all $tdur

.link-all__icon
	margin-left 8px
	vertical-align top
	margin-top 1px