.unused-balance-lines {
  margin-top: 20px;
  padding: 25px;
  background-color: #fff;
  border-radius: 5px;
}
.unused-balance-lines.open .icon-chevron-down {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.unused-balance-lines.open .content-line {
  max-height: 250px;
  padding-top: 25px;
}
.unused-balance-lines .title-line {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.unused-balance-lines .title-line .title {
  margin: 0 15px 0 0;
  font-size: 22px;
  line-height: 1;
  cursor: pointer;
}
@media (max-width: 500px) {
  .unused-balance-lines .title-line .title {
    font-size: 18px;
  }
}
.unused-balance-lines .title-line .icon-chevron-down {
  cursor: pointer;
}
.unused-balance-lines .content-line {
  margin: 0;
  padding: 0 40px;
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
}
@media (max-width: 768px) {
  .unused-balance-lines .content-line {
    padding: 0 10px;
  }
}
.unused-balance-lines .content-line .balance-line {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.unused-balance-lines .content-line .balance-line:last-child {
  margin-bottom: 0;
}
.unused-balance-lines .content-line .balance-line:hover {
  cursor: pointer;
}
.unused-balance-lines .content-line .balance-line_icon {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.unused-balance-lines .content-line .balance-line_icon .icon-balance {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
}
.unused-balance-lines .content-line .balance-line_title {
  margin-left: 15px;
  font-size: 24px;
  max-width: calc(100% - 35px);
}
@media (max-width: 500px) {
  .unused-balance-lines .content-line .balance-line_title {
    font-size: 18px;
  }
}
/*# sourceMappingURL=src/js/components/UnusedBalanceLines/UnusedBalanceLines.css.map */