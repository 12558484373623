.back-link {
  color: #a2a2a2;
  font-size: 14px;
  line-height: 1.285714285714286;
  letter-spacing: 0.015em;
}
.back-link__icon {
  width: 11px;
  height: 10px;
  margin-right: 10px;
  margin-top: -4px;
}
/*# sourceMappingURL=src/js/components/UI/BackLink/BackLink.css.map */