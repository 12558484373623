@import "~vars"

.personal-account-card-form
	margin 0
	max-width 100%
	.form__field
		margin-bottom  0
		&.has-card_form-field
			max-width 340px
			.radio-label
				width 50%
				.radio-label__title
					text-transform none
					font-size 14px
		&.barcode_form-field
			padding-top 10px
			+media(634px)
				padding-top 15px
			.error-without-label
				span
					+media(634px)
						margin-bottom -20px
			.form__label.form__label--focusable
				.form__label-value
					font-size 12px
					+media(634px)
						white-space normal
						overflow auto
						text-overflow unset
						height 30px
	.btn-submit
		margin-top 30px !important
		padding 9px 52px 6px
