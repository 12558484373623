.purchases-list-item {
  height: 56px;
  width: 100%;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
  margin-bottom: 14px;
  position: relative;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  color: #000;
  overflow: hidden;
}
.purchases-list-item:hover {
  background: -webkit-linear-gradient(90deg, #f8f8f8, #f8f8f8), #fff;
  background: -moz-linear-gradient(90deg, #f8f8f8, #f8f8f8), #fff;
  background: -o-linear-gradient(90deg, #f8f8f8, #f8f8f8), #fff;
  background: -ms-linear-gradient(90deg, #f8f8f8, #f8f8f8), #fff;
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8), #fff;
}
.purchases-list-item.isActive {
  height: auto;
  border: 1px solid #79b928;
}
.purchases-list-item.isActive:hover {
  background: none;
}
.purchases-list-item:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .purchases-list-item {
    height: 92px;
  }
}
.purchases-list-item .purchases-item-delivery {
  font-size: 20px;
  line-height: 16px;
  margin-right: 8px;
}
@media (max-width: 768px) {
  .purchases-list-item .purchases-item-delivery {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .purchases-list-item .purchases-item-datetime {
    display: none;
  }
}
.purchases-list-item .purchases-item-date,
.purchases-list-item .purchases-item-order {
  color: #a2a2a2;
  font-size: 16px;
  line-height: 16px;
}
@media (max-width: 768px) {
  .purchases-list-item .purchases-item-date,
  .purchases-list-item .purchases-item-order {
    font-size: 14px;
  }
}
.purchases-list-item .purchases-item-order {
  font-weight: 600;
  font-family: "ExoBold", Helvetica, Arial, sans-serif;
}
.purchases-list-item .purchases-item-order span {
  font-family: sans-serif;
}
.purchases-list-item .purchases-item-sum,
.purchases-list-item .purchases-item-sum-mobile {
  -webkit-flex-basis: 90px;
  flex-basis: 90px;
  text-align: center;
  font-size: 14px;
}
@media (max-width: 768px) {
  .purchases-list-item .purchases-item-sum-mobile {
    margin-bottom: 8px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 768px) {
  .purchases-list-item .purchases-item-sum {
    display: none;
  }
}
.purchases-list-item .purchases-item-status {
  padding: 3.5px 10px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 768px) {
  .purchases-list-item .purchases-item-status {
    font-size: 14px;
    line-height: 17px;
  }
}
.purchases-list-item .purchases-item-status.new {
  border: 1px solid #ff9500;
  color: #ff9500;
}
.purchases-list-item .purchases-item-status.ready {
  border: 1px solid #79b928;
  color: #79b928;
}
.purchases-list-item .purchases-item-status.refused {
  border: 1px solid #747474;
  color: #747474;
}
.purchases-list-item .icon-faq-arrow {
  color: #79b928;
  position: absolute;
  top: 16px;
  right: 14px;
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  z-index: 0;
}
@media (max-width: 768px) {
  .purchases-list-item .icon-faq-arrow {
    top: 21px;
    display: none;
  }
}
.purchases-list-item .icon-faq-arrow.isActive {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.purchases-list-item-info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 14px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  -webkit-column-gap: 90px;
  -moz-column-gap: 90px;
  column-gap: 90px;
}
@media (max-width: 1300px) {
  .purchases-list-item-info {
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
  }
}
@media (max-width: 768px) {
  .purchases-list-item-info {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-column-gap: auto;
    -moz-column-gap: auto;
    column-gap: auto;
    -webkit-box-align: baseline;
    -moz-box-align: baseline;
    -o-box-align: baseline;
    -ms-flex-align: baseline;
    -webkit-align-items: baseline;
    align-items: baseline;
    height: 42px;
    padding: 8px;
  }
}
.purchases-list-item-info-mobile {
  display: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .purchases-list-item-info-mobile {
    display: block;
    height: 48px;
    padding: 0 8px 8px;
  }
}
.purchases-item-main {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-basis: 335px;
  flex-basis: 335px;
}
@media (max-width: 768px) {
  .purchases-item-main {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/PurchasesList/components/PurchaseItem/PurchaseItem.css.map */