.united-exit-main-form {
  max-width: unset;
  margin-top: 0;
}
.united-exit-main-form .btn-submit {
  margin-top: 30px;
  min-width: 194px;
}
@media only screen and (max-width: 430px) {
  .united-exit-main-form .barcode_form-field .error-without-label span {
    margin-bottom: -20px;
  }
}
@media only screen and (max-width: 430px) {
  .united-exit-main-form .barcode_form-field .form__label.form__label--focusable .form__label-value {
    white-space: normal;
    overflow: auto;
    -o-text-overflow: unset;
    text-overflow: unset;
    height: 32px;
  }
}
.united-exit-main-form .united-exit__heading {
  margin-bottom: 30px;
}
.united-exit-main-form .united-exit__links-wrapper {
  margin-top: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.united-exit-main-form .united-exit__links-wrapper .united-exit__link-item {
  margin: 0 15px 40px;
}
.united-exit-main-form .united-exit__text {
  color: #333;
}
.united-exit-main-form .text-divider {
  width: 100%;
  color: #a2a2a2;
  text-align: center;
  position: relative;
  display: block;
}
.united-exit-main-form .text-divider:before,
.united-exit-main-form .text-divider:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 45%;
  background-color: #a2a2a2;
}
.united-exit-main-form .text-divider:after {
  left: unset;
  right: 0;
}
.united-exit-main-form .united-exit-main-form__submit {
  margin-top: 10px;
}
.united-exit-main-form .united-exit-main-form__error {
  margin-bottom: 10px;
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/PhoneEntrance/PhoneEntrance.css.map */