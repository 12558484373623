@import "~vars"

.contact-recovery-form
	margin 0
	max-width 490px

	.contact-recovery-password-form__btn
		width 165px
	.radio-buttons__contact
		margin 50px 0
		+phone()
			margin 45px 0
		.radio-label
			display block
			font-size 16px
			margin 8px 0
			+phone()
				font-size 14px
			.radio-label__content
				color $gray
			&.checked
				.radio-label__content
					color black