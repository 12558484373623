#sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 11;
  min-width: 320px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  -ms-transition: opacity 1s;
  transition: opacity 1s;
}
#sidebar[hidden] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  -ms-transition: opacity 1s;
  transition: opacity 1s;
}
.menu-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-right: 1px solid #e8e8e8;
  background: #fff;
  width: 326px;
  pointer-events: auto;
  overflow-y: auto;
  -webkit-box-shadow: 1px 0 20px -10px #000;
  box-shadow: 1px 0 20px -10px #000;
  -webkit-transition: left 0.35s;
  -moz-transition: left 0.35s;
  -o-transition: left 0.35s;
  -ms-transition: left 0.35s;
  transition: left 0.35s;
}
.menu-sidebar .navigation-block {
  padding: 0 30px;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .menu-sidebar .navigation-block {
    padding: 0 20px;
    margin-bottom: 10px;
  }
}
.menu-sidebar .menu__body {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 768px) {
  .menu-sidebar .menu__body {
    padding: 5px 0;
  }
}
.menu-sidebar .menu__body.another-section {
  padding-top: 0;
  max-height: 40px;
  overflow: hidden;
  -webkit-transition: max-height 0.7s;
  -moz-transition: max-height 0.7s;
  -o-transition: max-height 0.7s;
  -ms-transition: max-height 0.7s;
  transition: max-height 0.7s;
}
@media (max-width: 768px) {
  .menu-sidebar .menu__body.another-section {
    padding-top: 5px;
    max-height: 50px;
  }
}
.menu-sidebar .menu__body.another-section.open {
  max-height: 320px;
}
.menu-sidebar .menu__body.another-section.open .main-menu-title {
  padding-bottom: 0;
  color: #79b928;
}
.menu-sidebar .menu__body.another-section.open .main-menu-title .dropdown-icon {
  color: currentColor;
}
.menu-sidebar .menu__body.another-section .main-menu-title {
  padding-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  text-transform: uppercase;
  -webkit-transition: padding-bottom 0.7s;
  -moz-transition: padding-bottom 0.7s;
  -o-transition: padding-bottom 0.7s;
  -ms-transition: padding-bottom 0.7s;
  transition: padding-bottom 0.7s;
  font-size: 13px;
}
@media (max-width: 768px) {
  .menu-sidebar .menu__body.another-section .main-menu-title {
    padding: 14px 0 5px;
  }
}
.menu-sidebar .menu__body.another-section .main-menu-title .to-main-menu-title {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.menu-sidebar .menu__body.another-section .main-menu-title .dropdown-icon {
  cursor: pointer;
  color: #333;
}
.menu-sidebar .menu__body.another-section .main-menu-title .dropdown-icon svg {
  width: 9px;
}
.close-button {
  display: none;
  float: right;
  cursor: pointer;
  padding: 15px;
  width: initial;
}
@media (max-width: 1100px) {
  .close-button {
    display: block;
  }
}
.close-button svg {
  width: 15px;
  height: 15px;
}
.sidebar-hr {
  margin: 0;
  height: 1px;
  background: #fff;
}
.sidebar-hr::before {
  background: none;
}
.menu-button {
  margin: 0 auto 40px;
  width: calc(100% - 58px);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  padding: 8px 20px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .menu-button {
    width: calc(100% - 40px);
    margin: 0 auto 5px;
  }
}
.menu-button:hover,
.menu-button:focus {
  background: #fff !important;
  color: #79b928 !important;
  border: 1px solid #79b928;
}
.menu-button>i {
  margin-right: 8px;
}
@media (max-width: 1100px) {
  .menu-sidebar {
    width: 284px;
  }
}
.menu-sidebar #logo {
  display: block;
  padding: 10px 0 40px;
}
@media (max-width: 768px) {
  .menu-sidebar #logo {
    padding: 20px;
  }
}
.menu-sidebar #logo-svg {
  width: 162px;
  margin: 0 auto;
  display: block;
}
@media (max-width: 768px) {
  .menu-sidebar #logo-svg {
    margin: 0;
  }
}
/*# sourceMappingURL=src/js/components/Layout/Menu/SidebarMenu/SidebarMenu.base.css.map */