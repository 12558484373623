.Select {
  font-size: 14px;
  line-height: 17px;
  display: block;
}
.Select .Select__placeholder {
  color: transparent;
}
.Select .Select__menu {
  max-height: 380px;
  padding: 10px 0;
  border: none;
  border-radius: 0;
}
.Select .Select__control {
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
  border: none;
}
.Select .Select__control.Select__control--is-focused:not(.Select__control--menu-is-open) > .Select__control,
.Select .Select__control:hover:not(.is-disabled) > .Select__control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}
.Select .Select__control.Select__control--is-focused .icon-select-arrow,
.Select .Select__control:hover:not(.is-disabled) .icon-select-arrow {
  color: #333;
}
.Select .Select__control.Select__control--menu-is-open .icon-select-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.Select .Select__control .Select__value-container {
  padding-left: 0;
}
.icon-select-arrow {
  color: #a2a2a2;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -ms-transition: color 0.2s;
  transition: color 0.2s;
}
.Select__control {
  height: auto;
  border-radius: 0;
  border: 0 !important;
  background: none !important;
}
.Select__control .Select__indicators {
  cursor: pointer;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 55px;
  padding-right: 5px;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.Select__control .Select__indicators .ui-select-arrow {
  margin-left: auto;
  display: block;
}
.Select__control .Select__indicators .Select__clear-indicator {
  display: none;
}
.Select__control .Select__indicators svg {
  width: 7px;
  height: 5px;
}
.Select__placeholder,
.Select__single-value > .Select__control .Select__value {
  padding: 0;
  padding-right: 20px;
  line-height: 17px;
}
.Select__input {
  padding: 0;
  height: auto;
}
.Select__input > input {
  padding: 0;
  font-size: 14px;
  line-height: 17px;
  max-width: 100%;
}
.Select__input:empty:before {
  content: " ";
  display: inline-block;
}
.Select__placeholder,
.Select__single-value > .Select__control .Select__value,
.Select__input {
  padding-top: 7px;
  padding-bottom: 7px;
}
.Select-menu-outer {
  border-radius: 0;
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  margin-top: 10px;
  max-height: none;
}
.Select-menu-outer:before,
.Select-menu-outer:after {
  content: '';
  border-width: 7px;
  position: absolute;
  right: 7px;
  bottom: 100%;
  border-style: solid;
  border-top-width: 0 !important;
  border-color: #e0e0e0 transparent;
}
.Select-menu-outer:after {
  border-width: 6px;
  margin-right: 1px;
  border-color: #fff transparent;
}
.Select__option {
  background: #fff;
  padding: 6px 25px;
  color: #333;
  font-size: 14px;
  line-height: 1.428571428571429;
  border-radius: 0;
}
.Select__option:last-child {
  border-radius: 0;
}
.Select__option.is-selected,
.Select__option.is-focused {
  color: #573f7b;
  background: #fff;
}
.Select.is-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.Select.is-disabled.form__input {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.Select.is-disabled .Select-control {
  cursor: not-allowed;
}
.Select.is-disabled .Select-arrow-zone {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.Select.theme--violet:before,
.Select.theme--violet:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  right: 0;
  height: 1px;
  background: #a2a2a2;
}
.Select.theme--violet:after {
  height: 2px;
  background: #79b928;
  width: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.Select.theme--violet.error:after {
  background: #f71f2f;
}
.Select.theme--violet.has-value:after,
.Select.theme--violet.is-focused:after,
.Select.theme--violet.error:after {
  width: 100%;
}
.Select__indicator-separator {
  display: none;
}
.Select-multi-value-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.Select-value-label {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: inline-table;
}
/*# sourceMappingURL=src/js/components/UI/Select/Select.css.map */