.my-contacts-number-form {
  max-width: none;
  margin: 0;
}
.my-contacts-number-form .form-row.inline-row {
  margin-left: 0;
}
.my-contacts-number-form .field--title input {
  font-size: 18px;
  line-height: 1.222222222222222;
}
/*# sourceMappingURL=src/js/forms/MyContactsNumberForm/MyContactsNumberForm.css.map */