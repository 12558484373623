.ordered-list {
  counter-reset: list_item;
  margin: auto;
  padding: 0;
}
.ordered-list > li {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 10px 0;
}
@media (max-width: 1023px) {
  .ordered-list > li {
    margin-bottom: 30px;
  }
}
.ordered-list > li:before {
  content: counter(list_item);
  counter-increment: list_item;
  font-size: 70px;
  line-height: 1;
  color: #79b928;
  text-transform: uppercase;
  font-family: $novito;
  margin: -20px 15px 0 0;
}
@media (max-width: 768px) {
  .ordered-list > li:before {
    font-size: 50px;
    min-width: 18px;
    margin-top: -12px;
  }
}
/*# sourceMappingURL=src/js/components/UI/OL/OL.css.map */