@import "~vars"

.my-info-page
	+phone()
		.form-row
			flex-direction column
			margin-left 0
		.form__field
			width 100%
			margin-left 0

.my-info__personal-information
	margin $gutter 0