@import "~vars"

.modal-window.help-modal
	font-family $exo
	padding 30px
	.modal-heading
		font-family $exo
		font-weight 700
		margin 0 0 30px 0
		+phone()
			margin 0 20px 20px 0

.help-modal
	.flag
		margin 0 2px
	.help-modal__body
		display grid
		grid-template-columns 1fr 1fr
		gap 18px
		.item-wrapper
			height 233px
			border 1px solid $borderColor
			border-radius 12px
			display flex
			flex-direction column
			align-items center
			justify-content space-between
			padding 34px
			.text
				color $primary
				font-size 18px
				line-height 20px
				font-weight 600
				text-align center
			&:hover
				box-shadow 0 4px 10px rgba(0, 0, 0, 0.3)
				.text
					text-decoration underline
		+phone()
			gap 12px
			.item-wrapper
				height 176px
				padding 23px 5px
				justify-content flex-start
				img
					height 57px
					margin-bottom 19px
				.text
					font-size 14px
