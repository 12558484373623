.drop-down-title {
  border: 1px solid rgba(51,51,51,0.5);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.538461538461539;
  display: block;
  width: 100%;
  background: none;
  position: relative;
  padding: 8px 31px 7px 18px;
  border-radius: 20px;
  -webkit-transition: border-color 0.35s;
  -moz-transition: border-color 0.35s;
  -o-transition: border-color 0.35s;
  -ms-transition: border-color 0.35s;
  transition: border-color 0.35s;
  text-align: left;
  cursor: inherit;
  background: #fff;
}
.drop-down-title-value {
  width: 100%;
}
.drop-down-title-caret {
  position: absolute;
  right: 8px;
  top: 0;
  width: 23px;
  height: 100%;
  display: none;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
@media (max-width: 1023px) {
  .drop-down-title-caret {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
.drop-down-title-value,
.drop-down-title-caret {
  -webkit-transition: color 0.35s;
  -moz-transition: color 0.35s;
  -o-transition: color 0.35s;
  -ms-transition: color 0.35s;
  transition: color 0.35s;
}
.no-border .drop-down-title {
  border-color: transparent;
}
/*# sourceMappingURL=src/js/components/DropDown/DropDownTitle/DropDownTitle.css.map */