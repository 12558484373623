@import "~vars"

.controls-panel
	margin 0
	max-width initial
	zoom initial
	display flex
	align-items flex-start
	flex-wrap wrap
	max-height 0
	overflow-y hidden
	transition max-height $tdur
	&.open
		max-height 500px


	.switch-col
		margin-top 20px
		margin-left 37px
		flex 0 1 400px
		+phone()
			margin-left 0
		.radio-label
			margin-bottom 20px
			.radio-label__title
				text-transform initial
				color #4F4F4F
				font-size 15px
				font-weight bold
				+phone()
					font-size 14px
				&.checked
					color $primary
			.radio-label__content
				color #7B7B7B
				font-size 14px
				+phone()
					font-size 12px

	.description-col
		margin-top 20px
		margin-left 37px
		flex 0 2 400px
		font-size 15px
		+tablet()
			font-size 14px
		+phone()
			display none

	.button-col
		margin 20px 0 0 auto
		align-self flex-end
		+phone()
			margin 20px auto 0
		.moneybox-btn
			width 220px
			height 50px
			border-radius 25px 0
			font-size 18px
			font-weight normal
			text-transform initial
			+tablet()
				height initial
				width 160px
				font-size 16px
			+phone()
				font-size 14px