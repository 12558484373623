.form {
  display: block;
  max-width: 340px;
  padding: 0;
  margin: 50px auto;
  zoom: 1;
  font-size: 14px;
  line-height: 1.428571428571429;
}
.form:before,
.form:after {
  content: "";
  display: table;
}
.form:after {
  clear: both;
}
.form-input-hidden {
  width: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 100%;
  bottom: 100%;
  left: auto;
  top: auto;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.form-hint {
  font-size: 12px;
  line-height: 1.25;
  color: #a2a2a2;
  margin: 9px 0;
}
.form__footer {
  border-top: 1px solid #e5e5e5;
  padding: 27px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.form__footer-status {
  margin: 9px 0 0;
}
.form__footer-success {
  color: #79b928;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.form__footer-success .icon-circle-check {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.form__footer-btn {
  margin-left: auto;
}
.form-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  margin-left: -20px;
}
.form-row > .form-row,
.form-row > .form__field {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin-left: 20px;
}
.form-row > .inline-row__field {
  margin-left: 0;
}
.inline-row {
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.inline-row .inline-row__label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.inline-row .form__input input {
  padding: 15px 0;
  height: 59px;
}
.inline-row__label {
  padding: 17px 15px 20px 0;
  margin: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.inline-row__label,
.inline-row__buttons {
  border-bottom: 1px solid #a2a2a2;
}
.inline-row__buttons {
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 12px 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  border-bottom: 1px solid #a2a2a2;
}
.inline-row__button {
  padding: 0;
  width: 35px;
  height: 35px;
  color: #a2a2a2;
}
.inline-row__button:disabled {
  color: rgba(162,162,162,0.5);
}
.inline-row__button:not(:disabled):hover {
  color: #333;
}
.form__field {
  margin-bottom: 40px;
}
.inline-row__field {
  margin-bottom: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.form__label {
  display: block;
  font-size: 12px;
  line-height: 1.166666666666667;
  padding-top: 14px;
  -webkit-transition: opacity 0.35s;
  -moz-transition: opacity 0.35s;
  -o-transition: opacity 0.35s;
  -ms-transition: opacity 0.35s;
  transition: opacity 0.35s;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  color: #a2a2a2;
}
.form__label.two-line-label.form__label--focusable.focused .form__label-value {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.form__label.two-line-label.form__label--focusable .form__label-value {
  font-size: 12px;
}
@media only screen and (max-width: 634px) {
  .form__label.two-line-label.form__label--focusable .form__label-value {
    white-space: normal;
    overflow: auto;
    -o-text-overflow: unset;
    text-overflow: unset;
    height: 30px;
  }
}
.form__error + .form__label.form__label--focusable {
  margin-top: -28px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.form__error + .form__label.form__label--focusable.form__label--focusable:not(.not-empty) {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.form__label.form__label--focusable {
  pointer-events: none;
}
.form__label.form__label--focusable .form__label-value {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  height: 16px;
  -webkit-transition: -webkit-transform 0.35s, font-size 0.35s;
  -moz-transition: -moz-transform 0.35s, font-size 0.35s;
  -o-transition: -o-transform 0.35s, font-size 0.35s;
  -ms-transition: -ms-transform 0.35s, font-size 0.35s;
  transition: transform 0.35s, font-size 0.35s;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.form__label.form__label--focusable.focused .form__label-value {
  -webkit-transform: translate(0, 0) !important;
  -moz-transform: translate(0, 0) !important;
  -o-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}
.form__label.form__label--focusable.form__label--select:not(.not-empty):not(.searchable) .form__label-value,
.form__label.form__label--focusable.form__label--select.searchable:not(.not-empty):not(.focused) .form__label-value {
  -webkit-transform: translate(0, 35px);
  -moz-transform: translate(0, 35px);
  -o-transform: translate(0, 35px);
  -ms-transform: translate(0, 35px);
  transform: translate(0, 35px);
}
.form__label.form__label--focusable:not(.focused):not(.not-empty) .form__label-value {
  -webkit-transform: translate(0, 22px);
  -moz-transform: translate(0, 22px);
  -o-transform: translate(0, 22px);
  -ms-transform: translate(0, 22px);
  transform: translate(0, 22px);
}
.form__label.form__label--focusable:not(.focused):not(.not-empty):not(.two-line-label) .form__label-value {
  font-size: 14px;
  line-height: 1.142857142857143;
}
.form__label-value {
  display: block;
}
.form__input-holder {
  padding-top: 28px;
  position: relative;
}
.form__input-holder .form__error,
.form__input-holder .form__label {
  margin-top: -28px;
}
.form__input-holder.disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
.form__input-holder--inline {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 0;
}
.form__input-holder--inline .form__error {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -o-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  margin-top: 6px;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  min-height: 0;
}
.form__input {
  font-size: 14px;
  line-height: 17px;
  color: #333;
}
.form__input input {
  display: block;
  width: 100%;
  color: inherit;
  font-size: inherit;
  font-family: "Lato";
  line-height: inherit;
  border: 0;
  padding: 7px 0;
  margin: 0;
  text-align: inherit;
}
.form__input input:focus {
  outline: none;
}
.form__input-border {
  height: 1px;
  background: #a2a2a2;
  display: block;
}
.form__input-border:before {
  content: '';
  height: 2px;
  background: #79b928;
  margin: -1px auto 0;
  width: 0;
  display: inline-block;
  vertical-align: top;
  -webkit-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.form__input-border.focused:before,
.form__input-border.not-empty:before,
.form__input-border.error:before {
  width: 100%;
}
.form__input-border.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.form__input-border.error:before {
  background: #f71f2f;
}
.is-zoomed .form__input-border:not(.is-empty):not(.error):not(.focused) {
  height: 2px;
}
.form__textarea {
  position: relative;
  display: block;
  line-height: 25px;
  background-image: -webkit-linear-gradient(transparent, transparent 24px, #a8a7a7 0px);
  background-image: -moz-linear-gradient(transparent, transparent 24px, #a8a7a7 0px);
  background-image: -o-linear-gradient(transparent, transparent 24px, #a8a7a7 0px);
  background-image: -ms-linear-gradient(transparent, transparent 24px, #a8a7a7 0px);
  background-image: linear-gradient(transparent, transparent 24px, #a8a7a7 0px);
  background-size: 100% 25px;
}
.form__textarea-fake,
.form__textarea-input {
  white-space: pre-line;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  display: block;
  border: 0;
  min-height: 19px;
  background: none;
  letter-spacing: 0.02em;
}
.l-body.ios .form__textarea-fake,
.l-body.ios .form__textarea-input {
  white-space: pre;
}
.form__textarea-input {
  resize: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.form__textarea-fake {
  display: block;
  color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.form__textarea-fake:after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
}
.form__select-holder .form__label-value {
  padding-right: 20px;
}
.form__select-holder .Select-placeholder {
  color: transparent;
}
.form__checkbox-holder {
  padding-left: 30px;
}
.form__checkbox-label {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.form__checkbox--labeled {
  margin-left: -30px;
  float: left;
}
.form__checkbox {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #a2a2a2;
  position: relative;
  cursor: pointer;
}
.form__checkbox:not(:disabled):hover {
  color: #e6e6e6;
  border-color: #79b928;
}
.form__checkbox:disabled {
  cursor: not-allowed;
}
.form__checkbox.checked {
  color: #79b928;
  border-color: #79b928;
}
.form__checkbox.checked .form__checkbox-icon {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.form__checkbox-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
}
.form__checkbox-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.radio-buttons {
  margin-left: -15px;
}
.radio-button__holder {
  margin: 5px 0;
  margin-left: 15px;
  display: inline-block;
  min-width: 68px;
  text-align: center;
}
.radio-button {
  border: 1px solid rgba(51,51,51,0.3);
  display: block;
  border-radius: 40px;
  color: rgba(51,51,51,0.5);
  font-size: 13px;
  line-height: 1.538461538461539;
  padding: 8px 15px 7px;
  cursor: pointer;
}
.radio-button.checked {
  color: #79b928 !important;
  border-color: #79b928 !important;
}
.radio-button__input {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.radio-button__input:focus + .radio-button {
  color: rgba(51,51,51,0.8);
  border-color: rgba(51,51,51,0.5);
}
.form__input-holder--inline {
  padding: 0;
}
/*# sourceMappingURL=src/js/components/Form/Form.css.map */