@import "~vars"

.change-password__form
	margin 22px auto -25px
	max-width none

	.form__body
		max-width 711px

	hr
		margin 25px 0

	.form__text
		margin 0 0 5px
		line-height (24 / 16)

	.old-password__hint
		margin 4px 0 -15px
		text-align right

	.old-password__hint-link
		text-decoration underline
		color inherit

	.tooltip__change-password
		width (320px - 2 * 24px)

		ul
			list-style disc
			padding-left 16px

	.form__footer
		border none
		margin-top 0
		padding-top 10px
		+tablet()
			margin-top 0
			border none
		.form__footer-btn
			margin-left 0