@import "~vars"
.proposal-list
	margin 0 0 $gutter (- $gutter)
	+tablet()
		display flex
		flex-wrap wrap
	+media(630px)
		flex-direction column

	> li
		display inline-block
		vertical-align top
		padding-left $gutter
		margin-top $gutter
		width 33.33%
		+small-wide()
			width 50%
			max-width 50%
		+laptop()
			width 50%
			display inline-block
			flex 1 0 50%
		+media(630px)
			max-width 100%
			width 100%