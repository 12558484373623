.social-block {
  padding: 0 30px;
}
@media (max-width: 768px) {
  .social-block {
    padding: 0 20px;
  }
}
.menu__footer-icon-wrapper {
  width: 45px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .menu__footer-icon-wrapper {
    width: 40px;
  }
  .menu__footer-icon-wrapper svg {
    width: 25px;
  }
}
.social-networks-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.social-networks-container .social-network-link {
  margin-top: 15px;
  padding: 0 0 3px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #79b928;
  font-size: 14px;
  line-height: 1.428571428571429;
}
.social-networks-container .social-network-link:hover {
  font-weight: bold;
}
@media (max-width: 768px) {
  .social-networks-container .social-network-link {
    margin-top: 10px;
  }
}
.email-hot-line {
  margin-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.email-hot-line .email-hot-line__title {
  margin: 0;
  color: #848484;
  font-size: 12px;
  font-family: "Lato";
  text-transform: none;
}
.email-hot-line .email-hot-line__email {
  color: #333;
  font-size: 14px;
  font-family: "Lato";
}
.hot-line {
  margin-top: 25px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .hot-line {
    margin-top: 20px;
  }
}
.hot-line .hot-line__title {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-family: "Lato";
  text-transform: none;
  letter-spacing: inherit;
  line-height: 1.25;
}
@media (max-width: 768px) {
  .hot-line .hot-line__title {
    font-size: 14px;
  }
}
.hot-line .hot-line__number {
  margin: 1px 0 3px;
  color: #79b928;
  font-size: 28px;
  font-family: "Lato";
  letter-spacing: 0.05px;
  line-height: 1;
}
@media (max-width: 768px) {
  .hot-line .hot-line__number {
    font-size: 22px;
  }
}
/*# sourceMappingURL=src/js/components/Layout/Menu/SidebarMenu/SocailBlock/SocialBlock.base.css.map */