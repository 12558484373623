@import "~vars"

.menu-account
	padding 10px 0 15px
	margin-left 20px

	.menu-account__user-data
		margin-bottom 20px
		display flex
		flex-direction row
		align-items flex-end

		.account__user-icon
			width 35px
			flex-shrink 0

		.account__user-name
			font-size 16px
			line-height 1
			color $color

	.menu-account__user-bonuses
		line-height 1

		.bonus-count
			margin-bottom 5px
			font-size 14px

		.bonus-value
			.bonus-amount
				font-size 30px
				font-family $font

			.bonus-currency
				margin-left 5px
				font-size 14px

	.menu-account__user-points
		margin-top 15px
		font-size 14px
		line-height 1
		color #A8A7A7

	.menu-account__how-save-points
		position relative
		display block
		font-size 15px

		.icon-arrow-right
			color $primary
			position relative
			transform translate(50%, -15%)


@import projectStylesheet($project)
