.my-contacts__numbers {
  margin-top: 15px;
}
.my-contacts__numbers-heading {
  margin: 22px 0 25px;
}
.my-contacts__numbers-text {
  margin: 25px 0;
  width: 648px;
}
@media (max-width: 768px) {
  .my-contacts__numbers-text {
    width: 100%;
  }
}
.my-contacts__numbers__list {
  max-width: 520px;
  margin: 25px 0;
}
.my-contacts__numbers__list-add-holder {
  display: inline-block;
  width: 100%;
}
@media (max-width: 768px) {
  .my-contacts__numbers__list-add-holder {
    position: relative;
  }
  .my-contacts__numbers__list-add-holder .inline-row {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .my-contacts__numbers__list-add-holder .inline-row__buttons {
    position: absolute;
    border-bottom: none;
    right: 0;
    top: 0;
  }
}
.my-contacts__numbers__list-item {
  border-bottom: 1px solid #e8e8e8;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 60px;
}
@media (max-width: 500px) {
  .my-contacts__numbers__list-item {
    display: block;
  }
}
.my-contacts__numbers__list-item-title {
  margin: auto 0;
  font-size: 18px;
  line-height: 1.222222222222222;
  padding: 0 15px 0 0;
}
@media (max-width: 500px) {
  .my-contacts__numbers__list-item-title {
    float: left;
    width: 100%;
    padding-top: 5px;
  }
}
.my-contacts__numbers__list-item-value {
  font-size: 14px;
  line-height: 1.285714285714286;
}
@media (max-width: 500px) {
  .my-contacts__numbers__list-item-value {
    float: left;
    padding-top: 10px;
  }
}
.my-contacts__numbers__list-item-value,
.my-contacts__numbers__list-item-title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 225px;
  margin: auto 0;
}
.my-contacts__numbers__list-item-value .innerText,
.my-contacts__numbers__list-item-title .innerText {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.my-contacts__numbers__list-item-controls {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin: auto 0;
}
@media (max-width: 500px) {
  .my-contacts__numbers__list-item-controls {
    float: left;
    padding-left: 15px;
  }
}
.my-contacts__numbers__list-item-button {
  padding: 0;
  width: 35px;
  height: 35px;
  color: #a2a2a2;
}
@media (max-width: 500px) {
  .my-contacts__numbers__list-item-button {
    height: 32px;
  }
}
.my-contacts__numbers__list-item-button:not(:disabled):hover {
  color: #333;
}
.my-contacts__numbers__list-item-button:disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.my-contacts__numbers__add-button {
  display: block;
  margin: 20px auto;
  border-width: 0;
  float: left;
  padding: 0;
  color: #a2a2a2;
}
.my-contacts__numbers__add-button .icon {
  margin: -3px 7px 0 0;
}
.my-contacts__numbers__add-button:not(:disabled):hover {
  color: #333;
}
/*# sourceMappingURL=src/js/components/MyContactsNumbers/MyContactsNumbers.css.map */