@import "~vars"

.my-cards
	background white
	padding ($padding/ 2) $padding
	margin-top $gutter
	+tablet()
		padding 30px 20px

.my-cards__heading
	margin 34px 0 23px
	+tablet()
		margin-top 0

.cards__table
	width 100%
	max-width 840px
	font-size 15px
	line-height 20px
	color $gray

.cards__cell
	padding 11px 20px
	+tablet()
		display flex
		padding-left 0
		padding-right 0
		color $gray

	&:first-child
		padding-left 0

	&:last-child
		padding-right 0

.cards__card-label
	width 50%
	color black
	text-transform uppercase
	font-size 14px
	+phone()
		font-size 12px

.cards__head-th
	font-size 12px
	lh(15)
	font-weight $normal
	border-bottom 1px solid $gray-l
	text-align left

.cards__row
	+tablet()
		border-bottom 1px solid $gray-l
		display block
		padding-bottom 10px
		margin-bottom 10px

	&.active
		color $color

	&:first-child
		.cards__cell
			padding-top 17px
			+tablet()
				padding-top 6px

	&:last-child
		.cards__cell
			padding-bottom 20px

.cards__row-controls
	text-align right

.cards__card-date
.cards__card-type
.cards__card-status
	padding-top 1px

.cards__card-date
	color $gray

.cards__card-number
	font-size 18px
	+phone()
		font-size 15px

.cards__card-controls
	padding-top 2px


.cards__foot-td
	border-top 1px solid $gray-l
	+tablet()
		border none
		padding 0

	&.-right
		text-align right
		+tablet()
			button
				margin $gutter auto 0

.cards__button
	padding 0
	border 0
	color $gray
	font-weight $normal

	&:hover
	&:focus
		color $color

	.icon-lock-opened
	.icon-lock-closed
		margin-left 11px
		margin-top -2px

	.icon-circle-plus
		margin-right 7px
		margin-top -2px

.cards__button-add
	margin 12px 0


// INFO
.my-cards__info
	background white
	padding ($padding/ 2) $padding
	margin-top $gutter
	color $gray
	letter-spacing 0
	+tablet()
		padding 30px 20px

.my-cards__info-heading
	color $color
	text-transform none
	letter-spacing inherit
	font-family $font
	font-weight $normal
	margin 5px 0 18px

.my-cards__info-text
	margin 20px 0 10px
	max-width 840px