.collapse-item {
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  -ms-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}
.collapse-item.collapse-item__open {
  max-height: 10000px;
}
/*# sourceMappingURL=src/js/components/UI/Collapse/Collapse.css.map */