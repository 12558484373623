.contact-recovery-form {
  margin: 0;
  max-width: 490px;
}
.contact-recovery-form .contact-recovery-password-form__btn {
  width: 165px;
}
.contact-recovery-form .radio-buttons__contact {
  margin: 50px 0;
}
@media (max-width: 500px) {
  .contact-recovery-form .radio-buttons__contact {
    margin: 45px 0;
  }
}
.contact-recovery-form .radio-buttons__contact .radio-label {
  display: block;
  font-size: 16px;
  margin: 8px 0;
}
@media (max-width: 500px) {
  .contact-recovery-form .radio-buttons__contact .radio-label {
    font-size: 14px;
  }
}
.contact-recovery-form .radio-buttons__contact .radio-label .radio-label__content {
  color: #a2a2a2;
}
.contact-recovery-form .radio-buttons__contact .radio-label.checked .radio-label__content {
  color: #000;
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/RecoveryContactSelection/RecoveryContactSelection.css.map */