@import "~vars"

.moneybox-page

	.moneybox-intro
		min-height 360px
		padding 25px 50px
		background-color $white
		background-image embedurl("../../../img/account/moneybox/bg-moneybox-intro.svg", "utf8")
		background-repeat no-repeat
		background-position bgPosition(961 * 100 / 1143, 484px) 12px
		+desktop()
			background-image none
		+tablet()
			min-height 0
			padding 30px 20px

		.moneybox-intro__heading
			margin 40px 0
			font-size 48px
			+tablet()
				margin 30px 0
				font-size 32px
		.moneybox-intro__text
			max-width 710px
			margin 30px 0 20px
			line-height 1.4
		.moneybox-intro__text-details
			font-size 12px

	// CONDITIONS
	.moneybox-conditions
		margin-top $gutter
		padding 25px 50px
		background-color $white
		+tablet()
			padding 30px 20px
		+phone()
			padding-bottom 50px
			position relative

		.moneybox-conditions__head
			margin 20px 0 0
			display flex
			align-items baseline

		.moneybox-conditions__heading
			margin 0
			font-size 30px
			+tablet()
				font-size 20px

		.moneybox-conditions__link
			margin-left auto
			font-size 14px
			lh(20)
			color $gray
			+phone()
				position absolute
				bottom 30px
				left 20px

			.icon-arrow-right svg
				margin-top -1px
				margin-left 10px
				size(11px, 10px)

		.moneybox-conditions__list
			+tablet()
				flex-direction column

			.icon-text-list__li
				flex 0 1 33%