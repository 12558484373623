@import "~vars"

.purchases-page
	background $white
	width calc(100% + 30px)
	margin-left -15px
	margin-top -76px
	padding 76px 20px 20px
	font-family $exo
	.bread-crumbs
		padding-left 0
.purchases-header
	display flex
	align-items center
	justify-content space-between
	margin-top 14px
	+tablet()
		flex-wrap wrap
		flex-direction column
	.header-filters
		display flex
		align-items center
		justify-content space-between
		+tablet()
			margin-top 14px