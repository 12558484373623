.ui-tooltip.__react_component_tooltip,
.ui-tooltip.react-tooltip {
  font-size: 14px;
  line-height: 1.428571428571429;
  padding: 14px 24px 15px;
  font-family: "Lato";
  border-right: 5px;
  letter-spacing: 0;
  pointer-events: all;
  left: 655px;
  top: 362px;
  z-index: 10;
}
@media (max-width: 768px) {
  .ui-tooltip.__react_component_tooltip,
  .ui-tooltip.react-tooltip {
    display: none;
  }
  .ui-tooltip.__react_component_tooltip:after,
  .ui-tooltip.react-tooltip:after {
    display: none;
  }
}
.ui-tooltip.__react_component_tooltip.show,
.ui-tooltip.react-tooltip.show {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.ui-tooltip.__react_component_tooltip.place-right,
.ui-tooltip.react-tooltip.place-right {
  margin-left: 20px;
}
.ui-tooltip.__react_component_tooltip.type-dark,
.ui-tooltip.react-tooltip.type-dark {
  color: #fff;
  background: #333;
}
.ui-tooltip.__react_component_tooltip.type-dark.place-right:after,
.ui-tooltip.react-tooltip.type-dark.place-right:after {
  border-style: solid;
  border-width: 7px;
  border-left-width: 0;
  border-color: transparent;
  border-right-color: #333;
  left: auto;
  right: 100%;
  margin-top: -7px;
}
.ui-tooltip.__react_component_tooltip.place-bottom,
.ui-tooltip.react-tooltip.place-bottom {
  margin-top: 20px;
}
.ui-tooltip.__react_component_tooltip.place-bottom.place-bottom:after,
.ui-tooltip.react-tooltip.place-bottom.place-bottom:after {
  border-bottom-color: #333;
  border-width: 7px;
  top: auto;
  bottom: 100%;
}
.ui-tooltip.__react_component_tooltip.place-left,
.ui-tooltip.react-tooltip.place-left {
  margin-right: 20px;
}
.ui-tooltip.__react_component_tooltip.place-left.place-left:after,
.ui-tooltip.react-tooltip.place-left.place-left:after {
  border-left-color: #333;
  border-width: 7px;
  right: auto;
  left: 100%;
}
.ui-tooltip.__react_component_tooltip.place-top,
.ui-tooltip.react-tooltip.place-top {
  margin-bottom: 20px;
}
.ui-tooltip.__react_component_tooltip.place-top.place-top:after,
.ui-tooltip.react-tooltip.place-top.place-top:after {
  border-top-color: #333;
  border-width: 7px;
  bottom: auto;
  top: 100%;
}
/*# sourceMappingURL=src/js/components/UI/Tooltip/Tooltip.css.map */