@import "~vars"

.password-panel
	display flex
	.password-holder
		flex 1
	.password-button
		padding 0 10px
		.icon
			color $gray
			width 20px
			height 20px