.otp-form {
  max-width: 590px;
  margin: 0;
}
.otp-form .otp-form_description {
  font-size: 16px;
  padding-bottom: 30px;
}
@media (max-width: 500px) {
  .otp-form .otp-form_description {
    font-size: 14px;
  }
}
.otp-form .otp-form__password-field {
  margin-bottom: 20px;
  position: relative;
}
.otp-form .otp-form__try-again-btn,
.otp-form .otp-form__go_to_password-btn {
  position: absolute;
  right: 0;
  font-size: 11px;
  line-height: 1.272727272727273;
  color: #a2a2a2;
  margin: 5px 0 0;
  text-decoration: underline;
}
.otp-form .otp-form__try-again-btn:hover,
.otp-form .otp-form__go_to_password-btn:hover {
  color: #333;
  text-decoration: underline;
  cursor: pointer;
}
.otp-form .otp-form__go_to_password-btn {
  left: 0;
  color: #79b928;
  width: fit-content;
}
.otp-form .otp-form__go_to_password-btn.mobile {
  display: none;
}
@media (max-width: 500px) {
  .otp-form .otp-form__go_to_password-btn {
    display: none;
  }
  .otp-form .otp-form__go_to_password-btn.mobile {
    display: block;
    margin: 24px auto 0;
    position: static;
  }
}
.otp-form .btn-submit {
  min-width: 194px;
  margin-top: 30px;
}
@media (max-width: 500px) {
  .otp-form .btn-submit {
    display: block;
    margin: 0 auto;
  }
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/OtpEntry/OtpEntry.css.map */