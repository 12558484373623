@import "~vars"

.modal-overlay
	.modal-window.card-added-modal
		max-width 590px
		text-align center
		padding 30px
		display flex
		align-items center

		+phone()
			padding 0
		.card-added_description
			+phone()
				font-size 15px
		.card-added_heading
			display flex
			align-items center
			justify-content center
			.card_added_heading-text
				text-align center
		.continue_button
			margin-top 20px
			+phone()
				margin-top 10px
		.icon-check
			margin-right 10px
			+phone()
				margin-right 0
			svg
				size(25px, 25px)
				color green