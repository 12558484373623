@import "~vars"

.personal-information__form
	margin 22px auto -25px
	max-width none
	+tablet()
		margin 22px 0 -25px
	+phone()
		margin 0

	h3, h1
		margin 0
	hr
		margin 25px 0
		+phone()
			margin 15px 0

	.form__text
		margin 28px 0 11px
		+phone()
			margin 15px 0 11px

	.form__body
		max-width 711px

	.radio-buttons__sex
		margin-top 4px
		margin-bottom -6px


	.form__footer
		margin-top 37px
		+tablet()
			border none
			margin-top 0

	.btn-submit
		margin-left auto
		width 165px
		+phone()
			margin 0

	.radio-button__holder
		min-width 58px
		text-transform uppercase