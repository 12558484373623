.success-payment-modal > div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.success-payment-modal .page-heading {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  margin: 30px 0 15px;
}
.success-payment-modal .page-text-charity {
  color: #787878;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 1.3;
  max-width: 398px;
  margin: 0 auto 25px auto;
  text-align: center;
}
.success-payment-modal .page-text-charity .order-id {
  color: #79b928;
}
.success-payment-modal .payment-warning {
  padding: 12px 16px 12px 18px;
  background: rgba(122,185,41,0.1);
  margin: 0 auto 25px auto;
  border-radius: 5px;
  color: #79b928;
  font-size: 12px;
  line-height: 15.95px;
  font-weight: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  max-width: 400px;
}
.success-payment-modal .payment-warning > .icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.success-payment-modal .payment-warning svg {
  width: 22px;
  height: 20px;
  margin-top: 2px;
}
.success-payment-modal .choose_market_btn:focus {
  background: #79b928;
}
.success-payment-modal .choose_market_btn:hover {
  background: #e72f2a;
}
/*# sourceMappingURL=src/js/components/Modals/SuccessPaymentModal/SuccessPaymentModal.css.map */