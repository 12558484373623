@import "~vars"

.history-list__item-body
	padding 15px 0

	/*_________PRODUCTS_BLOCK________*/
	.products
		padding 11px 0 35px
		border-bottom 1px solid #E5E5E4
		border-bottom-style dashed
		+tablet()
			padding 0 0 24px

		.check-number
			font-size 32px
			font-weight $semibold
			display flex
			align-items center
			position relative
			+phone()
				font-size 18px
			a
				display inline-flex
				+phone()
					margin-left auto
			.button_e-check
				border-radius 30px
				width 37px
				height 37px
				padding 0
				margin-left 16px
				padding 7px 11px
				text-overflow clip
				line-height normal
				&:not(:disabled)
					&:hover
					&:focus
						background $primary
						width 190px
						+phone()
							width 37px
					&:hover
						background #6CAF21
					&:focus
						background #60A51B
				@media (max-width: 1200px)
					width 190px
				@media (max-width: 1100px)
					width 37px
				+tablet()
					width 190px
					margin-right 12px
				+phone()
					width 37px
					&:hover
					&:focus
						width 37px
				.icon-receipt
					margin-right 11px
				span
					display inline-block
					padding-top 2px
			.buttton_share
				background transparent
				height 37px
				width 190px
				border-radius 30px
				font-weight 400
				line-height normal
				margin-left auto
				margin-right 20px
				&:not(:disabled)
					&:hover
					&:focus
						border-color $primary
						color $primary
					&:hover
						background #E3EED7
					&:focus
						background #D9E8C9
				@media (max-width: 1200px)
					width 37px
					min-width 37px
					padding 0 10px
				@media (max-width: 1100px)
					width 190px
					padding 7px 19px 8px
				+tablet()
					margin-right 0
					width 37px
					min-width 37px
					padding 0 10px
				+phone()
					margin-left 0
				.icon-share
					margin-right 10px
				span
					display inline-block
					padding-top 2px
		.product-list
			margin 0
			padding 0

			.product-list-item
				margin-top 20px
				padding 0
				width 100%
				display flex
				flex-direction row
				font-size 16px
				color $gray-dark
				+tablet()
					margin-top 15px
					font-size 12px

				.product-title
					flex 6 6 50%
					.product-count--mobile
						display none
					+tablet()
						flex 0 1 50%
						.product-count--mobile
							display inline

				.product-count
					min-width 150px
					text-align right
					+tablet()
						display none

				.product-cost
					min-width 140px
					margin-right 20px
					flex-grow 1
					font-family $font
					text-align right
					+tablet()
						min-width 60px
						margin-right 0

				&.to-moneybox
					font-size 18px
					color $color
					+tablet()
						font-size 13px

					.product-title
						flex-grow 1
					.product-cost
						margin-right 20px
						flex-grow 1
						text-align right
						+tablet()
							margin-right 0

	/*_________PERSONAL_PROPOSAL_BLOCK________*/
	.personal-proposals
		padding 25px 0
		border-bottom 1px solid #E5E5E4
		+tablet()
			padding 15px 0

		.discount
			display flex
			flex-direction row

			.discount-title
				flex-grow 1
				font-size 16px
				font-weight $semibold
				+tablet()
					font-size 13px

			.discount-sum
				margin-right 20px
				flex-grow 1
				font-size 18px
				text-align right
				+tablet()
					margin-right 0
					font-size 13px

		.personal-proposal-caption
			margin-top 10px
			font-size 16px
			font-weight $semibold
			+tablet()
				margin-top 5px
				font-size 13px


		.personal-proposal-list
			height 170px
			padding 30px 30px 0
			overflow-x auto
			overflow-y hidden
			white-space nowrap
			display flex
			align-items flex-start
			+tablet()
				height 112px
				padding 20px 5px 0

			.personal-proposal-list-item
				height 115px
				width 340px
				margin-right 30px
				padding 15px
				background white
				box-shadow 0 0 12px rgba(0, 0, 0, 0.25)
				display inline-flex
				justify-content center
				align-items center
				flex-shrink 0
				white-space initial
				&:last-child
					margin-right 0
				+tablet()
					height 70px
					width 190px
					margin-right 15px
					box-shadow 0 0 6px rgba(0, 0, 0, 0.2)

				.list-item--inner
					width 100%
					display flex
					flex-direction row
					align-items flex-start

				.personal-proposal-value
					padding-right 20px
					display flex
					flex-direction row
					justify-content center
					align-items flex-start
					font-family $font
					font-size 60px
					+tablet()
						padding-right 10px
						font-size 36px

					.proposal-value-sign
						color #ff0000
						margin-right 5px
						+tablet()
							margin-right 3px

					.value-wrapper
						.proposal-value
							display block
							font-family $font
						.proposal-value-unit
							margin-top -15px
							display block
							font-size 18px
							+tablet()
								margin-top -10px
								font-size 14px

				.personal-proposal-title
					padding-top 10px
					font-size 18px
					+tablet()
						padding-top 6px
						font-size 14px
						span
							display block
							max-height 36px
							overflow hidden

					hr
						margin 5px 0


	/*_________COLLECTING_BLOCK________*/
	.collecting
		padding 25px 0
		border-bottom 1px solid $gray-2
		+tablet()
			padding 15px 0

		.collecting-caption
			margin-top 10px
			font-size 16px
			font-weight $semibold
			span
				color $primary
			+tablet()
				margin-top 5px
				font-size 13px

			.icon-chevron-right
				margin-left 8px

		.collecting-list
			background-color transparent
			height 175px
			padding 30px 30px 0
			overflow-x auto
			overflow-y hidden
			white-space nowrap
			display flex
			align-items flex-start
			+tablet()
				height 127px
				padding 20px 5px 0

			.collecting-list-item
				height 120px
				width 190px
				margin-right 30px
				padding 15px
				background white
				box-shadow 0 0 12px rgba(0, 0, 0, 0.25)
				display inline-flex
				flex-direction column
				justify-content flex-start
				align-items flex-start
				flex-shrink 0
				white-space initial
				&:last-child
					margin-right 0
				+tablet()
					height 85px
					width 300px
					margin-right 15px
					box-shadow 0 0 6px rgba(0, 0, 0, 0.2)

				.collecting-title
					font-size 16px
					line-height 1
					font-weight $semibold
					span
						display block
						max-height 32px
						overflow hidden
					+tablet()
						font-size 14px
						span
							max-height 14px

					hr
						margin 5px 0


				.collecting-value
					padding-top 6px
					font-size 16px
					line-height 1
					span
						display block
						max-height 16px
						overflow hidden
					+tablet()
						font-size 12px
						span
							max-height 12px

				.collecting-status
					margin-top auto
					padding-top 10px
					font-size 12px
					line-height 18px
					color $gray-dark
					+tablet()
						padding-top 6px
						font-size 12px
						line-height 1
						text-transform lowercase
						color $gray


	/*_________PAYMENT_TYPE_BLOCK________*/
	.payment-type
		padding 25px 0 10px
		display flex
		flex-direction row
		+tablet()
			padding 15px 0 0

		.payment-type-title
			flex-grow 1
			font-size 16px
			font-weight $semibold
			+tablet()
				font-size 13px

		.payment-type-value
			margin-right 20px
			flex-grow 1
			font-size 18px
			text-align right
			+tablet()
				margin-right 0
				font-size 13px

.collecting-caption
.personal-proposal-caption
.discount-title
.payment-type-title
	.icon-holder
		width 30px
		display inline-block
		text-align left
		svg
			color $primary
		+tablet()
			width 20px
.icon-holder
	+tablet()
		margin-right 12px
