@import "~vars"
//
.info-icon__control
	display inline-block
	margin-left 10px
	+tablet()
		display none

.info-icon
	size(19px)
	font-size 15px
	color white
	background $gray
	border-radius 100%
	font-style normal
	cursor pointer
	font-family $font
	text-align center
	font-weight $semibold
	padding 3px

	&:before
		content "?"
		display inline-block
		vertical-align top
		margin 0 0 0 1px

	&:hover
		background $primary