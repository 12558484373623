.radio-item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.radio-item .radio-item-tooltip .ui-tooltip {
  width: 280px;
}
.radio-item .radio-item-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 4px 0;
}
.radio-item .radio-item-wrapper.disabled {
  cursor: not-allowed;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
.radio-item label {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0;
  color: #000;
  padding-left: 38px;
  font-family: "ExoBold", Helvetica, Arial, sans-serif;
  height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.radio-item label.selected-label {
  font-weight: bold;
}
.radio-item .radio {
  display: none;
}
.radio-item .radio + label {
  position: relative;
  cursor: pointer;
}
.radio-item .radio + label:before {
  content: "";
  position: absolute;
  left: 0;
  width: 16.5px;
  height: 16.5px;
  border: 1.5px solid #79b928;
  border-radius: 50%;
  background: #fff;
  margin: 2.25px;
}
.radio-item .radio + label:after {
  content: "";
  position: absolute;
  top: 5.25px;
  left: 5.25px;
  width: 13.5px;
  height: 13.5px;
  border-radius: 50%;
  background: #79b928;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -o-transition: 0.35s;
  -ms-transition: 0.35s;
  transition: 0.35s;
}
.radio-item .radio:checked + label:after {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.radio-item .radio:focus + label:before {
  -webkit-box-shadow: 0 0 0 3px rgba(255,255,0,0.7);
  box-shadow: 0 0 0 3px rgba(255,255,0,0.7);
}
.radio-item .subitems {
  margin-left: 30px;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/RadioButton/RadioButton.css.map */