//@import "nib"
@import "~vars"

.back-link
	color $gray
	font-size 14px
	lh(18)
	letter-spacing .015em

.back-link__icon
	size(11px, 10px)
	margin-right 10px
	margin-top -4px