.menu__nav {
  display: block;
  margin: auto;
  width: 100%;
}
.menu__nav .menu__nav-list .menu__nav-link {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.230769230769231;
  margin: 14px 0;
}
@media (max-width: 768px) {
  .menu__nav .menu__nav-list .menu__nav-link {
    font-size: 13px;
  }
}
.menu__nav .menu__nav-list .menu__nav-link a {
  color: #000;
}
.menu__nav .menu__nav-list .menu__nav-link a:hover {
  color: #79b928;
}
.menu__nav .menu__nav-list .menu__nav-link span {
  cursor: pointer;
}
.menu__nav .menu__nav-list .menu__nav-link span:hover {
  color: #79b928;
}
.menu__nav .menu__nav-list .menu__nav-link span.active {
  color: #949494;
}
.menu__nav .menu__nav-list .menu__nav-link .dropdown-title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.menu__nav .menu__nav-list .menu__nav-link .dropdown-title .dropdown-arrow svg {
  width: 9px;
  color: #333;
}
.menu__nav .menu__nav-list .menu__nav-link .icon-faq-arrow {
  cursor: pointer;
}
.menu__nav .menu__nav-list .menu__nav-link.open .icon-faq-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.menu__nav .menu__nav-list .menu__nav-link .menu__nested-nav-list {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s linear;
  -moz-transition: max-height 0.5s linear;
  -o-transition: max-height 0.5s linear;
  -ms-transition: max-height 0.5s linear;
  transition: max-height 0.5s linear;
}
.menu__nav .menu__nav-list .menu__nav-link .menu__nested-nav-list .menu__nested-nav-link {
  margin-left: 20px;
  text-transform: initial;
}
/*# sourceMappingURL=src/js/components/Layout/Menu/SidebarMenu/NavigationBlock/NavigationBlock.css.map */