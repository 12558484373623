@import "~vars"

.registration-personal-info-form
	max-width 100%
	margin 0 auto 20px auto
	+phone()
		margin-top 10px
	.form-row
		+phone()
			display block
		.form__field
			width calc(50% - 20px)
			flex-grow unset
			flex-basis unset
			margin-bottom 24px
			+phone()
				width calc(100% - 20px)
				margin-bottom 30px
			.form__input-holder
				width 100%
		.spacer
			flex-basis 50%
	.btn-submit
		margin-top 20px
		padding 9px 52px 6px
	.tooltip__create-password
		width 200px
		z-index 1

	.otp_form-field
		.try-again-btn
			margin-left auto
			margin-top 5px
			text-align: right
			width: 110px
			font-size 11px
			lh(14)
			cursor pointer
			color $gray
			&:hover
				color $color
				text-decoration underline

		.form__input
			&.disabled
				line-height 28px
				margin-top -11px
				+phone()
					line-height 40px
					margin-top -23px
		.form__label
			&.two-line-label
				&.focused
					.form__label-value
						transform translate(0, 0)
				.form__label-value
					font-size 12px
					transform translate(0, 22px)
					height 33px
					white-space normal
					overflow auto
					text-overflow unset
					+phone()
						height 38px
				&.not-empty
					.form__label-value
						transform translate(0, 0)
					+phone()
						height 38px


	.email_form-field
		display flex
		justify-content space-between
		position relative
		align-items flex-end
		margin-bottom 20px
		+phone()
			flex-wrap wrap
		.form__input-holder
			flex 1
			margin 0
			+phone()
				width 100%
				flex unset
		.email-controls
			flex 1
			display flex
			align-items flex-end
			padding-left 20px
			+phone()
				padding-left 0
			.info-icon__control
				.ui-tooltip
					+tablet()
						display block
				.info-icon
					size(25px)
				+tablet()
					display inline-block
				+phone()
					position absolute
					right 0
					top 28px
			.create-password-with-email__form-btn
				margin-left 20px

				+phone()
					margin-top 30px
					margin-left 0
				.btn
					height 40px
					transition all 0.20s
	.btn-submit
		+phone()
			margin 30px auto !important
