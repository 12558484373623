.moneybox-page .moneybox-intro {
  min-height: 360px;
  padding: 25px 50px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3C?xml version=%221.0%22 encoding=%22utf-8%22?%3E %3C!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version=%221.1%22 xmlns=%22http://www.w3.org/2000/svg%22 xmlns:xlink=%22http://www.w3.org/1999/xlink%22 x=%220px%22 y=%220px%22 width=%22484px%22 height=%22420px%22 viewBox=%220 365 484 420%22 enable-background=%22new 0 365 484 420%22 xml:space=%22preserve%22%3E %3Cpath opacity=%225.000000e-02%22 d=%22M482,696.4l-35.8-159.2l-1.6-38.8c-0.2-5-3.8-9.1-8.7-10l-62.1-11c-0.1-0.1-0.1-0.2-0.1-0.2 L343,433.7c4.3-4.8,7.4-10.8,8.6-17.6c3.4-19.5-9.7-38.2-29.2-41.6c-9.8-1.7-19.3,0.7-26.9,5.9c-5.3-7.5-13.5-13-23.2-14.8 c-19.6-3.5-38.3,9.6-41.7,29.1c-1.2,6.8-0.3,13.5,2.1,19.5L189,444.6c-0.1,0.1-0.1,0.1-0.2,0.2l-62.1-11c-4.9-0.9-9.8,1.8-11.6,6.4 l-14.8,35.9L12,613.8c-13.5,22.4-15.7,46.2-6.1,67c11,24,36.5,41.2,69.9,47.1l307.4,54.2c33.4,5.9,63.2-1.5,81.8-20.3 C481,745.5,487.1,722.4,482,696.4z M318.7,395.2c8.1,1.4,13.5,9.1,12,17.2c-1.4,8-9.1,13.4-17.2,12c-8.1-1.4-13.5-9.1-12.1-17.1 C303,399.2,310.7,393.8,318.7,395.2z M268.6,386.4c8.1,1.4,13.5,9.1,12,17.2c-1.4,8-9.1,13.4-17.2,12c-8.1-1.4-13.5-9.1-12.1-17.1 C252.8,390.3,260.5,384.9,268.6,386.4z M245.9,430.7c4.1,2.7,8.7,4.7,13.9,5.6c9.8,1.7,19.3-0.7,26.9-5.9c5.3,7.5,13.5,13,23.2,14.8 c5.1,0.9,10.2,0.6,14.9-0.5l19.4,27.6l-61-10.8l-4-0.7l-61-10.8L245.9,430.7z M275.6,481.6l4,0.7l144.2,25.4l0.7,16.4l-299.4-52.8 l6.3-15.2L275.6,481.6z M449.8,747c-13.6,13.8-36.5,19-63,14.3L79.4,707.1c-26.4-4.7-46.2-17.4-54.3-35c-6.6-14.5-4.9-30.9,4.9-47.2 l85.7-133.8L426.5,546l34.8,154.7C464.9,719.6,461,735.6,449.8,747z%22/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(84.07699037620297% - -0.34076990376203 * 484px) 12px;
}
@media (max-width: 1300px) {
  .moneybox-page .moneybox-intro {
    background-image: none;
  }
}
@media (max-width: 768px) {
  .moneybox-page .moneybox-intro {
    min-height: 0;
    padding: 30px 20px;
  }
}
.moneybox-page .moneybox-intro .moneybox-intro__heading {
  margin: 40px 0;
  font-size: 48px;
}
@media (max-width: 768px) {
  .moneybox-page .moneybox-intro .moneybox-intro__heading {
    margin: 30px 0;
    font-size: 32px;
  }
}
.moneybox-page .moneybox-intro .moneybox-intro__text {
  max-width: 710px;
  margin: 30px 0 20px;
  line-height: 1.4;
}
.moneybox-page .moneybox-intro .moneybox-intro__text-details {
  font-size: 12px;
}
.moneybox-page .moneybox-conditions {
  margin-top: 15px;
  padding: 25px 50px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .moneybox-page .moneybox-conditions {
    padding: 30px 20px;
  }
}
@media (max-width: 500px) {
  .moneybox-page .moneybox-conditions {
    padding-bottom: 50px;
    position: relative;
  }
}
.moneybox-page .moneybox-conditions .moneybox-conditions__head {
  margin: 20px 0 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.moneybox-page .moneybox-conditions .moneybox-conditions__heading {
  margin: 0;
  font-size: 30px;
}
@media (max-width: 768px) {
  .moneybox-page .moneybox-conditions .moneybox-conditions__heading {
    font-size: 20px;
  }
}
.moneybox-page .moneybox-conditions .moneybox-conditions__link {
  margin-left: auto;
  font-size: 14px;
  line-height: 1.428571428571429;
  color: #a2a2a2;
}
@media (max-width: 500px) {
  .moneybox-page .moneybox-conditions .moneybox-conditions__link {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }
}
.moneybox-page .moneybox-conditions .moneybox-conditions__link .icon-arrow-right svg {
  margin-top: -1px;
  margin-left: 10px;
  width: 11px;
  height: 10px;
}
@media (max-width: 768px) {
  .moneybox-page .moneybox-conditions .moneybox-conditions__list {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.moneybox-page .moneybox-conditions .moneybox-conditions__list .icon-text-list__li {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 33%;
  -ms-flex: 0 1 33%;
  flex: 0 1 33%;
}
/*# sourceMappingURL=src/js/pages/MoneyBoxPage/MoneyBoxPage.css.map */