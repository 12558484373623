@import "~vars"


.balance-preview
	margin-top 20px
	padding 25px 60px
	background-color white
	border-radius 5px
	display flex
	flex-wrap wrap
	justify-content space-between
	+above(1921px)
		justify-content flex-start
	+tablet()
		background-color transparent
		padding 0
		flex-direction column

.balance-preview_top-wrap
	flex-grow 1
	max-width 850px
	display flex
	justify-content space-between
	+media(1700px)
		max-width 950px
	+tablet()
		padding 20px 30px
		background-color white
		border-radius 5px
		flex-direction column
		justify-content flex-start

//__CONTENT
.balance-preview_content
	max-width 400px
	line-height 1
	+tablet()
		max-width initial

	.content-title
		margin 20px 0 0
		font-size 32px
		+phone()
			font-size 24px
			font-weight 600

	.content-value
		margin-top 15px
		font-size 64px
		color $secondary
		+phone()
			font-size 50px

	.content-currency
		margin-left 5px
		font-size 24px
		color black
		+phone()
			font-size 20px

	.content-description
		margin-top 25px
		font-size 18px
		line-height 1.45
		color #7B7B7B
		a
			color $primary
		+tablet()
			margin-top 20px
		+phone()
			margin-top 15px
			font-size 14px

	.content-diagram-title
		margin 20px 0 -15px
		font-size 24px
		font-weight 600
		text-transform initial
		display none
		+phone()
			display block


//__DIAGRAM
.balance-preview_diagram
	margin-top -15px
	min-width 350px
	max-width 350px
	flexCenter()
	position relative
	+tablet()
		margin 0 auto
	+phone()
		min-width initial

	.diagram-arc
		transition stroke-width $tdur
		&.active
			stroke-width 3

	.diagram-value_wrap
		absolute-center()
		top 54%
		background-color transparent
		text-align center
		line-height 1

		.diagram-value
			font-size 64px
			color $secondary
			+phone()
				font-size 10.5vw
		.diagram-currency
			font-size 24px
			+phone()
				font-size 4vw

//__BALANCE_LINES
.balance-preview_balance-lines
	+above(1921px)
		margin-left 110px
	+tablet()
		margin 5px 0 0
		padding 20px 30px
		background-color #F9F9F9
		border-radius 5px

	.balance-line
		margin-bottom 10px
		display flex
		align-items baseline
		cursor pointer
		&:last-child
			margin-bottom 0
		&.active .icon-balance
			size(18px)

		.balance-line_icon
			size(20px)
			flexCenter()
			.icon-balance
				transition all $tdur

		.balance-line_text
			margin-left 15px
		.balance-line_title
			font-size 24px
			+phone()
				font-size 18px
		.balance-line_value
			font-size 18px
			color #747474
			+phone()
				font-size 14px