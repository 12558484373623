@import "~vars"

.purchase-payment-info-wrapper
	border 1px solid #F7F7F7
	border-radius 10px
	padding 14px
	width 50%
	+tablet()
		width 100%
		padding 8px
	.purchase-payment-type
		display flex
		justify-content space-between
		align-items center
		height 18px
		.description
			font-size 14px
			line-height 18px
		.value
			font-size 16px
			line-height 18px
			display flex
			align-items center
			.icon
				size(16px)
				margin-right 8px
				svg
					size(16px)
			span
				padding-bottom 2px
	.devider
		height 2px
		width 100%
		background #F7F7F7
		margin 14px 0
		+tablet()
			margin 10px 0
	.payment-details
		.payment-details-row
			display flex
			justify-content space-between
			align-items center
			font-family $exo-bold
			margin-bottom 16px
			+tablet()
				margin-bottom 8px
			&:last-child
				margin-bottom 0
			&.discount
				font-family $exo
				.value
					color black
		.description, .value
			font-size 14px
			line-height 16px
		.value
			color $primary
		.pay-btn
			font-size 14px
			line-height 1
			padding 3px
			width 98px
			text-transform none
			font-family $exo
			line-height 19px
			&:hover, &:focus
				background #60A51B