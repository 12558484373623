@import "~vars"

.personal-proposals-panel
	font-size 16px
	color #333
	background #fff
	padding 25px 50px
	margin-top 20px

	+tablet()
		padding 15px
	.mobile-apps-block
		background-color transparent
		justify-content start
		.mobile-apps-block__links-wrapper
			.mobile-apps-block__iOS-link-block
			.mobile-apps-block__android-link-block
				max-width 155px
	.personal-proposals__heading
		+phone()
			font-size 20px
			lh(20)
			margin 0

	.personal-proposals-description
		+phone()
			font-size 14px

	.personal-proposals__sub-heading
		margin 25px 0 10px 0
		text-transform none
		font-size 20px
		+phone()
			font-size 18px
	.divider
		height 1px
		margin 50px 0
		background-color #F2F2F2
		+phone()
			margin 25px 0
	.advice
		font-weight normal
		+phone()
				color #333
				font-size 14px

	.link__item
		margin-top 16px
		display flex
		flex-direction row
		align-items center

		.link__item-icon
			width 40px
			flex-shrink 0
			+tablet()
				width 45px

		.link__item-text
			font-family $proxima
			font-size 14px
			line-height 17px
			color $color

	.underline-hover
		&:hover
			text-decoration underline $primary
			color $primary


		.link__item-title
			font-size 12px
			line-height 15px
			color $gray

		.link__item-value
			line-height 14px

@import projectStylesheet($project)