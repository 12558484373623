.modal-window.help-modal {
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  padding: 30px;
}
.modal-window.help-modal .modal-heading {
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 30px 0;
}
@media (max-width: 500px) {
  .modal-window.help-modal .modal-heading {
    margin: 0 20px 20px 0;
  }
}
.help-modal .flag {
  margin: 0 2px;
}
.help-modal .help-modal__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}
.help-modal .help-modal__body .item-wrapper {
  height: 233px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 34px;
}
.help-modal .help-modal__body .item-wrapper .text {
  color: #79b928;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
}
.help-modal .help-modal__body .item-wrapper:hover {
  -webkit-box-shadow: 0 4px 10px rgba(0,0,0,0.3);
  box-shadow: 0 4px 10px rgba(0,0,0,0.3);
}
.help-modal .help-modal__body .item-wrapper:hover .text {
  text-decoration: underline;
}
@media (max-width: 500px) {
  .help-modal .help-modal__body {
    gap: 12px;
  }
  .help-modal .help-modal__body .item-wrapper {
    height: 176px;
    padding: 23px 5px;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .help-modal .help-modal__body .item-wrapper img {
    height: 57px;
    margin-bottom: 19px;
  }
  .help-modal .help-modal__body .item-wrapper .text {
    font-size: 14px;
  }
}
/*# sourceMappingURL=src/js/components/Modals/HelpModal/HelpModal.css.map */