@import "~vars"

.personal-proposals-block-intro
	position relative
	h1
		padding 0
	.personal-proposals-block__intro-content
		display flex
		justify-content space-between
		margin-bottom $gutter
		color $gray
		+tablet()
			flex-direction column
		+phone()
			font-size 13px

		.personal-proposals-block__intro-previous-link
			font-size 14px
			white-space nowrap
			+tablet()
				margin-top 20px
			+phone()
				font-size 13px
			.icon-arrow-right
				size(11px, 10px)
				margin-left 8px
				svg
					size(100%, 100%)
	.personal-proposals-block__intro-text
		margin 0 50px 25px 0

.personal-proposal-block
	background #f9f9f9
	padding 40px 50px 50px
	+tablet()
		padding 40px 30px 50px
		margin 0 -15px
	+phone()
		margin 0
		padding 40px 15px 0

.electronic-proposals-list, .paper-proposals-list
	position relative
	+tablet()
			padding 0
	.personal-proposals__electronic .ui-tooltip
			max-width 385px
	.personal-proposals__paper .ui-tooltip
			max-width 385px
	.special-proposals__list-heading
		margin 64px 0 10px
		font-family $proxima
		font-weight 600
		font-size 20px
		&:first-child
			margin-top 0
		+phone()
			font-size 18px

.withoutBefore:before
	display none
	+phone()
		display initial
