.modal-overlay .modal-window.card-added-modal {
  max-width: 590px;
  text-align: center;
  padding: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 500px) {
  .modal-overlay .modal-window.card-added-modal {
    padding: 0;
  }
}
@media (max-width: 500px) {
  .modal-overlay .modal-window.card-added-modal .card-added_description {
    font-size: 15px;
  }
}
.modal-overlay .modal-window.card-added-modal .card-added_heading {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.modal-overlay .modal-window.card-added-modal .card-added_heading .card_added_heading-text {
  text-align: center;
}
.modal-overlay .modal-window.card-added-modal .continue_button {
  margin-top: 20px;
}
@media (max-width: 500px) {
  .modal-overlay .modal-window.card-added-modal .continue_button {
    margin-top: 10px;
  }
}
.modal-overlay .modal-window.card-added-modal .icon-check {
  margin-right: 10px;
}
@media (max-width: 500px) {
  .modal-overlay .modal-window.card-added-modal .icon-check {
    margin-right: 0;
  }
}
.modal-overlay .modal-window.card-added-modal .icon-check svg {
  width: 25px;
  height: 25px;
  color: #008000;
}
/*# sourceMappingURL=src/js/components/Modals/CardAddedSuccessModal/CardAddedSuccessModal.css.map */