@import "~vars"

.success-repeat-modal__data
	display flex
	flex-direction column
	align-items center
	padding 20px
	+phone()
		padding 0
		height 90vh
		justify-content center
	.success-repeat-modal__button.reversed
		border 1px solid $primary
		margin-right 8px
	.success-repeat-modal__button
		text-transform none
		&:hover
			background #60A51B !important
			color $white !important
	.success-repeat-modal__title
		font-size 22px
		font-weight 600
		margin 35px 0 30px
		text-align center
		+phone()
			font-size 18px

.success-repeat-modal
	+phone()
		padding 0 !important