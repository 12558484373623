.my-cards {
  background: #fff;
  padding: 25px 50px;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .my-cards {
    padding: 30px 20px;
  }
}
.my-cards__heading {
  margin: 34px 0 23px;
}
@media (max-width: 768px) {
  .my-cards__heading {
    margin-top: 0;
  }
}
.cards__table {
  width: 100%;
  max-width: 840px;
  font-size: 15px;
  line-height: 20px;
  color: #a2a2a2;
}
.cards__cell {
  padding: 11px 20px;
}
@media (max-width: 768px) {
  .cards__cell {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    color: #a2a2a2;
  }
}
.cards__cell:first-child {
  padding-left: 0;
}
.cards__cell:last-child {
  padding-right: 0;
}
.cards__card-label {
  width: 50%;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
}
@media (max-width: 500px) {
  .cards__card-label {
    font-size: 12px;
  }
}
.cards__head-th {
  font-size: 12px;
  line-height: 1.25;
  font-weight: normal;
  border-bottom: 1px solid #e5e5e5;
  text-align: left;
}
@media (max-width: 768px) {
  .cards__row {
    border-bottom: 1px solid #e5e5e5;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
.cards__row.active {
  color: #333;
}
.cards__row:first-child .cards__cell {
  padding-top: 17px;
}
@media (max-width: 768px) {
  .cards__row:first-child .cards__cell {
    padding-top: 6px;
  }
}
.cards__row:last-child .cards__cell {
  padding-bottom: 20px;
}
.cards__row-controls {
  text-align: right;
}
.cards__card-date,
.cards__card-type,
.cards__card-status {
  padding-top: 1px;
}
.cards__card-date {
  color: #a2a2a2;
}
.cards__card-number {
  font-size: 18px;
}
@media (max-width: 500px) {
  .cards__card-number {
    font-size: 15px;
  }
}
.cards__card-controls {
  padding-top: 2px;
}
.cards__foot-td {
  border-top: 1px solid #e5e5e5;
}
@media (max-width: 768px) {
  .cards__foot-td {
    border: none;
    padding: 0;
  }
}
.cards__foot-td.-right {
  text-align: right;
}
@media (max-width: 768px) {
  .cards__foot-td.-right button {
    margin: 15px auto 0;
  }
}
.cards__button {
  padding: 0;
  border: 0;
  color: #a2a2a2;
  font-weight: normal;
}
.cards__button:hover,
.cards__button:focus {
  color: #333;
}
.cards__button .icon-lock-opened,
.cards__button .icon-lock-closed {
  margin-left: 11px;
  margin-top: -2px;
}
.cards__button .icon-circle-plus {
  margin-right: 7px;
  margin-top: -2px;
}
.cards__button-add {
  margin: 12px 0;
}
.my-cards__info {
  background: #fff;
  padding: 25px 50px;
  margin-top: 15px;
  color: #a2a2a2;
  letter-spacing: 0;
}
@media (max-width: 768px) {
  .my-cards__info {
    padding: 30px 20px;
  }
}
.my-cards__info-heading {
  color: #333;
  text-transform: none;
  letter-spacing: inherit;
  font-family: "Lato";
  font-weight: normal;
  margin: 5px 0 18px;
}
.my-cards__info-text {
  margin: 20px 0 10px;
  max-width: 840px;
}
/*# sourceMappingURL=src/js/pages/SettingsPage/MyCards/MyCards.css.map */