@import "~vars"

.unauthorized-wrapper
	display flex
	height 100%
	flex-direction column
	.unauthorized-header
		height 110px
		padding 27px 0
		box-shadow 0 4px 4px rgba(0, 0, 0, 0.15)
		z-index 1
		+phone()
			height 75px
			padding 23px 0
		#logo
			padding-top 0
			float left
			+phone()
				padding 0 10px 0 15px
		#logo-svg
			width 212px
			+phone()
				width 100px
	.unauthorized-message
		max-width 1340px
		margin 0 auto
		width 100%
		+tablet()
			max-width 590px

		.unauthorized-message__right-side
			.hot-line__title, .hot-line__number
				font-family Lato
				font-style normal
				font-weight normal
			.hot-line__title
				padding-top 10px
				color black
				font-size 16px
				+phone()
					padding 0
					font-size 14px
			.hot-line__number
				color $primary
				padding-top 12px
				font-size 18px
				+phone()
					padding 0
					font-size 14px


.unauthorized-content
	flex 1
	display flex
	align-items center
	justify-content center
	background embedurl("../../../img/pattern.svg", "utf8") $primary repeat center

	+phone()
		align-items flex-start
		background white
	.unauthorized-holder
		display flex
		align-items center
		justify-content center
		max-width 650px
		min-width 300px
		width 100%
		background white
		+tablet()
			max-width 590px
		+phone()
			padding 10px
			.auth
				padding 20px 15px