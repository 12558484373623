.change-password__form {
  margin: 22px auto -25px;
  max-width: none;
}
.change-password__form .form__body {
  max-width: 711px;
}
.change-password__form hr {
  margin: 25px 0;
}
.change-password__form .form__text {
  margin: 0 0 5px;
  line-height: 1.5;
}
.change-password__form .old-password__hint {
  margin: 4px 0 -15px;
  text-align: right;
}
.change-password__form .old-password__hint-link {
  text-decoration: underline;
  color: inherit;
}
.change-password__form .tooltip__change-password {
  width: 272px;
}
.change-password__form .tooltip__change-password ul {
  list-style: disc;
  padding-left: 16px;
}
.change-password__form .form__footer {
  border: none;
  margin-top: 0;
  padding-top: 10px;
}
@media (max-width: 768px) {
  .change-password__form .form__footer {
    margin-top: 0;
    border: none;
  }
}
.change-password__form .form__footer .form__footer-btn {
  margin-left: 0;
}
/*# sourceMappingURL=src/js/forms/ChangePasswordForm/ChangePasswordForm.css.map */