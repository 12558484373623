.link-all {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.230769230769231;
  color: #79b928;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
}
.link-all__icon {
  margin-left: 8px;
  vertical-align: top;
  margin-top: 1px;
}
/*# sourceMappingURL=src/js/components/UI/LinkAll/LinkAll.css.map */