@import "~vars"

.ui-tooltip
	&.__react_component_tooltip, &.react-tooltip
		font-size 14px
		lh(20)
		padding 14px 24px 15px
		font-family $font
		border-right 5px
		letter-spacing 0
		pointer-events all
		left 655px
		top 362px
		z-index 10
		+tablet()
			display none
			&:after
				display none

		&.show
			opacity 1

		&.place-right
			margin-left 20px

		&.type-dark
			color white
			background $color

			&.place-right:after
				border-style solid
				border-width 7px
				border-left-width 0
				border-color transparent
				border-right-color @background
				left auto
				right 100%
				margin-top -7px

		&.place-bottom
			margin-top 20px

			&.place-bottom:after
				border-bottom-color $color
				border-width 7px
				top auto
				bottom 100%

		&.place-left
			margin-right 20px

			&.place-left:after
				border-left-color $color
				border-width 7px
				right auto
				left 100%

		&.place-top
			margin-bottom 20px

			&.place-top:after
				border-top-color $color
				border-width 7px
				bottom auto
				top 100%
