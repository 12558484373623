.modal-overlay .modal-window.cookie-banner-modal {
  max-width: 900px;
  padding: 80px;
}
@media (max-width: 768px) {
  .modal-overlay .modal-window.cookie-banner-modal {
    max-width: 600px;
    padding: 50px 30px;
  }
}
@media (max-width: 500px) {
  .modal-overlay .modal-window.cookie-banner-modal {
    padding: 50px 15px;
  }
}
.modal-overlay .modal-window.cookie-banner-modal .icon-cross {
  width: 14px;
  height: 14px;
}
@media (max-width: 768px) {
  .modal-overlay .modal-window.cookie-banner-modal .cookie-banner-closeButton {
    top: 15px;
    right: 15px;
  }
}
.cookie-banner-popup {
  margin: auto;
  letter-spacing: 0;
  font-size: 14px;
  color: #a2a2a2;
}
@media (max-width: 500px) {
  .cookie-banner-popup {
    font-size: 12px;
  }
}
.cookie-banner-popup a {
  color: #000;
  text-decoration: underline;
}
.cookie-banner-popup a:hover {
  color: #79b928;
}
/*# sourceMappingURL=src/js/components/Modals/CookieAgreementModal/CookieAgreementModal.css.map */