.nearest-store {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 11px 0 13px 10px;
}
.search-block + .nearest-store {
  border-top: 1px solid #ccc;
  padding-top: 21px;
  margin-bottom: 10px;
}
.nearest-store__body {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (max-width: 500px) {
  .nearest-store__body {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.nearest-store__message {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 683px;
  -ms-flex: 1 1 683px;
  flex: 1 1 683px;
  padding-right: 25px;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .nearest-store__message {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 50px;
    -ms-flex: 1 1 50px;
    flex: 1 1 50px;
  }
}
.nearest-store__message-cell {
  display: table-cell;
  height: 40px;
  vertical-align: middle;
}
.nearest-store__login-block {
  margin: 11px 0 0;
}
.nearest-store__login-link {
  position: relative;
}
.nearest-store__login-link:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  height: 2px;
  background: currentColor;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.is-ie .nearest-store__login-link {
  text-decoration: underline;
}
.is-ie .nearest-store__login-link:after {
  display: none;
}
.nearest-store__btn-holder {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.nearest-store__btn {
  padding: 11px 22px 10px;
  font-size: 12px;
  line-height: 1.333333333333333;
}
.nearest-store__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: auto;
}
.nearest-store__content .search-block {
  margin: 6px 0 0 25px;
}
.nearest-store__selected {
  margin: 8px 0 0;
}
@media (max-width: 768px) {
  .nearest-store__selected {
    width: 100%;
  }
}
.nearest-stores-drop-down {
  margin: 0 0 0 5px;
  vertical-align: top;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .nearest-stores-drop-down {
    width: 100%;
    margin: 0;
  }
  .nearest-stores-drop-down .drop-down-menu {
    max-width: 100%;
  }
}
.nearest-stores-drop-down .drop-down-arrow {
  display: none !important;
}
.nearest-stores-drop-down .drop-down-title {
  padding-bottom: 5px;
}
.nearest-stores-drop-down__store {
  cursor: pointer;
  padding: 6px 25px;
}
.nearest-stores-drop-down__store.active {
  cursor: default;
}
.nearest-stores-drop-down__edit {
  text-transform: uppercase;
  color: #a2a2a2;
  font-size: 12px;
  line-height: 1.25;
  padding: 10px 25px 9px;
  cursor: pointer;
  -webkit-transition: color 0.35s;
  -moz-transition: color 0.35s;
  -o-transition: color 0.35s;
  -ms-transition: color 0.35s;
  transition: color 0.35s;
}
.nearest-stores-drop-down__edit:hover {
  color: #333;
}
.nearest-stores-drop-down__edit .icon-pencil {
  margin: -2px 7px 0 0;
}
/*# sourceMappingURL=src/js/components/NearestStore/NearestStore.css.map */