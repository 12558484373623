@import "~vars"

.radio-btn-like-list
	display flex
	flex-flow wrap

	.radio-btn-like-item
		margin 0 10px 20px 0
		cursor pointer
		display block
		padding 7px 10px
		text-align center
		border 1px solid $primary
		color black
		transition border $tdur ease 0s
		border-radius 23px
		font-size 14px
		min-width 140px


	.radio-btn-like-active
		background $primary
		color white

	.radio-btn-like-disabled
		border-color $gray-line
		color $gray-line
		background-color white
		cursor not-allowed

	.radio-btn-like-disabled.radio-btn-like-active
		background-color rgba($primary, .3)