.sharer-wrapper {
  position: absolute;
  top: 45px;
  right: 20px;
  background: #fff;
  padding: 32px;
}
@media (max-width: 768px) {
  .sharer-wrapper {
    right: 0;
  }
}
@media (max-width: 500px) {
  .sharer-wrapper {
    left: 0;
    top: 20px;
    padding: 0 20px;
  }
}
.sharer-wrapper span {
  font-size: 23px;
  line-height: 28px;
  font-weight: 500;
  display: block;
}
.sharer-wrapper .sharer-close-button {
  position: absolute;
  right: 13px;
  top: 25px;
}
@media (max-width: 500px) {
  .sharer-wrapper .sharer-close-button {
    display: none;
  }
}
.sharer-wrapper .buttons-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 24px;
}
.sharer-wrapper .social-button:hover .icon path:first-child {
  fill-opacity: 0.25;
}
.sharer-wrapper .social-button:focus .icon path:first-child {
  fill-opacity: 0.55;
}
.sharer-wrapper .social-button p {
  margin: 8px 0 0;
  font-size: 12px;
  font-weight: 400;
}
.sharer-wrapper .copy-link {
  padding: 16px;
  background: #f6f7f5;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  cursor: pointer;
}
.sharer-wrapper .copy-link span {
  font-size: 14px;
  font-weight: 400;
  color: #a2a2a1;
}
@media (max-width: 500px) {
  .sharer-wrapper .copy-link span {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}
.sharer-wrapper .copy-link .icon {
  margin-left: 16px;
}
@media (max-width: 500px) {
  .sharer-wrapper .copy-link .icon {
    margin-left: auto;
  }
}
.sharer-wrapper .message {
  position: absolute;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  padding: 9px;
  text-align: center;
  line-height: 14px;
  background: #79b928;
  color: #fff;
}
@media (max-width: 500px) {
  .sharer-wrapper .message {
    background: #fff;
    color: #000;
    border-radius: 6px;
    bottom: 8px;
    width: fit-content;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 8px 16px;
    -webkit-box-shadow: 0px 4px 8px rgba(0,0,0,0.08);
    box-shadow: 0px 4px 8px rgba(0,0,0,0.08);
  }
}
/*# sourceMappingURL=src/js/components/HistoryList/HistoryCheck/Sharer/Sharer.css.map */