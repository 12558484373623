@import "~vars"

.icon-text-list
	display flex
	flex-wrap wrap
	margin 25px 0
	margin-left -30px
	padding 0
	list-style none
	+tablet()
		margin-left 0

.icon-text-list__li
	margin $gutter 0
	padding-left 30px
	flex 0 1 25%
	+tablet()
		padding-left 0

.icon-text-list__item
	display flex

.icon-text-list__item-icon
	margin 3px 14px 0 0
	flex 0 0 34px