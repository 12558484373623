@import "~vars"

.password-form
	max-width 590px
	margin-bottom 0

.password-form__tooltip
	width 200px

.password-form__links-wrapper
	display flex
	flex-direction row
	justify-content space-between

	.password-form__forgot-password,
	.password-form__go_to_otp
		font-size 11px
		margin-left auto
		margin-top 5px
		lh(14)
		color $gray
		text-decoration underline
		cursor pointer
		&:hover
			color $color
		.loader
			margin-top 5px
	.password-form__go_to_otp
		color $primary
		margin-right auto
		margin-left 0
		+phone()
			display none
		&.mobile
			display none
			+phone()
				display block
				margin 24px auto 0

.password-form-btn
	min-width 194px
	margin-top 30px