@import "nib"
@import "~vars"

.modal-overlay
	.modal-window.error__dialog
		max-width 590px
		.error-modal__wrapper
			display flex
			justify-content center

			.error-modal__content
				display block
				text-align center
				max-width 500px
				margin $gutter auto
				font-size 14px
				line-height 1.428571428571429
				padding-top 64px
				padding-bottom 64px
				+phone()
					padding 0
					margin 0
					max-width none
					width 100%
					.button-ok
						display block
						margin 0 auto

				.button-ok
					width 200px
					margin-top $gutter
