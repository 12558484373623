@import "~vars"

.layout
	width 100%
	height 100%
	background #f2f2f2
	font-family $font

.container
	padding-left 326px
	width auto
	min-height 100%
	background $container
	display flex
	flex-direction column
	position relative
	+desktop()
		padding-left $sidebarWidth
	+phone()
		overflow hidden
	@media only screen and (max-width $menuToggle)
		padding-left 0

.content
	flex-grow 1
	position: relative
	width: 100%
	height: 100%
	padding 76px 15px 15px 15px
	@media only screen and (max-width $menuToggle)
		padding 72px 15px 15px

.row
	width 100%
	display flex

.col-3
	width 25%
	display inline-block
	+phone()
		width: 100%

.footer-links
	list-style none

.back-to-top
	z-index 90
	display flex
	justify-content center
	align-items center
	size(50px, 50px)
	cursor pointer
	background $primary
	position fixed
	bottom 60px
	right 60px
	border-radius 50%
	transform translate(110px, 0);
	transition transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72)
	+tablet()
		bottom 20px
		width 42px
		height 42px

	&.show
		transform translate(0, 0);
		+tablet()
			right 20px

	i.icon
		transform rotate(-90deg)
		color white
		font-size 28px
		width 40px
		height 24px
		+tablet()
			width 22px
			height 16px

.-mobile
	display none
	+tablet()
		display block

.-desktop
	+tablet()
		display none