@import "~vars"

.purchase-details
	// padding 0 14px 14px
	font-family $exo
	.purchase-details-general
		display flex
		column-gap 8px
		padding 0 14px
		+tablet()
			padding 0 8px
			flex-direction column
			row-gap 8px
	.repeat-btn-mobile
		display none
		height 32px
		text-transform capitalize
		padding 4px 17px
		width calc(100% - 16px)
		margin 16px 8px
		align-items center
		justify-content center
		&:hover, &:focus
			background #60A51B
		.icon-basket-bold
			margin-right 10px
		+phone()
			display inline-flex
	.cancel-btn-mobile
		display none
		height 32px
		width calc(100% - 16px)
		text-transform none
		padding 9px 12px
		color #EF4237
		font-size 14px
		line-height 14px
		font-weight 500
		border-radius 37px
		margin 0 8px
		cursor pointer
		&:hover, &:focus
			background rgba(0, 0, 0, 0.04)
		+phone()
			display block
	.purchase-details-order-title
		display flex
		justify-content space-between
		margin 14px 14px 4px
		align-items center
		position relative
		font-size 16px
		height 32px
		+tablet()
			margin 14px 8px 4px
		+phone()
			margin 0px 8px 4px
		.repeat-btn
			height 32px
			text-transform capitalize
			padding 4px 17px
			width auto
			display inline-flex
			align-items center
			&:hover, &:focus
				background #60A51B
			&:disabled
				background gray
				color $white
			.icon-basket-bold
				margin-right 10px
			.lds-ring
				width 31px
				height 25px
				& > div
					size(25px)
			+phone()
				display none
		.cancel-btn
			margin-right 32px
			background none
			border-radius 37px
			padding 9px 12px
			height 32px
			color #EF4237
			text-transform none
			font-size 14px
			line-height 14px
			font-weight 500
			cursor pointer
			&:hover, &:focus
				background rgba(0, 0, 0, 0.04)
			+phone()
				display none
		.more-btn
			padding 0
			margin-left 4px
			border-radius 15px
			cursor pointer
			&:hover, &:focus
				background #f7f7f7
		.more-actions
			position absolute
			background white
			border-radius 10px
			padding 12px
			box-shadow 0px 4px 24px 0px rgba(0, 0, 0, 0.08)
			right 0
			top 42px
			display flex
			flex-direction column
			z-index 1
			& > button
				padding 0
				height 24px
				margin-bottom 12px
				font-family $exo
				font-weight 400
				font-size 14px
				text-transform inherit
				display flex
				align-items center
				&:last-child
					margin-bottom 0
				.icon
					margin-right 12px
					size(24px)
					padding 4.5px

