@media (max-width: 500px) {
  .my-info-page .form-row {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
  }
  .my-info-page .form__field {
    width: 100%;
    margin-left: 0;
  }
}
.my-info__personal-information {
  margin: 15px 0;
}
/*# sourceMappingURL=src/js/pages/SettingsPage/MyInfo/MyInfo.css.map */