@import "~vars"

.birthday-banner
	margin-top $gutter
	background $violet
	color white
	height 480px
	position relative
	font-size 16px
	lh(24)
	display block
	overflow hidden
	+tablet()
		height auto
		display: flex;
		flex-direction: column-reverse;


	a&
		cursor pointer
		color white
		transition-duration 2s
		transition-property background-color

		&:hover
			.promo__image
				transform scale(1.1)
				transition-duration 2s
		+tablet()
			height auto
			padding 60px
		+phone()
			padding 36px 15px
			margin 0 -15px

	.product-list &
		margin-top 0
		+tablet()
			margin 0

	&.theme-violet
		background $violet

		a&:hover
			background $violetHover
			+tablet()
				background $violet

	&.theme-cyan
		background $cyan

		a&:hover
			background $cyanHover

	&.theme-orange
		background $orange

		a&:hover
			background $orangeHover

	&.theme-olive
		background $olive

		a&:hover
			background $oliveHover

	&.theme-green
		background $green

		a&:hover
			background $greenHover


	.banner__image
		absolute right 25% top 50%
		text-align center
		transform scale(1)
		transition all $tdur
		+wide()
			right 22%
		+tablet()
			display block
			position unset
		img
			margin -500px
			max-width none
			+desktop()
				max-width 500px
			+laptop()
				max-width 450px
			+tablet()
				margin 0
				width 100%

	.promo__info
		display flex
		height 100%
		max-width 55%
		padding 0 65px
		position relative
		min-height 280px
		+tablet()
			padding 0
			max-width 100%
			font-size 13px
			min-height auto

	.promo__info-content
		margin auto 0
		+tablet()
			margin 0 auto

	.promo__title
		font-size 72px
		lh(65)
		novito()
		margin 0 0 23px
		+tablet()
			font-size 56px
			text-align center
		+phone()
			font-size 40px

	.promo__description
		margin 0

	.promo__btn-more
		font-size 13px
		line-height (16 / 13)
		margin 39px 0 0
		font-weight $semibold
		letter-spacing .1em
		padding 20px 33px
		+tablet()
			padding 15px 26px 13px
			margin-top 30px
			font-size 12px

	.normal
		.promo__info
			display flex
			height 100%
			max-width 100%
			padding 0 25px
			position relative
			min-height 280px
			+tablet()
				padding 0
				max-width 100%
				font-size 13px
