@import "~vars"

.success-payment-modal
	& > div
		display flex
		flex-direction column
		align-items center
	.page-heading
		font-size 22px
		text-align center
		font-weight 700
		margin 30px 0 15px
	.page-text-charity
		color #787878
		font-size 16px !important
		font-weight 500
		line-height 1.3
		max-width 398px
		margin 0 auto 25px auto
		text-align center
		.order-id
			color $primary
	.payment-warning
		padding 12px 16px 12px 18px
		background rgba(122, 185, 41, 0.1)
		margin 0 auto 25px auto
		border-radius 5px
		color $primary
		font-size 12px
		line-height 15.95px
		font-weight 500
		display flex
		max-width 400px
		& > .icon
			margin-right 8px
			width 24px
			height 24px
		svg
			width 22px
			height 20px
			margin-top 2px
	.choose_market_btn
		&:focus
			background $primary
		&:hover
			background $primaryHover
