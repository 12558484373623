.my-contacts-row-form {
  max-width: none;
  margin: 0;
  position: relative;
}
.my-contacts-row-form .form__label.form__label--focusable:not(.focused):not(.not-empty) .form__label-value {
  -webkit-transform: translate(0, 30px);
  -moz-transform: translate(0, 30px);
  -o-transform: translate(0, 30px);
  -ms-transform: translate(0, 30px);
  transform: translate(0, 30px);
}
.my-contacts-row-form .inline-row__buttons {
  padding: 0;
  position: absolute;
  border-bottom: none;
  right: 0;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.my-contacts-row-form .form-row.inline-row {
  margin-left: 0;
}
.my-contacts-row-form .form-row.inline-row input {
  padding: 0;
  height: 40px;
}
/*# sourceMappingURL=src/js/forms/MyContactsRowForm/MyContactsRowForm.css.map */