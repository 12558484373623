.personal-proposals-page .personal-proposals-intro {
  position: relative;
}
.personal-proposals-page .personal-proposals-intro h1 {
  padding: 0;
}
.personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #a2a2a2;
}
@media (max-width: 768px) {
  .personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content {
    font-size: 13px;
  }
}
.personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content .personal-proposals__intro-previous-link {
  font-size: 14px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content .personal-proposals__intro-previous-link {
    margin-top: 20px;
  }
}
@media (max-width: 500px) {
  .personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content .personal-proposals__intro-previous-link {
    font-size: 13px;
  }
}
.personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content .personal-proposals__intro-previous-link .icon-arrow-right {
  width: 11px;
  height: 10px;
  margin-left: 8px;
}
.personal-proposals-page .personal-proposals-intro .personal-proposals__intro-content .personal-proposals__intro-previous-link .icon-arrow-right svg {
  width: 100%;
  height: 100%;
}
.personal-proposals-page .personal-proposals-intro .personal-proposals__intro-text {
  margin: 0 50px 25px 0;
}
/*# sourceMappingURL=src/js/pages/PersonalProposalsPage/PersonalProposalsPage.css.map */