@import "~vars"

.tab-item
	outline none
	text-decoration none
	background none
	user-select none
	cursor pointer
	border none
	font-size 16px
	display inline-flex
	align-items center
	height 21px
	margin-right 24px
	padding 0
	&:last-child
		margin-right 0
	&.active
		color $primary
		.tab-item_counter
			border 1px solid $primary
	.tab-item_counter
		border 1px solid #D3D3D3
		padding 4px 6px
		border-radius 4px
		font-size 12px
		line-height 9px
		margin-left 8px
		height 17px
		display inline-block

.filters-chips
	margin-top 24px
	border 1px solid #D3D3D3
	border-radius 4px
	width fit-content
	padding 4px 12px
	font-size 16px
	font-weight 500
	font-family $exo
	display flex
	align-items center
	cursor pointer
	&:hover
		background #F6F7F5
	.icon
		margin-left 8px
		svg
			size(12px)
			path
				fill black