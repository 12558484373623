@import "nib"
@import "~vars"

.ordered-list
	counter-reset list_item
	margin auto
	padding 0

	> li
		display flex
		margin 10px 0
		+laptop()
			margin-bottom 30px

		&:before
			content counter(list_item)
			counter-increment list_item
			font-size 70px
			lh(70)
			color $primary
			novito()
			margin -20px 15px 0 0
			+tablet()
				font-size 50px
				min-width 18px
				margin-top -12px