#logo {
  display: block;
  padding: 30px;
}
#logo-svg {
  width: 250px;
}
.logo-st0 {
  fill: #79b928;
}
.logo-st1 {
  fill: #fff;
}
.logo-st2 {
  fill: #e72f2a;
}
.logo-st3 {
  fill: none;
}
/*# sourceMappingURL=src/js/components/UI/Logo/Logo.css.map */