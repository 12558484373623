@import "~vars"

.drop-down-menu
	background white
	border 1px solid #e0e0e0
	box-shadow $boxShadow
	absolute left top 100%
	margin-top 13px
	min-width 235px
	max-height 252px
	overflow-y auto
	color black
	padding 10px 0
	+tablet()
		min-width 260px

	.pull-right &
		right 0
		left auto

ul.drop-down-menu > li
.drop-down-menu-item
	padding 6px 25px
	transition color $tdur
	overflow ellipsis
	text-transform capitalize

	&.active
		cursor default

	&.active
	&:hover
		color $primary
