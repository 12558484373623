.panel {
  background: #fff;
  padding: 25px 50px;
}
@media (max-width: 768px) {
  .panel {
    padding: 30px 20px;
  }
}
@media (max-width: 500px) {
  .panel {
    margin-bottom: 20px;
  }
}
/*# sourceMappingURL=src/js/components/UI/Panel/Panel.css.map */