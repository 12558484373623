.modal-overlay {
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  pointer-events: all;
  background: rgba(242,242,242,0.9);
}
@media (max-width: 500px) {
  .modal-overlay {
    background: #fff;
  }
}
.modal-overlay .modal-window {
  -webkit-tap-highlight-color: transparent;
  pointer-events: all;
  background: #fff;
  -webkit-box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 930px;
  min-height: 300px;
  cursor: auto;
  padding: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .modal-overlay .modal-window {
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .modal-overlay .modal-window {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding: 40px 20px 0;
  }
}
.modal-overlay .modal-window .modal-closeButton {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  margin: 20px;
}
@media (max-width: 768px) {
  .modal-overlay .modal-window .modal-closeButton {
    margin: 5px;
  }
}
@media (max-width: 500px) {
  .modal-overlay .modal-window .modal-closeButton {
    margin: 0;
    padding: 0;
  }
}
.modal-overlay .modal-window .modal-closeButton .modal-closeIcon {
  fill: #a4a4a4;
  -webkit-transition: fill 0.3s ease-in-out;
  -moz-transition: fill 0.3s ease-in-out;
  -o-transition: fill 0.3s ease-in-out;
  -ms-transition: fill 0.3s ease-in-out;
  transition: fill 0.3s ease-in-out;
}
.modal-overlay .modal-window .modal-closeButton .modal-closeIcon:hover {
  fill: #494949;
}
@font-face {
  font-family: icomoon;
  font-style: normal;
  src: url("../fonts/icomoon.woff");
}
@font-face {
  font-family: Exo;
  font-style: normal;
  src: url("../fonts/Exo-Regular.ttf") format('truetype');
}
@font-face {
  font-family: ExoBold;
  font-style: normal;
  src: url("../fonts/Exo-Bold.ttf") format('truetype');
}
@font-face {
  font-family: ExoSemiBold;
  font-style: normal;
  src: url("../fonts/Exo-Soft-Semi-Bold.otf");
}
@font-face {
  font-family: ExoExtraLight;
  font-style: normal;
  src: url("../fonts/Exo-Soft-Extra-Light.otf");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  src: url("../fonts/Lato-Regular.woff");
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  font-family: "Lato";
}
@media (max-width: 768px) {
  body {
    font-size: 13px;
  }
}
a,
.a-like {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
  cursor: pointer;
}
a:hover,
.a-like:hover {
  text-decoration: none;
  color: #79b928;
}
a:hover,
.a-like:hover,
a:active,
.a-like:active {
  outline: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
a:focus,
button:focus,
a:hover,
button:hover,
a:active,
button:active {
  outline: none;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
hr {
  height: 2px;
  border: 0;
  color: #79b928;
  margin: 15px 0;
}
hr::before {
  content: '';
  height: 100%;
  width: 28px;
  display: inline-block;
  vertical-align: top;
  background: #79b928;
}
h1 {
  font-size: 48px;
  font-family: "Lato";
}
@media (max-width: 768px) {
  h1 {
    font-size: 32px;
  }
}
h2 {
  font-size: 32px;
  font-family: "Lato";
  line-height: 0.875;
}
@media (max-width: 768px) {
  h2 {
    font-size: 23px;
  }
}
h3,
.heading3 {
  font-size: 23px;
  line-height: 0.875;
  font-family: "Lato";
  font-weight: normal;
}
@media (max-width: 768px) {
  h3,
  .heading3 {
    font-size: 22px;
  }
}
h4,
.heading4 {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.02em;
  font-family: "Lato";
  font-weight: 500;
  text-transform: uppercase;
}
p {
  font-size: 16px;
}
@media (max-width: 500px) {
  p {
    font-size: 12px;
  }
}
.-mobile {
  display: none;
}
@media (max-width: 768px) {
  .-mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .-desktop {
    display: none;
  }
}
ul {
  list-style: none;
  padding: 0;
}
/*# sourceMappingURL=src/js/index.css.map */