.icon-text-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 25px 0;
  margin-left: -30px;
  padding: 0;
  list-style: none;
}
@media (max-width: 768px) {
  .icon-text-list {
    margin-left: 0;
  }
}
.icon-text-list__li {
  margin: 15px 0;
  padding-left: 30px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 25%;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
}
@media (max-width: 768px) {
  .icon-text-list__li {
    padding-left: 0;
  }
}
.icon-text-list__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.icon-text-list__item-icon {
  margin: 3px 14px 0 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 34px;
  -ms-flex: 0 0 34px;
  flex: 0 0 34px;
}
/*# sourceMappingURL=src/js/components/IconTextList/IconTextList.css.map */