.moneybox_container {
  margin: 20px 0;
  padding: 25px;
  background-color: #fff;
  border-radius: 5px;
}
.moneybox_container .preview-panel {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media only screen and (max-width: 625px) {
  .moneybox_container .preview-panel {
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}
.moneybox_container .preview-panel .icon-moneybox,
.moneybox_container .preview-panel .icon-bonuses {
  margin-right: 15px;
}
.moneybox_container .preview-panel .icon-moneybox > svg,
.moneybox_container .preview-panel .icon-bonuses > svg {
  width: 28px;
  height: 28px;
}
.moneybox_container .preview-panel .title-line {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 625px) {
  .moneybox_container .preview-panel .title-line {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.moneybox_container .preview-panel .title-line .moneybox-title {
  margin: 0 25px 0 0;
  font-size: 22px;
  line-height: 25px;
}
@media only screen and (max-width: 625px) {
  .moneybox_container .preview-panel .title-line .moneybox-title {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .moneybox_container .preview-panel .title-line .moneybox-title {
    font-size: 18px;
  }
}
.moneybox_container .preview-panel .title-line .switch-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.moneybox_container .preview-panel .icon-chevron-down {
  margin-top: 7px;
  margin-left: auto;
  cursor: pointer;
}
.moneybox_container .preview-panel .icon-chevron-down.open {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.moneybox_container .preview-panel .moneybox-tooltip {
  margin-right: 25px;
  margin-left: 0;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
@media (max-width: 768px) {
  .moneybox_container .preview-panel .moneybox-tooltip {
    margin-right: 15px;
  }
}
.moneybox_container .preview-panel .moneybox-tooltip .info-icon.moneybox-tooltip-icon {
  width: 26px;
  height: 26px;
  padding-top: 4px;
  background: #333;
  position: relative;
}
.moneybox_container .preview-panel .moneybox-tooltip .info-icon.moneybox-tooltip-icon:hover {
  background: #79b928;
}
.moneybox_container .preview-panel .moneybox-tooltip .info-icon.moneybox-tooltip-icon:hover .tooltip-massage {
  display: block;
}
.moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage {
  display: none;
  width: max-content;
  max-width: 380px;
  padding: 25px;
  border-radius: 5px;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  z-index: 1;
  -webkit-transform: translate(20px, -50%);
  -moz-transform: translate(20px, -50%);
  -o-transform: translate(20px, -50%);
  -ms-transform: translate(20px, -50%);
  transform: translate(20px, -50%);
}
@media (max-width: 1300px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage {
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, 20px);
    -moz-transform: translate(-50%, 20px);
    -o-transform: translate(-50%, 20px);
    -ms-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }
}
@media (max-width: 768px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage {
    -webkit-transform: translate(-80%, 20px);
    -moz-transform: translate(-80%, 20px);
    -o-transform: translate(-80%, 20px);
    -ms-transform: translate(-80%, 20px);
    transform: translate(-80%, 20px);
  }
}
@media only screen and (max-width: 630px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage {
    max-width: 300px;
    -webkit-transform: translate(-50%, 20px);
    -moz-transform: translate(-50%, 20px);
    -o-transform: translate(-50%, 20px);
    -ms-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }
}
@media (max-width: 500px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage {
    max-width: 280px;
    padding: 15px;
    font-size: 12px;
  }
}
.moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  -webkit-transform: translate(-95%, -50%);
  -moz-transform: translate(-95%, -50%);
  -o-transform: translate(-95%, -50%);
  -ms-transform: translate(-95%, -50%);
  transform: translate(-95%, -50%);
  border: 8px solid transparent;
  border-right-color: #333;
}
@media (max-width: 1300px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage:before {
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -95%);
    -moz-transform: translate(-50%, -95%);
    -o-transform: translate(-50%, -95%);
    -ms-transform: translate(-50%, -95%);
    transform: translate(-50%, -95%);
    border-right-color: transparent;
    border-bottom-color: #333;
  }
}
@media (max-width: 768px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage:before {
    left: 80%;
  }
}
@media only screen and (max-width: 630px) {
  .moneybox_container .preview-panel .moneybox-tooltip .tooltip-massage:before {
    left: 50%;
  }
}
.moneybox_container .preview-panel .moneybox-toggle.react-toggle {
  margin-right: 25px;
}
@media (max-width: 768px) {
  .moneybox_container .preview-panel .moneybox-toggle.react-toggle {
    margin-right: 15px;
  }
}
.moneybox_container .preview-panel .moneybox-toggle.react-toggle.react-toggle--checked .react-toggle-thumb {
  left: 21px;
}
.moneybox_container .preview-panel .moneybox-toggle.react-toggle .react-toggle-track {
  width: 44px;
  height: 24px;
}
.moneybox_container .preview-panel .moneybox-toggle.react-toggle .react-toggle-thumb {
  width: 24px;
  height: 24px;
  top: 0;
}
/*# sourceMappingURL=src/js/components/Moneybox/Moneybox.css.map */