.menu-account {
  padding: 10px 0 15px;
  margin-left: 20px;
}
.menu-account .menu-account__user-data {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.menu-account .menu-account__user-data .account__user-icon {
  width: 35px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.menu-account .menu-account__user-data .account__user-name {
  font-size: 16px;
  line-height: 1;
  color: #333;
}
.menu-account .menu-account__user-bonuses {
  line-height: 1;
}
.menu-account .menu-account__user-bonuses .bonus-count {
  margin-bottom: 5px;
  font-size: 14px;
}
.menu-account .menu-account__user-bonuses .bonus-value .bonus-amount {
  font-size: 30px;
  font-family: "Lato";
}
.menu-account .menu-account__user-bonuses .bonus-value .bonus-currency {
  margin-left: 5px;
  font-size: 14px;
}
.menu-account .menu-account__user-points {
  margin-top: 15px;
  font-size: 14px;
  line-height: 1;
  color: #a8a7a7;
}
.menu-account .menu-account__how-save-points {
  position: relative;
  display: block;
  font-size: 15px;
}
.menu-account .menu-account__how-save-points .icon-arrow-right {
  color: #79b928;
  position: relative;
  -webkit-transform: translate(50%, -15%);
  -moz-transform: translate(50%, -15%);
  -o-transform: translate(50%, -15%);
  -ms-transform: translate(50%, -15%);
  transform: translate(50%, -15%);
}
.account-available__vouchers__amount {
  font-family: "Lato";
}
/*# sourceMappingURL=src/js/components/Layout/Menu/SidebarMenu/AccountBar/AccountBar.base.css.map */