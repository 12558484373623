@import "~vars";

.purchase-product-wrapper
	height 78px
	border-top 2px solid #F7F7F7
	display flex
	align-items center
	padding 16px 0
	cursor pointer
	margin 0 14px
	&.isDeliveryItem
		&:hover
			background none
			cursor default
	+tablet()
		margin 0 8px
		padding 10px 0
		height 58px
	&:hover
		padding 16px 14px
		margin 0
		background #F8F8F8
		& + li
			border-top 2px solid transparent
		+tablet()
			padding 10px 8px
	&:first-child
		border-top none
	.purchase-product-img
		size(46px)
		+tablet()
			size(32px)
	.purchase-product-info
		width 100%
		display flex
		flex-direction column
		justify-content space-between
		margin-left 12px
		height 100%
		font-family $exo
		.purchase-product-info-row
			display flex
			justify-content space-between
			align-items center
			.title
				font-size 16px
				line-height 16px
				max-height 18px
				overflow hidden
				text-overflow ellipsis
				-webkit-line-clamp 1
				display -webkit-box
				-webkit-box-orient vertical
				+tablet()
					font-size 12px
				span
					height 16px
					display inline-block
					padding 1px 4px
					vertical-align text-bottom
					font-size 12px
					line-height 1
					border-radius 4px
					background $primary
					color white
					margin-right 6px
			.status, .price
				font-size 14px
				line-height 16px
				font-family $exo-bold
				+tablet()
					font-size 12px
			.unit
				color #A2A2A2
				font-size 14px
				line-height 16px
				position relative
				+tablet()
					font-size 12px
				&:hover
					.hint-text
						display inline
			.hint
				margin-left 4px
			.hint-text
				position absolute
				background #383838
				padding 10px
				color white
				max-width 195px
				width max-content
				border-radius 6px
				font-size 14px
				line-height 16px
				margin-top 12px
				margin-left 8px
				display none

.isDeleted
	opacity 0.4

.item-checkbox
	opacity 0
	position absolute

.item-checkbox, .item-checkbox-label {
    cursor: pointer;
		margin-right 5px
}

.item-checkbox + .item-checkbox-label:before {
    content: '';
    background-image: url(/images/fora/icon-checkbox-default.svg);
    display: inline-block;
    width: 24px;
    height: 24px;
		position relative
		z-index 1
}

.item-checkbox:checked + .item-checkbox-label:before {
    content: "";
    display: inline-block;
	  width: 24px;
	  height: 24px;
    background-image: url(/images/fora/icon-checkbox-checked.svg);
}