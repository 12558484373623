@import "~vars"

.not_found-wrapper
	display flex
	align-items center
	justify-content center
	min-height 100%
	height 100%
	background embedurl("../../../img/pattern.svg", "utf8") $primary repeat center
	.not_found-holder
		display flex
		align-items center
		justify-content space-around
		height 100%
		flex-direction column
		width 75%
		background white
		padding 10px
		#logo #logo-svg
			width 175px
		.not_found-image
			+tablet()
				width 100%
		.not_found-text
			text-align center
			font-size 22px
			color: $primary