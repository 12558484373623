@media screen and (max-width: 1024px) {
  .row {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.footer {
  background-color: #595959;
  font-size: 14px;
  padding-bottom: 110px;
  line-height: 24px;
  color: #a2a2a2;
}
.footer.fora {
  padding: 40px 20px;
  font-family: "Lato";
}
.footer.fora .logo-column {
  padding-right: 10px;
}
.footer.silpo {
  padding: 15px 75px 15px 95px;
  font-family: $pfdintextcond;
}
@media screen and (max-width: 1624px) {
  .footer {
    padding: 30px;
  }
}
@media (max-width: 768px) {
  .footer .row .col-3:first-child {
    -webkit-box-ordinal-group: 9;
    -moz-box-ordinal-group: 9;
    -o-box-ordinal-group: 9;
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
    padding: 35px 0 0 0;
  }
}
.logo #logo {
  padding: 0;
}
.logo #logo #logo-svg {
  width: 200px;
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
}
.phone-block {
  margin-top: 35px;
  margin-bottom: 16px;
}
@media screen and (max-width: 1024px) {
  .phone-block {
    margin-bottom: 19px;
  }
}
span.footer-title {
  font-size: 18px;
  font-family: "Lato";
  cursor: text;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  -ms-transition: 1s;
  transition: 1s;
}
span.footer-title:hover {
  color: #fff;
}
.footer-title {
  color: #fff;
  font-size: 16px;
  line-height: 32px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  -ms-transition: 1s;
  transition: 1s;
}
.footer-title.s {
  text-transform: uppercase;
  font-weight: 500;
}
.footer-title:hover {
  text-decoration: none;
  color: #79b928;
  cursor: pointer;
}
.footer-block div a.footer-title {
  font-size: 18px;
}
.footer-widget-title {
  cursor: text;
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  line-height: 18px;
  margin-bottom: 7px;
}
.footer-widget-title.s {
  font-family: $pfdintextcond;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}
.footer-widget-title.s:hover {
  color: #79b928;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  -ms-transition: 1s;
  transition: 1s;
}
.footer-widget-title:hover {
  color: #fff;
}
.footer-phone-number {
  display: block;
  margin-top: 2px;
  font-size: 24px;
  color: #79b928;
  font-weight: 400;
  font-family: "Lato";
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  -ms-transition: 1s;
  transition: 1s;
}
.footer-phone-number:hover {
  color: #fff;
  text-decoration: none;
}
.callback-link {
  display: block;
  margin-bottom: 55px;
  font-family: "Lato";
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  -ms-transition: 1s;
  transition: 1s;
}
@media screen and (max-width: 1024px) {
  .callback-link {
    display: none;
  }
}
.callback-link:hover {
  color: #79b928;
  text-decoration: none;
}
.email-label {
  font-size: 14px;
  margin-bottom: 16px;
  font-family: "Lato";
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  -ms-transition: 1s;
  transition: 1s;
}
@media screen and (max-width: 1024px) {
  .email-label {
    font-size: 18px;
  }
}
.email-label:hover {
  color: #fff;
}
#email-input {
  width: 100%;
  height: 40px;
  border: none;
  padding: 15px 15px 10px;
  color: #969696 !important;
  font-size: 18px;
  font-family: "Lato";
  font-style: italic;
}
@media screen and (max-width: 1024px) {
  .input-block {
    margin: 0 13px;
  }
}
.socials {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 42px;
}
@media screen and (max-width: 1024px) {
  .socials {
    text-align: -webkit-center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 22px auto;
  }
}
.socials .social-link {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: 5px;
  text-decoration: none;
}
.socials .social-link svg {
  width: 35px;
  height: 35px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.socials .social-link:hover svg {
  width: 45px;
  height: 45px;
}
.footer-list {
  list-style: none;
  padding: 0;
  margin-bottom: 29px;
}
.footer-widget:nth-child(2) {
  padding-left: 11.5px;
}
@media screen and (max-width: 1024px) {
  .footer-widget:nth-child(2) {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    padding: 0;
  }
}
.footer-widget:nth-child(3) {
  padding-left: 8px;
}
@media screen and (max-width: 1024px) {
  .footer-widget:nth-child(3) {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -o-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding: 0;
    margin-top: 20px;
  }
}
.footer-widget:nth-child(4) {
  padding-left: 4.5px;
  margin-top: -5px;
}
@media screen and (max-width: 1024px) {
  .footer-widget:nth-child(4) {
    padding: 0 15px;
    margin-top: 15px;
    margin-bottom: 65px;
  }
}
li.footer-list-item {
  font-family: "Lato";
  line-height: 27px;
}
li.footer-list-item.s {
  font-family: $proxima;
}
li.footer-list-item.s a {
  font-weight: 400;
}
li.footer-list-item .footer-list-link:hover {
  color: #fff;
}
.footer .version-link {
  float: right;
  color: #7e7d7d;
  padding: 3px;
}
/*# sourceMappingURL=src/js/components/Layout/Footer/Footer.css.map */