@import "~vars"


.moneybox_container
	margin $paddingXS 0
	padding 25px
	background-color white
	border-radius 5px

	.preview-panel
		display flex
		align-items center
		+media(625px)
			align-items flex-start
		.icon-moneybox, .icon-bonuses
			margin-right 15px
			& > svg
				width 28px
				height 28px
		.title-line
			display flex
			align-items flex-start
			flex-wrap wrap
			+media(625px)
				flex-direction column
			.moneybox-title
				margin 0 25px 0 0
				font-size 22px
				line-height 25px
				+media(625px)
					margin-bottom 10px
				+phone()
					font-size 18px
			.switch-wrap
				display flex
				align-items center
		.icon-chevron-down
			margin-top 7px
			margin-left auto
			cursor pointer
			&.open
				transform rotateX(180deg)


		.moneybox-tooltip
			margin-right 25px
			margin-left 0
			display inline-flex
			+tablet()
				margin-right 15px
			.info-icon.moneybox-tooltip-icon
				size(26px)
				padding-top 4px
				background $color
				position relative
				&:hover
					background $primary
					.tooltip-massage
						display block

			.tooltip-massage
				display none
				width max-content
				max-width 380px
				padding 25px
				border-radius 5px
				background-color $color
				position absolute
				top 50%
				left 100%
				font-size 14px
				font-weight normal
				color white
				z-index 1
				transform translate(20px, -50%)
				+desktop()
					top 100%
					left 50%
					transform translate(-50%, 20px)
				+tablet()
					transform translate(-80%, 20px)
				+media(630px)
					max-width 300px
					transform translate(-50%, 20px)
				+phone()
					max-width 280px
					padding 15px
					font-size 12px

				&:before
					content ""
					position absolute
					top 50%
					left 0
					display block
					transform translate(-95%, -50%)
					border  8px solid transparent
					border-right-color $color
					+desktop()
						top 0
						left 50%
						transform translate(-50%, -95%)
						border-right-color transparent
						border-bottom-color $color
					+tablet()
						left 80%
					+media(630px)
						left 50%

		.moneybox-toggle.react-toggle
			margin-right 25px
			+tablet()
				margin-right 15px

			&.react-toggle--checked
				.react-toggle-thumb
					left 21px
			.react-toggle-track
				width 44px
				height 24px
			.react-toggle-thumb
				size(24px)
				top 0