@import "~vars"


.unused-balance-lines
	margin-top $paddingXS
	padding 25px
	background-color white
	border-radius 5px
	&.open
		.icon-chevron-down
			transform rotateX(180deg)
		.content-line
			max-height 250px
			padding-top 25px

	.title-line
		flexCenter()
		.title
			margin 0 15px 0 0
			font-size 22px
			line-height 1
			cursor pointer
			+phone()
				font-size 18px
		.icon-chevron-down
			cursor pointer

	.content-line
		margin 0
		padding 0 40px
		max-height 0
		overflow-y hidden
		transition all $tdur
		+tablet()
			padding 0 10px

		.balance-line
			margin-bottom 10px
			display flex
			align-items center
			&:last-child
				margin-bottom 0
			&:hover
				cursor pointer

		.balance-line_icon
			size(20px)
			flexCenter()
			.icon-balance
				transition all $tdur
		.balance-line_title
			margin-left 15px
			font-size 24px
			max-width calc(100% - 35px)
			+phone()
				font-size 18px