.tab-item {
  outline: none;
  text-decoration: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: none;
  font-size: 16px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 21px;
  margin-right: 24px;
  padding: 0;
}
.tab-item:last-child {
  margin-right: 0;
}
.tab-item.active {
  color: #79b928;
}
.tab-item.active .tab-item_counter {
  border: 1px solid #79b928;
}
.tab-item .tab-item_counter {
  border: 1px solid #d3d3d3;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 9px;
  margin-left: 8px;
  height: 17px;
  display: inline-block;
}
.filters-chips {
  margin-top: 24px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: fit-content;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.filters-chips:hover {
  background: #f6f7f5;
}
.filters-chips .icon {
  margin-left: 8px;
}
.filters-chips .icon svg {
  width: 12px;
  height: 12px;
}
.filters-chips .icon svg path {
  fill: #000;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/FiltersHeader/FiltersHeader.css.map */