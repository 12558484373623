.purchase-payment-info-wrapper {
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 14px;
  width: 50%;
}
@media (max-width: 768px) {
  .purchase-payment-info-wrapper {
    width: 100%;
    padding: 8px;
  }
}
.purchase-payment-info-wrapper .purchase-payment-type {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 18px;
}
.purchase-payment-info-wrapper .purchase-payment-type .description {
  font-size: 14px;
  line-height: 18px;
}
.purchase-payment-info-wrapper .purchase-payment-type .value {
  font-size: 16px;
  line-height: 18px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.purchase-payment-info-wrapper .purchase-payment-type .value .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.purchase-payment-info-wrapper .purchase-payment-type .value .icon svg {
  width: 16px;
  height: 16px;
}
.purchase-payment-info-wrapper .purchase-payment-type .value span {
  padding-bottom: 2px;
}
.purchase-payment-info-wrapper .devider {
  height: 2px;
  width: 100%;
  background: #f7f7f7;
  margin: 14px 0;
}
@media (max-width: 768px) {
  .purchase-payment-info-wrapper .devider {
    margin: 10px 0;
  }
}
.purchase-payment-info-wrapper .payment-details .payment-details-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-family: "ExoBold", Helvetica, Arial, sans-serif;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .purchase-payment-info-wrapper .payment-details .payment-details-row {
    margin-bottom: 8px;
  }
}
.purchase-payment-info-wrapper .payment-details .payment-details-row:last-child {
  margin-bottom: 0;
}
.purchase-payment-info-wrapper .payment-details .payment-details-row.discount {
  font-family: 'Exo', Helvetica, Arial, sans-serif;
}
.purchase-payment-info-wrapper .payment-details .payment-details-row.discount .value {
  color: #000;
}
.purchase-payment-info-wrapper .payment-details .description,
.purchase-payment-info-wrapper .payment-details .value {
  font-size: 14px;
  line-height: 16px;
}
.purchase-payment-info-wrapper .payment-details .value {
  color: #79b928;
}
.purchase-payment-info-wrapper .payment-details .pay-btn {
  font-size: 14px;
  line-height: 1;
  padding: 3px;
  width: 98px;
  text-transform: none;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  line-height: 19px;
}
.purchase-payment-info-wrapper .payment-details .pay-btn:hover,
.purchase-payment-info-wrapper .payment-details .pay-btn:focus {
  background: #60a51b;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/PurchasesList/components/PurchaseItemPaymentInfo/PurchaseItemPaymentInfo.css.map */