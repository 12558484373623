@media (max-width: 768px) {
  .due-date {
    bottom: unset;
    top: -20px;
  }
}
.history-list {
  margin-top: 5px;
  background: #f2f2f2;
  border-bottom: 1px solid #e5e5e5;
}
.history-list-container .history-list {
  margin-top: -20px;
  margin-bottom: -20px;
  border-bottom-color: transparent;
}
@media (max-width: 768px) {
  .history-list-container .history-list {
    margin-top: 0;
    background-color: transparent;
  }
}
.history-list__li {
  position: relative;
}
.history-list__li.isDisabled {
  pointer-events: none;
}
.history-list__li.isDisabled .history-list__item-toggle {
  visibility: hidden;
}
.history-list__li:not(:first-child):before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  background: #e5e5e5;
  margin: 0;
}
@media (max-width: 768px) {
  .history-list__li:not(:first-child):before {
    margin: 0 24px;
  }
}
@media (max-width: 500px) {
  .history-list__li:not(:first-child):before {
    margin: 0 12px;
  }
}
@media (max-width: 768px) {
  .history-list__li.active:before,
  .history-list__li.active + li:before {
    display: none;
  }
}
.history-list__item {
  padding: 0 24px;
  -webkit-transition: border-color 0.35s;
  -moz-transition: border-color 0.35s;
  -o-transition: border-color 0.35s;
  -ms-transition: border-color 0.35s;
  transition: border-color 0.35s;
}
@media (max-width: 768px) {
  .history-list__item {
    padding: 16px 24px;
  }
}
@media (max-width: 500px) {
  .history-list__item {
    padding: 12px;
  }
}
.history-list__item.active {
  background: #f1f3ef;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .history-list__item.active {
    border: none;
  }
  .history-list__item.active .history-list__item-city,
  .history-list__item.active .history-list__item-total-price,
  .history-list__item.active .history-list__item-toggle {
    color: #79b928;
  }
}
@media (max-width: 500px) {
  .history-list__item.active {
    border: 1px solid #e5e5e5;
  }
}
.history-list__item-head {
  min-height: 72px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 72px;
  cursor: pointer;
  -webkit-transition: color 0.35s;
  -moz-transition: color 0.35s;
  -o-transition: color 0.35s;
  -ms-transition: color 0.35s;
  transition: color 0.35s;
}
@media (max-width: 768px) {
  .history-list__item-head {
    position: relative;
  }
}
@media (hover: hover) {
  .history-list__item-head:hover {
    color: #79b928;
  }
}
.history-list__item-date,
.history-list__item-city,
.history-list__item-check-type,
.history-list__item-total-price,
.history-list__item-total-points,
.history-list__item-toggle {
  display: table-cell;
  vertical-align: middle;
  padding: 24px 20px;
}
@media (max-width: 768px) {
  .history-list__item-date,
  .history-list__item-city,
  .history-list__item-check-type,
  .history-list__item-total-price,
  .history-list__item-total-points,
  .history-list__item-toggle {
    display: block;
  }
}
@media (max-width: 1300px) {
  .history-list__item-check-type {
    display: none;
  }
}
@media (max-width: 768px) {
  .history-list__item-check-type {
    display: block;
  }
}
.history-list__item-date {
  font-size: 15px;
  line-height: 1.2;
  color: #a2a2a2;
  padding-left: 0;
  width: 230px;
}
@media (max-width: 1300px) {
  .history-list__item-date {
    width: 150px;
  }
}
@media (max-width: 768px) {
  .history-list__item-date {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    padding: 0;
  }
}
@media (max-width: 500px) {
  .history-list__item-date {
    font-size: 12px;
  }
}
@media only screen and (max-width: 900) {
  .history-list__item-date > span {
    display: block;
  }
  .history-list__item-date > span:not(:first-child) {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .history-list__item-date > span {
    display: inline-block;
  }
}
.history-list__item-date > span:not(:first-child) {
  margin-left: 15px;
}
@media (max-width: 768px) {
  .history-list__item-city {
    padding-bottom: 25px;
    padding-left: 0;
    padding-top: 8px;
    font-size: 16px;
    float: left;
    width: 55%;
  }
}
@media (max-width: 500px) {
  .history-list__item-city {
    font-size: 15px;
  }
}
.history-list__item-total-price,
.history-list__item-total-points {
  text-align: right;
  font-size: 18px;
  line-height: 1.222222222222222;
  width: 170px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .history-list__item-total-price,
  .history-list__item-total-points {
    margin-left: auto;
    padding: 10px 0 8px 0;
    width: 35%;
  }
}
@media (max-width: 768px) {
  .history-list__item-total-price {
    font-size: 18px;
    font-weight: 600;
  }
}
@media (max-width: 500px) {
  .history-list__item-total-price {
    font-size: 15px;
  }
}
.history-list__item-total-points,
.history-list__item-check-type {
  font-weight: 600;
}
@media (max-width: 768px) {
  .history-list__item-total-points,
  .history-list__item-check-type {
    color: #a2a2a2;
    font-size: 16px;
    padding: 0;
  }
}
@media (max-width: 500px) {
  .history-list__item-total-points,
  .history-list__item-check-type {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .history-list__item-total-points {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .history-list__item-total-points {
    font-size: 13px;
  }
}
.history-list__item-toggle {
  padding: 24px 0;
  width: 42px;
  -webkit-align-self: stretch;
  align-self: stretch;
  -ms-flex-item-align: stretch;
  cursor: pointer;
  text-align: center;
}
@media (max-width: 768px) {
  .history-list__item-toggle {
    margin-right: 0;
    margin-left: auto;
    padding: 17px 0 0;
    text-align: right;
  }
}
.history-list__item-toggle .icon-chevron-down {
  margin: auto;
}
.history-list__item.active .history-list__item-toggle .icon-chevron-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.history-list__item-body {
  padding: 0 20px;
  margin: 0 -20px;
}
@media (max-width: 768px) {
  .history-list__item-body {
    padding: 0;
    margin: 0;
  }
}
/*# sourceMappingURL=src/js/components/BonusHistory/BonusHistory.css.map */