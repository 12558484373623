.personal-page-intro {
  margin-bottom: 20px;
  background-position: calc(84.51443569553805% - -0.345144356955381 * 10%) calc(15% + 25px);
  padding: 15px 0 0 0;
}
@media only screen and (max-width: 600px) {
  .personal-page-intro {
    background-position: calc(84.51443569553805% - -0.345144356955381 * 10%) calc(50% + 25px);
  }
}
.personal-page-intro h1.intro-title {
  padding: 30px 50px;
}
@media (min-width: 770px) and (max-width: 860px) {
  .personal-page-intro h1.intro-title {
    font-size: 40px;
  }
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .personal-page-intro h1.intro-title {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .personal-page-intro h1.intro-title {
    padding: 30px;
  }
}
.personal-page-intro .intro-content {
  width: 100%;
}
@media (max-width: 768px) {
  .personal-page-intro {
    margin: 0 -15px 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
.drop-down .drop-down-title {
  width: 100%;
  position: relative;
  float: right;
}
.personal-page__user_and_card {
  border-top: 1px solid #e6e6e6;
  background: #fff;
  padding: 25px 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 18px;
  line-height: 1.222222222222222;
  min-height: 110px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .personal-page__user_and_card {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: auto;
    font-size: 16px;
    padding: 20px;
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 500px) {
  .personal-page__user_and_card {
    margin-bottom: 20px;
  }
}
.personal-page__user_and_card .user__name {
  max-width: 600px;
  padding-right: 50px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 600px;
  -ms-flex: 1 1 600px;
  flex: 1 1 600px;
}
@media (max-width: 768px) {
  .personal-page__user_and_card .user__name {
    -webkit-flex-basis: auto;
    flex-basis: auto;
    padding-right: 0;
  }
}
.personal-page__user_and_card .user__card {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: right;
}
@media (max-width: 768px) {
  .personal-page__user_and_card .user__card {
    text-align: left;
    margin-top: 20px;
  }
}
.personal-page__user_and_card .card__number {
  margin-left: 15px;
}
.personal-page__history {
  background: #fff;
  padding: 50px;
  margin-top: 50px;
}
.personal-page__history .no-data:before {
  display: none;
}
@media (max-width: 768px) {
  .personal-page__history {
    padding: 25px 15px;
  }
}
.personal-page__history-heading {
  margin: 10px 0 28px;
}
.personal-page__history-btn-all {
  margin: 40px auto 0;
  min-width: 150px;
  display: block;
  max-width: 180px;
}
.personal-page__dropdown-links {
  position: absolute;
  top: 55px;
  right: 20px;
}
@media (max-width: 500px) {
  .personal-page__dropdown-links {
    top: 36px;
  }
}
.personal-page .special-proposals__intro {
  margin-left: 0;
  margin-right: 0;
}
/*# sourceMappingURL=src/js/pages/PersonalPage/PersonalPage.css.map */