@import "~vars"


.modal-overlay .modal-window
	&.cookie-banner-modal
		max-width 900px
		padding 80px
		+tablet()
			max-width 600px
			padding 50px 30px
		+phone()
			padding 50px 15px
		.icon-cross
			size(14px)

		.cookie-banner-closeButton
			+tablet()
				top 15px
				right 15px

.cookie-banner-popup
	margin auto
	letter-spacing 0
	font-size 14px
	color $gray
	+phone()
		font-size 12px
	a
		color black
		text-decoration underline
		&:hover
			color $primary