//@import "nib"
@import "~vars"

.form
	display block
	max-width 340px
	padding 0
	margin 50px auto
	clearfix()
	font-size 14px
	lh(20)

.form-input-hidden
	size(0)
	overflow hidden
	fixed right 100% bottom 100%
	left auto
	top auto
	opacity 0

.form-hint
	font-size 12px
	lh(15)
	//color rgba(#333333, .7)
	color $gray
	margin 9px 0

// Footer
.form__footer
	border-top 1px solid $gray-l
	padding 27px 0
	display flex

.form__footer-status
	margin 9px 0 0

.form__footer-success
	color $primary
	display inline-flex
	align-items center

	.icon-circle-check
		size(20px)
		margin 0 10px 0 0

.form__footer-btn
	margin-left auto


// Form Row
.form-row
	display flex
	align-items flex-start
	margin-left -20px

	> .form-row
	> .form__field
		flex 1 1 100%
		margin-left 20px

	> .inline-row__field
		margin-left 0

.inline-row
	align-items flex-start

	.inline-row__label
		display flex

	.form__input input
		padding 15px 0
		height 59px

.inline-row__label
	padding 17px 15px 20px 0
	margin 0
	flex 1 0 auto

.inline-row__label
.inline-row__buttons
	border-bottom 1px solid $gray

.inline-row__buttons
	margin 0
	display flex
	padding 12px 0
	flex 0 0 70px
	align-items flex-end
	border-bottom 1px solid $gray


.inline-row__button
	padding 0
	size(35px)
	color $gray

	&:disabled
		color rgba($gray, .5)

	&:not(:disabled)
		&:hover
			color $color

// Form Field
.form__field
	margin-bottom 40px


.inline-row__field
	margin-bottom 0
	flex 1 1 auto


// v2
// Form Label
.form__label
	display block
	font-size 12px
	lh(14)
	padding-top @font-size * @line-height
	transition opacity $tdur
	opacity 1
	color $gray

	&.two-line-label.form__label--focusable
		&.focused
			.form__label-value
				transform translate(0, 0)

		.form__label-value
			font-size 12px

			+media(634px)
				white-space normal
				overflow auto
				text-overflow unset
				height 30px

	.form__error + &.form__label--focusable
		margin-top -28px
		opacity 0

		&.form__label--focusable:not(.not-empty)
			opacity 1

	&.form__label--focusable
		pointer-events none

		.form__label-value
			overflow ellipsis
			height 16px
			transition transform $tdur, font-size $tdur
			transform translate(0, 0)
		&.focused .form__label-value
			transform translate(0, 0)!important
		&.form__label--select:not(.not-empty):not(.searchable)
		&.form__label--select.searchable:not(.not-empty):not(.focused)
			.form__label-value
				transform translate(0, 35px)
		&:not(.focused):not(.not-empty)
			.form__label-value
				transform translate(0, 22px)
			&:not(.two-line-label)
				.form__label-value
					font-size 14px
					lh(16)


.form__label-value
	display block


// Form Input
.form__input-holder
	padding-top $errorHeight
	position relative

	.form__error
	.form__label
		margin-top (- $errorHeight)

	&.disabled
		opacity .5
		cursor not-allowed

.form__input-holder--inline
	display flex
	flex-direction column
	padding-top 0

	.form__error
		order 2
		margin-top 6px
		align-items flex-start
		min-height 0

.form__input
	font-size $formFontSize
	line-height $formLineHeight
	color $color

	input
		display block
		width 100%
		color inherit
		font-size inherit
		font-family $font
		line-height inherit
		border 0
		padding 7px 0
		margin 0
		text-align inherit
		&:focus
			outline none

.form__input-border
	height 1px
	background $formBorColor
	display block

	&:before
		content ''
		height 2px
		background $formActiveBorColor
		margin -1px auto 0
		width 0
		display inline-block
		vertical-align top
		transition width $tdur $cubic

	&.focused
	&.not-empty
	&.error
		&:before
			width 100%

	&.disabled
		cursor not-allowed
		opacity .5

	&.error
		&:before
			background $danger

.is-zoomed .form__input-border:not(.is-empty):not(.error):not(.focused)
	height 2px

// Form Textarea
.form__textarea
	position relative
	display block
	//height 174px
	line-height: 25px;
	background-image: linear-gradient(transparent, transparent 24px, #a8a7a7 0px);
	background-size: 100% 25px;

//+laptop()
//  height 199px
//+phone()
//  height 399px


.form__textarea-fake
.form__textarea-input
	white-space pre-line
	font-size inherit
	line-height inherit
	color inherit
	//padding 6px 0
	margin 0
	display block
	border 0
	min-height $formLineHeight + 2
	background none
	letter-spacing .02em

.l-body.ios .form__textarea-fake
.l-body.ios .form__textarea-input
	white-space pre


.form__textarea-input
	resize none
	position absolute
	left 0
	top 0
	size(100%)
	overflow hidden

.form__textarea-fake
	display block
	color transparent
	pre()

	&:after
		content ''
		display inline-block
		size(0)
		overflow hidden


// Form Select
.form__select-holder
	.form__label-value
		padding-right $formArrowWidth

	.Select-placeholder
		color transparent


// Form Checkbox
.form__checkbox-holder
	padding-left 30px

.form__checkbox-label
	display inline-block
	vertical-align top
	width 100%

.form__checkbox--labeled
	margin-left -30px
	float left

.form__checkbox
	display inline-block
	vertical-align middle
	size(20px)
	border-radius 3px
	border 1px solid $gray
	position relative
	cursor pointer

	&:not(:disabled)
		&:hover
			color #e6e6e6
			border-color $primary

	&:disabled
		cursor not-allowed

	&.checked
		color $primary
		border-color @color

		.form__checkbox-icon
			opacity 1
			visibility visible

.form__checkbox-input
	absolute left top
	opacity 0
	size(100%)

.form__checkbox-icon
	absolute left top
	size(100%)
	display flex
	align-items center
	justify-content center
	opacity 0
	visibility hidden


// RADIO BUTTONS
.radio-buttons
	margin-left -15px

.radio-button__holder
	margin 5px 0
	margin-left $gutter
	display inline-block
	min-width 68px
	text-align center

.radio-button
	border 1px solid rgba($color, .3)
	display block
	border-radius 40px
	color rgba($color, .5)
	font-size 13px
	lh(20)
	padding 8px 15px 7px
	cursor pointer

	&.checked
		color $primary !important
		border-color $primary !important

.radio-button__input
	position absolute
	opacity 0

	&:focus + .radio-button
		color rgba($color, .8)
		border-color rgba($color, .5)

.form__input-holder--inline {
	padding 0
}