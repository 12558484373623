@import "~vars"


.modal-overlay .modal-window.recovery-modal
	max-width: $padding * 11;
	.card-recovery-dialog__panel
		margin auto
	.get-card__back-link
		margin 0
		display inline-block
		color: $gray;
		font-size: $fontSize2;
		line-height: 1.285714285714286;
		letter-spacing: .015em;
		&:hover
			color: $primary
			.back-link__icon
				path
					fill $primary
		.back-link__icon
			width: $paddingXXS;
			height: $paddingXXS;
			margin-right: $paddingXXS;
			path
				fill $gray
	.card-recovery-dialog__text
		font-size 16px
		lh($paddingXXS * 2)
		color $gray
		margin $paddingXS 0
	.card-recovery-dialog__ol
		display flex
		margin-left (- $gutter)
		margin-bottom $paddingXXS

		> li
			margin-left $gutter
			flex 1 1 50%
	.panel-footer
		border-top 1px solid $borderColor
		margin-bottom (- $padding / 2)
		padding ($padding/ 2) 0
	.card-recovery-dialog__hint
		font-size $fontSize1
		lh(16)
		color $gray
		margin 0

	.ordered-list
		counter-reset list_item
		margin auto

		> li
			display flex
			margin $paddingXXS 0
			+laptop()
				margin-bottom $paddingTablet * 2

			&:before
				content counter(list_item)
				counter-increment list_item
				font-size $fontSize6
				lh($padding + $paddingXS)
				color $primary

				margin (-1 * $paddingXS) $paddingTablet 0 0
				+tablet()
					font-size 50px
					min-width $gutter
					margin-top $negativeGutter
