.modal-overlay .modal-window.registration-contact-center__dialog {
  max-width: 590px;
}
.registration-contact-center__panel {
  text-align: center;
  color: #6e6d6d;
  min-height: 200px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.registration-contact-center__panel a[href^="tel:"] {
  color: #333;
}
/*# sourceMappingURL=src/js/components/Modals/ContactCenterModal/ContactCenterModal.css.map */