@import "~vars";

	.radio-item
		display flex
		flex-wrap wrap

		.radio-item-tooltip
			.ui-tooltip
				width 280px
		.radio-item-wrapper
			width 100%
			max-width 100%
			padding 4px 0
			&.disabled
				cursor not-allowed
				opacity 0.4
		label
			display inline-flex
			justify-content space-between
			font-size 14px
			font-weight 600
			width 100%
			line-height 16px
			text-align left
			letter-spacing 0
			color black
			padding-left 38px
			font-family $exo-bold
			height 24px
			display flex
			align-items center
			&.selected-label
				font-weight bold
		.radio
			display none
		.radio + label
			position relative
			cursor pointer
		.radio + label:before
			content ""
			position absolute
			left 0
			size(16.5px)
			border 1.5px solid $primary
			border-radius 50%
			background white
			margin 2.25px
		.radio + label:after
			content ""
			position absolute
			top 5.25px
			left 5.25px
			size(13.5px)
			border-radius 50%
			background $primary
			opacity 0
			transition $tdur
		.radio:checked + label:after
			opacity 1
		.radio:focus + label:before
			box-shadow 0 0 0 3px rgba(255,255,0,.7)
		.subitems
			margin-left 30px