.modal-overlay .modal-window.card-add-modal {
  max-width: 590px;
}
@media (max-width: 768px) {
  .modal-overlay .modal-window.card-add-modal {
    padding: 0;
  }
}
.modal-overlay .form.card-add-form .form__field,
.modal-overlay .form.card-activate-form .form__field {
  margin-bottom: 0;
}
.modal-overlay .form.card-add-form h3,
.modal-overlay .form.card-activate-form h3 {
  margin: 0 0 23px;
}
.modal-overlay .form.card-add-form .btn-submit,
.modal-overlay .form.card-activate-form .btn-submit {
  margin-top: 47px;
}
/*# sourceMappingURL=src/js/components/Modals/CardAddModal/CardAddModal.css.map */