@import "~vars"

.bread-crumbs
	color $gray
	font-size 13px
	margin 4px 0
	padding 20px 0 10px 10px
	+phone()
		padding 10px 0 10px 10px

	> li
		vertical-align top
		position relative
		display inline-block

.bread-crumbs-link
	font-family $lato
	a&:hover
		color $color

.icon-bread-crumbs-arrow
	color $gray-line
	vertical-align middle
	margin 0 8px
	size(11px, 10px)
	svg
		size(100%, 100%)