.personal-information__form {
  margin: 22px auto -25px;
  max-width: none;
}
@media (max-width: 768px) {
  .personal-information__form {
    margin: 22px 0 -25px;
  }
}
@media (max-width: 500px) {
  .personal-information__form {
    margin: 0;
  }
}
.personal-information__form h3,
.personal-information__form h1 {
  margin: 0;
}
.personal-information__form hr {
  margin: 25px 0;
}
@media (max-width: 500px) {
  .personal-information__form hr {
    margin: 15px 0;
  }
}
.personal-information__form .form__text {
  margin: 28px 0 11px;
}
@media (max-width: 500px) {
  .personal-information__form .form__text {
    margin: 15px 0 11px;
  }
}
.personal-information__form .form__body {
  max-width: 711px;
}
.personal-information__form .radio-buttons__sex {
  margin-top: 4px;
  margin-bottom: -6px;
}
.personal-information__form .form__footer {
  margin-top: 37px;
}
@media (max-width: 768px) {
  .personal-information__form .form__footer {
    border: none;
    margin-top: 0;
  }
}
.personal-information__form .btn-submit {
  margin-left: auto;
  width: 165px;
}
@media (max-width: 500px) {
  .personal-information__form .btn-submit {
    margin: 0;
  }
}
.personal-information__form .radio-button__holder {
  min-width: 58px;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/js/forms/PersonalInformationForm/PersonalInformationForm.css.map */