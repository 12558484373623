.ui-date-range-picker.purchases-period-filters__control {
  width: 200px;
  min-width: 200px;
  height: 36px;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 6px 8px;
  background: #fff;
}
.ui-date-range-picker.purchases-period-filters__control.filled {
  border: 1px solid #7ab929;
}
.ui-date-range-picker.purchases-period-filters__control.filled .DateInput {
  height: 22px;
  width: 70px;
}
.ui-date-range-picker.purchases-period-filters__control .ui-date-range-placeholder {
  width: 200px;
}
.ui-date-range-picker.purchases-period-filters__control .DateRangePicker_picker {
  z-index: 2;
}
.ui-date-range-picker.purchases-period-filters__control .DateRangePickerInput__withBorder {
  padding: 0;
  width: 184px;
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.ui-date-range-picker.purchases-period-filters__control .DateInput {
  height: 22px;
  width: 82px;
}
.ui-date-range-picker.purchases-period-filters__control .DateInput_input {
  line-height: 16px;
  height: 22px;
  font-family: 'Lato';
  cursor: pointer;
  border: none;
}
.ui-date-range-picker.purchases-period-filters__control .DateInput_input::placeholder {
  color: #5d5c5c;
  font-size: 12px;
  line-height: 16px;
}
.ui-date-range-picker.purchases-period-filters__control .DateInput_input__focused {
  background: #7ab929;
  border-radius: 6px;
  color: #fff;
}
.ui-date-range-picker.purchases-period-filters__control .DateInput_input__focused::placeholder {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.ui-date-range-picker.purchases-period-filters__control .DateRangePickerInput_arrow {
  margin: 0;
  vertical-align: middle;
}
.ui-date-range-picker.purchases-period-filters__control .DateRangePickerInput_arrow:before {
  background: #d9d9d9;
  border-radius: 1px;
  width: 12px;
  height: 1px;
  margin: 0 4px;
}
.ui-date-range-picker.purchases-period-filters__control .DateRangePickerInput_clearDates {
  padding: 6px 6px 9px;
  margin: 0;
  right: -6px;
}
.ui-date-range-picker.purchases-period-filters__control .DateRangePickerInput_clearDates > svg {
  width: 12px;
  height: 12px;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/DatePicker/DatePicker.css.map */