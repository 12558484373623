.no-purchase {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.no-purchase img {
  width: 176px;
  margin: 16px;
}
.no-purchase .no-purchase-text {
  margin: 25px;
  width: 260px;
  font-family: Exo;
  font-size: 16px;
  font-weight: 100;
  text-align: center;
}
.no-purchase .btn.btn-primary {
  margin: 5px;
  width: 130px;
  height: 40px;
  font-family: Exo;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  cursor: pointer;
}
/*# sourceMappingURL=src/js/components/NoPurchase/NoPurchase.css.map */