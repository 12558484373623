@import "~vars"

.otp-form
	max-width 590px
	margin 0

	.otp-form_description
		font-size 16px
		padding-bottom 30px
		+phone()
			font-size 14px

	.otp-form__password-field
		margin-bottom 20px
		position relative
	.otp-form__try-again-btn,
	.otp-form__go_to_password-btn
		position absolute
		right 0
		font-size 11px
		lh(14)
		color $gray
		margin 5px 0 0
		text-decoration underline

		&:hover
			color $color
			text-decoration underline
			cursor pointer
	.otp-form__go_to_password-btn
		left 0
		color $primary
		width fit-content
		&.mobile
			display none
		+phone()
			display none
			&.mobile
				display block
				margin 24px auto 0
				position static

	.btn-submit
		min-width 194px
		margin-top 30px
		+phone()
			display block
			margin 0 auto