.purchase-details {
  font-family: 'Exo', Helvetica, Arial, sans-serif;
}
.purchase-details .purchase-details-general {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 8px;
  padding: 0 14px;
}
@media (max-width: 768px) {
  .purchase-details .purchase-details-general {
    padding: 0 8px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 8px;
  }
}
.purchase-details .repeat-btn-mobile {
  display: none;
  height: 32px;
  text-transform: capitalize;
  padding: 4px 17px;
  width: calc(100% - 16px);
  margin: 16px 8px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.purchase-details .repeat-btn-mobile:hover,
.purchase-details .repeat-btn-mobile:focus {
  background: #60a51b;
}
.purchase-details .repeat-btn-mobile .icon-basket-bold {
  margin-right: 10px;
}
@media (max-width: 500px) {
  .purchase-details .repeat-btn-mobile {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-box;
    display: inline-flex;
  }
}
.purchase-details .cancel-btn-mobile {
  display: none;
  height: 32px;
  width: calc(100% - 16px);
  text-transform: none;
  padding: 9px 12px;
  color: #ef4237;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 37px;
  margin: 0 8px;
  cursor: pointer;
}
.purchase-details .cancel-btn-mobile:hover,
.purchase-details .cancel-btn-mobile:focus {
  background: rgba(0,0,0,0.04);
}
@media (max-width: 500px) {
  .purchase-details .cancel-btn-mobile {
    display: block;
  }
}
.purchase-details .purchase-details-order-title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 14px 14px 4px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  height: 32px;
}
@media (max-width: 768px) {
  .purchase-details .purchase-details-order-title {
    margin: 14px 8px 4px;
  }
}
@media (max-width: 500px) {
  .purchase-details .purchase-details-order-title {
    margin: 0px 8px 4px;
  }
}
.purchase-details .purchase-details-order-title .repeat-btn {
  height: 32px;
  text-transform: capitalize;
  padding: 4px 17px;
  width: auto;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.purchase-details .purchase-details-order-title .repeat-btn:hover,
.purchase-details .purchase-details-order-title .repeat-btn:focus {
  background: #60a51b;
}
.purchase-details .purchase-details-order-title .repeat-btn:disabled {
  background: #808080;
  color: #fff;
}
.purchase-details .purchase-details-order-title .repeat-btn .icon-basket-bold {
  margin-right: 10px;
}
.purchase-details .purchase-details-order-title .repeat-btn .lds-ring {
  width: 31px;
  height: 25px;
}
.purchase-details .purchase-details-order-title .repeat-btn .lds-ring > div {
  width: 25px;
  height: 25px;
}
@media (max-width: 500px) {
  .purchase-details .purchase-details-order-title .repeat-btn {
    display: none;
  }
}
.purchase-details .purchase-details-order-title .cancel-btn {
  margin-right: 32px;
  background: none;
  border-radius: 37px;
  padding: 9px 12px;
  height: 32px;
  color: #ef4237;
  text-transform: none;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
}
.purchase-details .purchase-details-order-title .cancel-btn:hover,
.purchase-details .purchase-details-order-title .cancel-btn:focus {
  background: rgba(0,0,0,0.04);
}
@media (max-width: 500px) {
  .purchase-details .purchase-details-order-title .cancel-btn {
    display: none;
  }
}
.purchase-details .purchase-details-order-title .more-btn {
  padding: 0;
  margin-left: 4px;
  border-radius: 15px;
  cursor: pointer;
}
.purchase-details .purchase-details-order-title .more-btn:hover,
.purchase-details .purchase-details-order-title .more-btn:focus {
  background: #f7f7f7;
}
.purchase-details .purchase-details-order-title .more-actions {
  position: absolute;
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  -webkit-box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.08);
  right: 0;
  top: 42px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1;
}
.purchase-details .purchase-details-order-title .more-actions > button {
  padding: 0;
  height: 24px;
  margin-bottom: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-transform: inherit;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.purchase-details .purchase-details-order-title .more-actions > button:last-child {
  margin-bottom: 0;
}
.purchase-details .purchase-details-order-title .more-actions > button .icon {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  padding: 4.5px;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/PurchasesList/components/PurchaseItemDetails/PurchaseItemDetails.css.map */