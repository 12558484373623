.info-icon__control {
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .info-icon__control {
    display: none;
  }
}
.info-icon {
  width: 19px;
  height: 19px;
  font-size: 15px;
  color: #fff;
  background: #a2a2a2;
  border-radius: 100%;
  font-style: normal;
  cursor: pointer;
  font-family: "Lato";
  text-align: center;
  font-weight: 600;
  padding: 3px;
}
.info-icon:before {
  content: "?";
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 1px;
}
.info-icon:hover {
  background: #79b928;
}
/*# sourceMappingURL=src/js/components/UI/InfoIcon/InfoIcon.css.map */