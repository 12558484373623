@import "~vars"

.proposal-image-item
	position relative
	text-align center
	background white
	transition box-shadow $tdur
	height 368px
	padding 15px 20px !important
	border-radius 5px
	+phone()
		margin 0 -15px


	&.with-shadow
	&:hover
		box-shadow 0 0 15px rgba(black, .13)

	/*&:not(.show-mark):hover
		.proposal-image-item__controls
			opacity 1
			visibility visible

		.proposal-image-item__period
		.proposal-image-item__footer
			filter blur(2px)
			+tablet()
				filter none
*/
	&.show-mark
		.proposal-image-item__change-type-holder
			opacity 1
			visibility visible

		.proposal-image-item__link
			display none

	.product-list__item-promotion-label
		absolute right top
		margin 18px
		z-index 9
		img
			width 47px
			height 53px

// BODY
.proposal-image-item__body
	position relative
	background-position top right
	background-repeat no-repeat
	background-size 80px
	height 100%
	width 100%
	display flex
	flex-direction column
	justify-content space-between

// HEADER
.proposal-image-item__header
	position absolute
	top 0
	left 0
	text-align left
.proposal-image-item__item-image
	height 110px
	margin-top 30px
	img
		margin auto
		max-width 100%
		max-height 110px
.proposal-image-item__period
	font-size 10px
	line-height 1.2
	color #585858
	display flex
	justify-content space-between
	align-items center
	width 100%


// FOOTER
.proposal-image-item__footer
	display flex
	flex-direction column
	justify-content flex-end
	height 165px
	font-size 35px

	.proposal-image-item__footer-first-row
		display flex
		flex-direction row
		padding-bottom 12px

	.proposal-image-item__footer-second-row
		text-align left
		font-size 12px
		lh(12)
		color $gray
		padding 15px 0 0
		display flex
		border-top 1px solid #efefef
		a
			display flex
			align-items center
		.icon.icon-chevron-right
			margin-left 4.5px

.proposal-image-item__reward-holder
		align-self flex-start
		margin-top -4px
		margin-bottom 2px
		padding-right 15px
		font-family $pfdintextcomp
		font-size 35px
		display inline-block
		white-space nowrap
		.proposal-image-item__reward
			font-family Exo
			font-size 32px
			lh(40)
			display block
			margin-right 5px
			vertical-align top
			font-weight 600
			color $primary
			&.proposal-image-item__percent
				lh(70)
		.proposal-image-item__reward-sign
			color $primary
		.proposal-image-item__unit
			color $primary
			text-align right
			font-size 14px
			lh(24)

	.proposal-image-item__description-holder
		flex 1 1 100%
		text-align left
		overflow hidden
		hr
			margin 8px 0 5px

		@media (max-width: 380px)
			overflow-wrap break-word
			max-width 50%

		.proposal-image-item__coupon-description
			//text-align left
			margin 0
			font-family ExoBold

			&.heading2
				font-size 72px
				lh(60)
				margin-bottom 12px
				+desktop()
					max-width 350px
				+tablet()
					max-width 45%
				+phone()
					text-transform none
					font-family $pfdintextcomp
					font-weight $normal
					font-size 22px

			&.heading3
				font-size  14px
				font-weight bold
				text-transform uppercase
				display block
				max-height 60px
				overflow hidden
				line-height 1.25
				-webkit-line-clamp 3
				display -webkit-box
				-webkit-box-orient vertical
		.proposal-image-item__limit-description
			color $gray
			font-size 12px
			lh(16)
			max-height 45px
			text-overflow hidden
			overflow hidden

.proposal-image-item__limit
	color $gray
	font-size 14px
	lh(18)
	margin 0

// CONTROLS
.proposal-image-item__change-type-holder
.proposal-image-item__controls
	absolute left top
	size(100%)
	background rgba(white, .85)
	opacity 0
	visibility hidden
	transition opacity $tdur, visibility $tdur

.proposal-image-item__controls
	+phone()
		opacity 1
		visibility visible
		top auto
		bottom 0
		height calc(100% - 50px)
		background none
		position relative
		margin-top 40px
		padding-top 25px
		box-shadow 0px -2px 0px 0px rgba(0, 0, 0, 0.1)

.proposal-image-item__change-type-holder
	background rgba(white, .60)

.proposal-image-item__link
	absolute left top
	size(100%)
	display flex
	align-items: center;
	justify-content: center;
	flex-direction column
	+tablet()
		position static
		margin-bottom 20px

	.proposal-image-item__link-item
		+tablet()
			order 2
			margin-top 20px
		+phone()
			order 2
			margin-top 20px

	.proposal-image-item__switcher-container
		+tablet()
			min-height 70px
		+phone()
			min-height unset

	.proposal-image-item__switcher-wrapper
		position absolute
		top 60%
		left: 50%;
		transform: translateX(-50%);

		.proposal-image-item__restore
			cursor pointer

			.icon
				margin -2px 0 0 8px
		+tablet()
			position relative
			top 0
			left 0
			transform none
			.proposal-image-item__restore
				display none
		+phone()
			.proposal-image-item__restore
				display none

		.proposal-image-item__switcher-title
			font-size 14px
			font-family $proxima
			margin-bottom 10px

		.proposal-image-item__restore
			margin-top $gutter

.proposal-image-item__link-button
	margin auto
	+tablet()
		display block

.proposal-image-item__change-type
	absolute left bottom 24px
	right 0
	text-align center
	+tablet()
		bottom 3px

.proposal-image-item__change-type-btn
	margin auto
	font-weight $normal
	+phone()
		top 22px

	.icon
		margin -2px 0 0 8px

.proposal-image-item__change-type-radio
	position absolute
	top 0
	left 50%
	transform translateX(-100%)
	margin 23px
	padding 0
	border-radius 100%
	size(24px)
	border 1px solid $primary
	display flex
	+tablet()
		top -15px
		left 10px

	&.marked:before
		background $primary

	&:before
		content ''
		size(12px)
		margin auto
		display block
		border-radius 100%
		background transparent
		transition background $tdur

	&:disabled
		border-color $gray

.proposal-image-item.product-list__item
	+tablet()
		display block

	.proposal-image-item__body
		min-height 100%
		width 100%
		+phone()
			margin-bottom 10px

.proposal-image-item__not-supported
	absolute left top
	size(100%)
	display flex
	align-items center
	justify-content center
	flex-direction column
	+tablet()
		position static

	.snippet
		max-width 255px
		font-family $pfdintextcomp
		text-align center
		margin 26px 0 0 0
		color $color
		font-size 22px
		line-height 1.09

.react-toggle
	touch-action pan-x
	display inline-block
	position relative
	cursor pointer
	background-color transparent
	border 0
	padding 0
	-webkit-touch-callout none
	-webkit-user-select none
	-khtml-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none
	-webkit-tap-highlight-color rgba(0, 0, 0, 0)
	-webkit-tap-highlight-color transparent

	&:hover
		&:not(.react-toggle--disabled)
			.react-toggle-track
				background-color white
				border 1px solid $borderColor

.react-toggle-screenreader-only
	border 0
	clip rect(0 0 0 0)
	height 1px
	margin -1px
	overflow hidden
	padding 0
	position absolute
	width 1px

.react-toggle--disabled
	cursor not-allowed
	opacity 0.5
	-webkit-transition opacity 0.25s
	transition opacity 0.25s

.react-toggle-track
	width 70px
	height 32px
	padding 0
	border-radius 30px
	background-color $borderColor
	-webkit-transition all 0.2s ease
	-moz-transition all 0.2s ease
	transition all 0.2s ease

.react-toggle--checked
	.react-toggle-track
		background-color $primary

	&:hover
		&:not(.react-toggle--disabled)
			.react-toggle-track
				background-color $primary

	.react-toggle-track-check
		opacity 1
		-webkit-transition opacity 0.25s ease
		-moz-transition opacity 0.25s ease
		transition opacity 0.25s ease

	.react-toggle-track-x
		opacity 0

	.react-toggle-thumb
		left 38px
		border-color $primary

.react-toggle-track-check
	position absolute
	width 14px
	height 10px
	top 0px
	bottom 0px
	margin-top auto
	margin-bottom auto
	line-height 0
	left 8px
	opacity 0
	-webkit-transition opacity 0.25s ease
	-moz-transition opacity 0.25s ease
	transition opacity 0.25s ease

.react-toggle-track-x
	position absolute
	width 10px
	height 10px
	top 0px
	bottom 0px
	margin-top auto
	margin-bottom auto
	line-height 0
	right 10px
	opacity 1
	-webkit-transition opacity 0.25s ease
	-moz-transition opacity 0.25s ease
	transition opacity 0.25s ease

.react-toggle-thumb
	transition all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms
	position absolute
	top 1px
	left 1px
	width 30px
	height 30px
	border 1px solid $gray
	border-radius 50%
	background-color #FAFAFA
	-webkit-box-sizing border-box
	-moz-box-sizing border-box
	box-sizing border-box
	-webkit-transition all 0.25s ease
	-moz-transition all 0.25s ease
	transition all 0.25s ease

.proposal-list .proposal-image-item
	padding 20px

.proposal-image-item__not-supported
	absolute left top
	size(100%)
	display flex
	align-items center
	justify-content center
	flex-direction column
	+tablet()
		position static

	.snippet
		max-width 255px
		font-family $pfdintextcomp
		text-align center
		margin 26px 0 0 0
		color $color
		font-size 22px
		line-height 1.09
.proposal-image-item.inactive-proposal
	color grey

	.product-list__item-promotion-label
	.proposal-image-item__item-image
		opacity 0.5
	.proposal-image-item__reward-sign
		color #fb8f97



.proposal-image-item__toggle.react-toggle
	margin-right 25px
	+tablet()
		margin-right 15px

	&.react-toggle--checked
		.react-toggle-thumb
			left 21px
	.react-toggle-track
		width 44px
		height 24px
	.react-toggle-thumb
		size(24px)
		top 0
