//@import "nib"
@import "~vars"

.btn
	outline none
	position relative
	display inline-block
	vertical-align middle
	padding 7px 19px 8px
	border 1px solid transparent
	font-size 12px
	lh(20)
	font-weight $semibold
	text-decoration none
	text-align center
	color $color
	background none
	user-select none
	cursor pointer
	overflow ellipsis
	text-transform uppercase
	letter-spacing .02px
	transition all $tdur
	transition-property width, height, opacity, color, background, opacity, letter-spacing

	&:disabled
	input[type=file]:disabled + &
		cursor not-allowed

	svg
		path
		polygon
		rect
		circle
			transition fill $tdur

a.btn
	text-decoration none

.btn-block
	display block
	width 100%

.btn-primary
.btn-default
.btn-white
.btn-gray
.border-radius
.black
	border-radius 10px 0

// COLORS
.btn-primary
	color white
	background $primary

	&:disabled
		background $disabledBg
		color $disabledColor

	&:not(:disabled)
		&:hover
		&:focus
			background $primaryHover
			color @color

	&.primary-white
		color black
		border-color black
		background white

		&:disabled
			background $disabledBg
			color $disabledColor
			border-color transparent
		&:not(:disabled)
			&:hover
			&:focus
				background white
				color $primary
				border-color $primary


	&.reversed
		$c = @background
		background @color
		color $c

		&:not(:disabled)
			&:hover
			&:focus
				background @background
				color @color

	&.outline
		$c = @background
		background @color // white
		color $c // $primary
		border-color currentColor

		&:not(:disabled)
			&:hover
			&:focus
				$c = @background
				background @color
				color $c
				border-color transparent

	&.btn--loading
		background $primary

.btn--loading
	color transparent !important
	cursor progress

	.btn__loader
		opacity 1
		visibility visible
		transition-delay .2s

.btn__loader
	opacity 0
	visibility hidden
	transition all $tdur
	background url("/images/fora/rolling.svg") no-repeat center
	size(16px)
	absolute left 50% top 50%
	margin -8px

.btn-default
	color white
	background $red

	&:not(:disabled)
		&:hover
		&:focus
			background $colorHover
			color @color

	&.reversed
		$c = @background
		background @color
		color $c

		&:not(:disabled)
			&:hover
			&:focus
				background @background
				color @color

	&.outline
		border-color rgba($color, .37)
		color $color
		background transparent

		&:not(:disabled)
			&:hover
			&:focus
				border-color transparent
				background $color
				color white

	&.btn--loading
		background $color

.btn-white
	color $color
	background white

	&:not(:disabled)
		&:hover
		&:focus
			background $colorHover
			color @color

	&.outline
		border-color #efede8
		color @background
		background transparent

		&:not(:disabled)
			&:hover
			&:focus
				border-color transparent
				background white
				color $color

.btn-gray
	color $color
	background #e5e5e5
	&:disabled
		color rgba(@color, .5)
		opacity .5

	&:not(:disabled)
		&:hover
		&:focus
			background $color
			color white

	&.btn--loading
		opacity 1
		background $color

.btn-filter-view-mode
	padding 0
	border 0
	border-radius 0
	size(37px)
	color rgba($gray, .7)

	&:not(:disabled)
		&:hover
			color #464646

	&.active
		color $primary !important
		cursor default

.btn-filter-view-mode.list-view
	&:not(:disabled)
		color #464646

		&:hover
		&:focus
			color #464646

// SIZE
.btn-large
	padding 16px $padding 14px
	letter-spacing .1em
	font-size 13px
	lh(20)
	+phone()
		padding 13px 25px 11px
		font-size 12px