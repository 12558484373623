.proposal-list {
  margin: 0 0 15px -15px;
}
@media (max-width: 768px) {
  .proposal-list {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 630px) {
  .proposal-list {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.proposal-list > li {
  display: inline-block;
  vertical-align: top;
  padding-left: 15px;
  margin-top: 15px;
  width: 33.33%;
}
@media (max-width: 1600px) {
  .proposal-list > li {
    width: 50%;
    max-width: 50%;
  }
}
@media (max-width: 1023px) {
  .proposal-list > li {
    width: 50%;
    display: inline-block;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 50%;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
  }
}
@media only screen and (max-width: 630px) {
  .proposal-list > li {
    max-width: 100%;
    width: 100%;
  }
}
/*# sourceMappingURL=src/js/components/ProposalsList/ProposalsList.css.map */