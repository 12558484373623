.drop-down {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.428571428571429;
  cursor: pointer;
  color: #333;
  text-align: left;
}
.drop-down.open {
  position: relative;
  z-index: 10;
}
.drop-down.open .icon-caret {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.drop-down.disabled {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  cursor: not-allowed;
}
.drop-down:not(.disabled).open .drop-down-arrow {
  display: block;
}
.drop-down:not(.disabled).open .drop-down-title {
  border-color: #79b928;
}
.drop-down:not(.disabled).open .drop-down-title-value,
.drop-down:not(.disabled):hover .drop-down-title-value,
.drop-down:not(.disabled).open .drop-down-title-caret,
.drop-down:not(.disabled):hover .drop-down-title-caret {
  color: #79b928;
}
.drop-down:not(.disabled).has-value .drop-down-title-value {
  color: #79b928;
}
.drop-down-arrow {
  position: absolute;
  margin: 8px 26px;
  display: none;
}
.drop-down-arrow:before,
.drop-down-arrow:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-style: solid;
  border-width: 6px;
  border-top-width: 0 !important;
  border-color: #e0e0e0 transparent;
}
.drop-down-arrow:after {
  margin: 1px;
  border-color: #fff transparent;
  border-width: 5px;
}
.drop-down--inline {
  font-size: inherit;
  line-height: inherit;
  color: currentColor;
}
.drop-down--inline .drop-down-title {
  border-color: #d6d6d6;
  border-width: 0 0 1px;
  border-radius: 0;
  background: none;
  padding: 0 30px 0 0;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
  color: currentColor;
}
.drop-down--inline:not(.disabled).open .drop-down-arrow {
  display: none;
}
@media (max-width: 1023px) {
  .drop-down--inline:not(.disabled).open .drop-down-arrow {
    display: block;
  }
}
.drop-down--inline:not(.disabled).open .drop-down-title {
  border-bottom-color: #d6d6d6;
}
.drop-down--inline:not(.disabled).open .drop-down-title-value,
.drop-down--inline:not(.disabled):hover .drop-down-title-value,
.drop-down--inline:not(.disabled).open .drop-down-title-caret,
.drop-down--inline:not(.disabled):hover .drop-down-title-caret {
  color: currentColor;
}
.drop-down--inline:not(.disabled).has-value .drop-down-title-value {
  color: currentColor;
}
.dropdown-dottes {
  z-index: 5;
  cursor: pointer;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
}
.dropdown-dottes .drop-down-title {
  background-color: transparent;
  padding: 0;
}
.dropdown-dottes-drop-down .drop-down-title {
  border: none;
}
.dropdown-dottes-drop-down .drop-down-title .drop-down-title-value.drop-down-title-value {
  color: rgba(0,0,0,0.8);
}
.dropdown-dottes-drop-down .drop-down-menu {
  margin-top: 5px;
}
.dropdown-dottes-drop-down .drop-down-menu-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  outline-offset: 0;
  z-index: -1;
}
.dropdown-dottes-drop-down .drop-down-arrow {
  margin-top: 0;
}
.dropdown-dottes__button {
  position: relative;
  padding: 12px;
}
.dropdown-dottes__button:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 8px 0 0 0 #000, -8px 0 0 0 #000;
  box-shadow: 8px 0 0 0 #000, -8px 0 0 0 #000;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.-white .dropdown-dottes__button:before {
  background: #fff;
  -webkit-box-shadow: 8px 0 0 0 #fff, -8px 0 0 0 #fff;
  box-shadow: 8px 0 0 0 #fff, -8px 0 0 0 #fff;
}
.-orange .dropdown-dottes__button:before {
  background: #79b928;
  -webkit-box-shadow: 8px 0 0 0 #79b928, -8px 0 0 0 #79b928;
  box-shadow: 8px 0 0 0 #79b928, -8px 0 0 0 #79b928;
}
/*# sourceMappingURL=src/js/components/DropDown/DropDown.css.map */