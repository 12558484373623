.personal-account-card-form {
  margin: 0;
  max-width: 100%;
}
.personal-account-card-form .form__field {
  margin-bottom: 0;
}
.personal-account-card-form .form__field.has-card_form-field {
  max-width: 340px;
}
.personal-account-card-form .form__field.has-card_form-field .radio-label {
  width: 50%;
}
.personal-account-card-form .form__field.has-card_form-field .radio-label .radio-label__title {
  text-transform: none;
  font-size: 14px;
}
.personal-account-card-form .form__field.barcode_form-field {
  padding-top: 10px;
}
@media only screen and (max-width: 634px) {
  .personal-account-card-form .form__field.barcode_form-field {
    padding-top: 15px;
  }
}
@media only screen and (max-width: 634px) {
  .personal-account-card-form .form__field.barcode_form-field .error-without-label span {
    margin-bottom: -20px;
  }
}
.personal-account-card-form .form__field.barcode_form-field .form__label.form__label--focusable .form__label-value {
  font-size: 12px;
}
@media only screen and (max-width: 634px) {
  .personal-account-card-form .form__field.barcode_form-field .form__label.form__label--focusable .form__label-value {
    white-space: normal;
    overflow: auto;
    -o-text-overflow: unset;
    text-overflow: unset;
    height: 30px;
  }
}
.personal-account-card-form .btn-submit {
  margin-top: 30px !important;
  padding: 9px 52px 6px;
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/CardInformationEntry/CardInformationEntry.css.map */