@import "~vars"

.sharer-wrapper
	position absolute
	top 45px
	right 20px
	background white
	padding 32px
	+tablet()
		right 0
	+phone()
		left 0
		top 20px
		padding 0 20px

	span
		font-size 23px
		line-height 28px
		font-weight 500
		display block
	.sharer-close-button
		position absolute
		right 13px
		top 25px
		+phone()
			display none
	.buttons-row
		display flex
		justify-content space-between
		margin-top 32px
		margin-bottom 24px
	.social-button
		&:hover
			.icon
				path:first-child
					fill-opacity 0.25
		&:focus
			.icon
				path:first-child
					fill-opacity 0.55
		p
			margin 8px 0 0
			font-size 12px
			font-weight 400
	.copy-link
		padding 16px
		background #F6F7F5
		display flex
		cursor pointer
		span
			font-size 14px
			font-weight 400
			color #A2A2A1
			+phone()
				overflow hidden
				text-overflow ellipsis
		.icon
			margin-left 16px
			+phone()
				margin-left auto
	.message
		position absolute
		width 100%
		left 0
		font-weight 500
		font-size 12px
		padding 9px
		text-align center
		line-height 14px
		background $primary
		color white
		+phone()
			background white
			color black
			border-radius 6px
			bottom 8px
			width fit-content
			left 50%
			transform translateX(-50%)
			padding 8px 16px
			box-shadow 0px 4px 8px rgba(0, 0, 0, 0.08)

