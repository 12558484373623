//@import "nib"
//@import "~vars"

.radio-label
	display inline-block
	padding-left 27px

	//&.with-error
	//	padding-top $errorHeight

.radio-label__control
	margin-left -27px
	float left

.radio-label__body
	display flex

.radio-label__title
	font-size 13px
	lh(17)
	display block
	text-transform uppercase
	padding-top 2px
	opacity .7
	//transition opacity $tdur

	&.checked
		opacity 1

.radio-label__content
	display block
	margin-top 3px
	opacity .7


.radio-label__error-holder
	position relative

.radio-label__error
	absolute left bottom
	width 100%
	//height $errorHeight

.radio-label.with-error
	padding-top 28px