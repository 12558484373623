.radio {
  display: inline-block;
  vertical-align: middle;
}
.radio__input {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.radio__input:focus + .radio__icon,
.radio__input:hover + .radio__icon,
.radio__input:checked + .radio__icon {
  border-color: #79b928;
}
.radio__input:checked + .radio__icon:before {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.radio__icon {
  border: 1px solid #999;
  border-radius: 100%;
  width: 17px;
  height: 17px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.radio__icon:before {
  content: '';
  background: #79b928;
  margin: auto;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
/*# sourceMappingURL=src/js/components/Form/Radio/Radio.css.map */