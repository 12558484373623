.layout {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  font-family: "Lato";
}
.container {
  padding-left: 326px;
  width: auto;
  min-height: 100%;
  background: #f2f2f2;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
@media (max-width: 1300px) {
  .container {
    padding-left: 326px;
  }
}
@media (max-width: 500px) {
  .container {
    overflow: hidden;
  }
}
@media only screen and (max-width: 1100px) {
  .container {
    padding-left: 0;
  }
}
.content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 76px 15px 15px 15px;
}
@media only screen and (max-width: 1100px) {
  .content {
    padding: 72px 15px 15px;
  }
}
.row {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.col-3 {
  width: 25%;
  display: inline-block;
}
@media (max-width: 500px) {
  .col-3 {
    width: 100%;
  }
}
.footer-links {
  list-style: none;
}
.back-to-top {
  z-index: 90;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: #79b928;
  position: fixed;
  bottom: 60px;
  right: 60px;
  border-radius: 50%;
  -webkit-transform: translate(110px, 0);
  -moz-transform: translate(110px, 0);
  -o-transform: translate(110px, 0);
  -ms-transform: translate(110px, 0);
  transform: translate(110px, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  -moz-transition: -moz-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  -o-transition: -o-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  -ms-transition: -ms-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  transition: transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
}
@media (max-width: 768px) {
  .back-to-top {
    bottom: 20px;
    width: 42px;
    height: 42px;
  }
}
.back-to-top.show {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media (max-width: 768px) {
  .back-to-top.show {
    right: 20px;
  }
}
.back-to-top i.icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #fff;
  font-size: 28px;
  width: 40px;
  height: 24px;
}
@media (max-width: 768px) {
  .back-to-top i.icon {
    width: 22px;
    height: 16px;
  }
}
.-mobile {
  display: none;
}
@media (max-width: 768px) {
  .-mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .-desktop {
    display: none;
  }
}
/*# sourceMappingURL=src/js/components/Layout/Layout.css.map */