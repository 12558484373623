.intro {
  padding: 50px;
  background: #fff;
  font-family: "Lato";
  min-height: 200px;
}
@media (max-width: 768px) {
  .intro {
    margin: 0 -15px;
  }
}
@media (max-width: 500px) {
  .intro {
    margin-bottom: 20px;
  }
}
.intro h1 {
  margin: 1em 0;
  text-transform: none;
}
.intro-menu {
  margin: 0 0 10px 10px;
  padding: 25px;
  background: #4c515a;
  color: #fff;
}
@media (max-width: 768px) {
  .intro-menu {
    display: none;
  }
}
.intro-menu a {
  margin: 0 10px;
  text-transform: uppercase;
}
.intro-hr {
  width: 28;
  height: 2;
}
.intro-hr:before {
  content: "";
  height: 100%;
  width: 28px;
  display: inline-block;
  vertical-align: top;
  background: currentColor;
}
.intro-text {
  max-width: 475px;
}
.intro {
  font-family: "Lato";
}
@media (max-width: 768px) {
  .intro {
    padding: 30px;
  }
}
.intro nav {
  margin: 0;
}
.intro-menu a {
  font-family: "Lato";
  font-weight: 600;
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -o-transition: 0.35s;
  -ms-transition: 0.35s;
  transition: 0.35s;
}
/*# sourceMappingURL=src/js/components/Intro/Intro.base.css.map */