@import "~vars"

.modal-overlay
	.modal-window.card-add-modal
		max-width 590px
		+tablet()
			padding 0
	.form
		&.card-add-form
		&.card-activate-form
			.form__field
				margin-bottom 0
			h3
				margin 0 0 23px

			.btn-submit
				margin-top 47px