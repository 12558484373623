@import "~vars"

.filters-wrapper
	margin-left 6px
	position relative
.filter-btn
	size(36px)
	border-radius 6px
	border 1px solid #D3D3D3
	padding 9px
	outline none
	text-decoration none
	background none
	user-select none
	cursor pointer
	&.active
		background $primary
		border 1px solid $primary
		.icon
			svg > path
				stroke $white
.filters-list
	position absolute
	z-index 2
	top 44px
	right 0
	width 188px
	padding 24px
	border-radius 10px
	background $white
	box-shadow 0px 4px 24px rgba(0, 0, 0, 0.08)
	span
		font-size 14px
		line-height 16px
	ul
		margin 16px 0 0
