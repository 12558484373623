@import "~vars"

.btn-show-more
	border-radius 0
	background white
	margin-top $gutter
	font-size 22px
	line-height (24 / 22)
	color #848484
	text-transform none
	font-family $pfdintextcomp
	font-weight $normal
	padding 25px 20px 21px
	border 0

	&:hover
		color $primary

	.icon
		margin-left 8px
		margin-right @margin-left
		vertical-align baseline