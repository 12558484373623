@import "~vars"

.ui-date-range-picker.purchases-period-filters__control
	width 200px
	min-width 200px
	height 36px
	border 1px solid #D3D3D3
	border-radius 6px
	padding 6px 8px
	background $white
	&.filled
		border 1px solid #7AB929
		.DateInput
			height 22px
			width 70px
	.ui-date-range-placeholder
		width 200px
	.DateRangePicker_picker
		z-index 2
	.DateRangePickerInput__withBorder
		padding 0
		width 184px
		visibility visible
		opacity 1
	.DateInput
		height 22px
		width 82px
	.DateInput_input
		line-height 16px
		height 22px
		font-family 'Lato'
		cursor pointer
		border none
		&::placeholder
			color #5D5C5C
			font-size 12px
			line-height 16px
	.DateInput_input__focused
		background #7AB929
		border-radius 6px
		color $white
		&::placeholder
			color $white
			font-size 12px
			line-height 16px
			font-weight 400
	.DateRangePickerInput_arrow
		margin 0
		vertical-align middle
		&:before
			background #D9D9D9
			border-radius 1px
			width 12px
			height 1px
			margin 0 4px
	.DateRangePickerInput_clearDates
		padding 6px 6px 9px
		margin 0
		right -6px
		& > svg
			size(12px)


