//@import "nib"
@import "~vars"

.Select
	font-size $formFontSize
	line-height $formLineHeight
	display block

	.Select__placeholder
		color transparent

	.Select__menu
		max-height 380px
		padding 10px 0
		border none
		border-radius 0


	.Select__control
		box-shadow 0 0 0  1px transparent
		border none
		&.Select__control--is-focused:not(.Select__control--menu-is-open)
		&:hover:not(.is-disabled)
			> .Select__control
				//border-color $gray
				box-shadow none
				border none

		&.Select__control--is-focused
		&:hover:not(.is-disabled)
			.icon-select-arrow
				color $color

		&.Select__control--menu-is-open
			//> .Select-control
			//  border-color $gray

			.icon-select-arrow
				transform rotate(180deg)
		.Select__value-container
			padding-left 0

.icon-select-arrow
	color $gray
	transition color .2s

.Select__control
	height auto
	border-radius 0
	border 0 !important
	//border-bottom 1px solid $gray
	background none !important

	.Select__indicators
		cursor pointer
		position relative
		text-align center
		vertical-align middle
		width 55px
		padding-right 5px
		flex-shrink 1
		.ui-select-arrow
			margin-left auto
			display block
		.Select__clear-indicator
			display none
		svg
			width 7px
			height 5px

.Select__placeholder
.Select__single-value > .Select__control .Select__value
	padding 0
	padding-right $formArrowWidth
	line-height $formLineHeight

.Select__input
	padding 0
	height auto

	> input
		padding 0
		font-size $formFontSize
		line-height $formLineHeight
		max-width 100%

	&:empty
		&:before
			content " "
			display inline-block

.Select__placeholder
.Select__single-value > .Select__control .Select__value
.Select__input
	padding-top 7px
	padding-bottom @padding-top


// OPTIONS
.Select-menu-outer
	$bgcolor = white
	$bcolor = #e0e0e0
	border-radius 0
	border 1px solid $bcolor
	box-shadow $boxShadow
	margin-top 10px
	max-height none

	&:before
	&:after
		content ''
		border-width 7px
		absolute right @border-width bottom 100%
		border-style solid
		border-top-width 0 !important
		border-color $bcolor transparent

	&:after
		border-width 6px
		margin-right 1px
		border-color $bgcolor transparent


.Select__option
	background white
	padding 6px 25px
	color $color
	font-size 14px
	lh(20)
	border-radius 0

	&:last-child
		border-radius 0

	&.is-selected
	&.is-focused
		color #573f7b
		background white

.Select.is-disabled
	opacity .5

	&.form__input
		opacity 1

	.Select-control
		cursor not-allowed

	.Select-arrow-zone
		opacity 1

.Select.theme--violet
	&:before
	&:after
		content ''
		position  absolute
		left 0
		bottom -1px
		right 0
		height 1px
		background $formBorColor

	&:after
		height 2px
		background $formActiveBorColor
		width 0
		transition width $tdur $cubic

	&.error
		&:after
			background $danger

	&.has-value
	&.is-focused
	&.error
		&:after
			width 100%

.Select__indicator-separator
	display none

.Select-multi-value-wrapper
	display flex
	justify-content space-between
	align-items baseline

.Select-value-label
	flex 1 0 auto
	display inline-table