@import "~vars"
@import "components/Modal/Modal.styl"

use("stylus.config.js")

fonts(skin)
	"../fonts/" + skin + ".styl"

$project = getProject()

$THEME = json("../../project.config." + $project + ".json", {hash: true})

@import fonts($project)

*
	box-sizing border-box

html,body,#root,.App
	height 100%
	margin 0
	font-family $font

body
	+tablet()
		font-size 13px

a
.a-like
	color $color
	text-decoration none
	transition all $tdur
	cursor pointer

	&:hover
		text-decoration none
		color $primary

	//&:focus
	//	outline thin dotted

	&:hover,
	&:active
		outline 0

a
	text-decoration none
	color inherit

a, button
	&:focus,
	&:hover,
	&:active
		outline none

pre
	white-space pre-wrap
	word-wrap break-word

img
	max-width 100%
	border 0
	-ms-interpolation-mode bicubic
	vertical-align middle


hr
	height 2px
	border 0
	color $primary
	margin $gutter 0
	&::before
		content ''
		height 100%
		width 28px
		display inline-block
		vertical-align top
		background $primary


h1
	font-size 48px
	font-family $font
	+tablet()
		font-size 32px

h2
	font-size 32px
	font-family $font
	line-height (28 / 32)
	+tablet()
			font-size 23px

h3, .heading3
	font-size 23px
	line-height (28 / 32)
	font-family $font
	font-weight normal
	+tablet()
		font-size 22px

h4, .heading4
	font-size 16px
	line-height (20 / 16)
	letter-spacing .02em
	font-family  $font
	font-weight $medium
	text-transform uppercase

p
	font-size 16px
	+phone()
		font-size 12px

.-mobile
	display none
	+tablet()
		display block

.-desktop
	+tablet()
		display none

ul
	list-style none
	padding 0