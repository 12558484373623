@import "~vars"

.radio
	display inline-block
	vertical-align middle

.radio__input
	position absolute
	opacity 0

	&:focus
	&:hover
	&:checked
		+ .radio__icon
			border-color $primary

	&:checked
		+ .radio__icon:before
			opacity 1

.radio__icon
	border 1px solid #999999
	border-radius 100%
	width 17px
	height 17px
	//transition border-color $tdur
	display flex

	&:before
		content ''
		background $primary
		margin auto
		display block
		width 9px
		height 9px
		border-radius 100%
		//transition opacity $tdur $cubic
		opacity 0