.confirm-modal__dialog {
  padding: 70px 0 73px 0;
}
@media (max-width: 500px) {
  .confirm-modal__dialog {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.confirm-modal__heading {
  text-align: center;
}
.confirm-modal__snippet {
  font-size: 16px;
  line-height: 1.375;
  text-align: center;
}
.confirm-modal__snippet:before {
  content: '';
  width: 28px;
  height: 2px;
  background: #79b928;
  display: block;
  margin: 25px auto 21px;
}
.confirm-modal__buttons-group {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 55px 0 0 -20px;
}
.confirm-modal__buttons-group button {
  min-width: 100px;
  margin: 0 0 0 20px;
}
.confirm-modal__buttons-group .button-no {
  background: #fff;
  color: #e72f2a;
  border: 1px #e72f2a solid;
}
/*# sourceMappingURL=src/js/components/Modals/ConfirmModal/ConfirmModal.css.map */