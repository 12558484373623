@import "~vars"

.united-exit-main-form
	max-width unset
	margin-top 0
	.btn-submit
		margin-top 30px
		min-width 194px
	.barcode_form-field
		.error-without-label
			span
				+media(430px)
					margin-bottom -20px
		.form__label.form__label--focusable
			.form__label-value
				+media(430px)
					white-space normal
					overflow auto
					text-overflow unset
					height 32px
	.united-exit__heading
		margin-bottom 30px
	.united-exit__links-wrapper
		margin-top 40px
		display flex
		align-items center
		justify-content center
		flex-wrap wrap
		.united-exit__link-item
			margin 0 15px 40px
	.united-exit__text
		color $color
	.text-divider
		width 100%
		color $gray
		text-align center
		position relative
		display block
		&:before, &:after
			content ""
			position absolute
			top 50%
			left 0
			height 1px
			width 45%
			background-color $gray
		&:after
			left unset
			right 0
	.united-exit-main-form__submit
		margin-top 10px

	.united-exit-main-form__error
		margin-bottom 10px

