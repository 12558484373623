.radio-btn-like-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
}
.radio-btn-like-list .radio-btn-like-item {
  margin: 0 10px 20px 0;
  cursor: pointer;
  display: block;
  padding: 7px 10px;
  text-align: center;
  border: 1px solid #79b928;
  color: #000;
  -webkit-transition: border 0.35s ease 0s;
  -moz-transition: border 0.35s ease 0s;
  -o-transition: border 0.35s ease 0s;
  -ms-transition: border 0.35s ease 0s;
  transition: border 0.35s ease 0s;
  border-radius: 23px;
  font-size: 14px;
  min-width: 140px;
}
.radio-btn-like-list .radio-btn-like-active {
  background: #79b928;
  color: #fff;
}
.radio-btn-like-list .radio-btn-like-disabled {
  border-color: rgba(138,138,138,0.3);
  color: rgba(138,138,138,0.3);
  background-color: #fff;
  cursor: not-allowed;
}
.radio-btn-like-list .radio-btn-like-disabled.radio-btn-like-active {
  background-color: rgba(121,185,40,0.3);
}
/*# sourceMappingURL=src/js/components/RadioBtnLikeList/RadioBtnLikeList.css.map */