@import "~vars"

.mobile-apps-block
	background-color transparent
	padding 0
	display flex
	justify-content center
	.mobile-apps-block__links-wrapper
		justify-content center
		+phone()
			display block
		.mobile-apps-block-link
			&:first-child
				margin-right 40px
		.mobile-apps-block__iOS-link-block
		.mobile-apps-block__android-link-block
			width 154px
	.mobile-apps-block__iOS-link-block-mobile
	.mobile-apps-block__android-link-block-mobile
		width 154px
		a svg
			width 100%
			height auto

	.mobile-apps-block__title
		a
			outline none

			span
				text-transform uppercase
				font-size 13px
				border-bottom 2px solid $gray-line
		font-size 16px
		padding-bottom 10px
		font-family $font

	.mobile-apps-block__qr-code-wrapper
		margin-top 10px

		img
			width 200px
		+phone()
			img
				display none

	.mobile-apps-block__title-custom
		a
			outline none
			border-bottom 2px solid $gray-line
			margin-bottom 10px
			display inline-block
			font-family $font
			text-transform uppercase
			+phone()
				font-size 16px

	.mobile-apps-block__links-wrapper
		display flex
		flex-direction row
		justify-content space-between
		+desktop()
			justify-content space-between

		.mobile-apps-block__iOS-link-block
		.mobile-apps-block__android-link-block
			width 45%
			text-align center


		.mobile-apps-block__iOS-link-block
		.mobile-apps-block__android-link-block
			a
				outline none
			+desktop()
				width 45%
	.mobile-apps-block__links-wrapper
		svg
			width 100%
			height auto
@import projectStylesheet($project)