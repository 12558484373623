.fixed-mark {
  position: fixed;
  right: 0;
  top: 50%;
  margin: -1px 0;
  height: 2px;
  width: 50px;
  background: #f00;
  z-index: 1000;
}
.change-coupon-type-bar-dummy {
  min-height: 84px;
  margin: 25px -50px -50px;
}
@media (max-width: 768px) {
  .change-coupon-type-bar-dummy {
    margin-right: -34px;
    margin-left: -34px;
  }
}
.change-coupon-type-bar.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.change-coupon-type-bar.fixed .change-coupon-type-bar__content {
  margin: 0 15px 0 295px;
}
@media only screen and (max-width: 1100px) {
  .change-coupon-type-bar.fixed .change-coupon-type-bar__content {
    margin: 0 20px;
  }
}
@media (max-width: 768px) {
  .change-coupon-type-bar.fixed .change-coupon-type-bar__content {
    padding: 10px 20px;
    margin: 0;
  }
}
@media only screen and (max-width: 1100px) {
  .change-coupon-type-bar.fixed .change-coupon-type-bar__body {
    min-width: 320px;
  }
}
.change-coupon-type-bar__body {
  max-width: 1920px;
  min-width: 320px;
}
.change-coupon-type-bar__content {
  background: #fbfbfb;
  border-top: 1px solid #ededed;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 23px 50px;
}
@media (max-width: 768px) {
  .change-coupon-type-bar__content {
    padding: 10px 20px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
.change-coupon-type-bar__right-part {
  margin-left: auto;
}
@media (max-width: 768px) {
  .change-coupon-type-bar__right-part {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -o-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}
.change-coupon-type-bar__btn-change-selected,
.change-coupon-type-bar__btn-change-all {
  margin-left: 20px;
  min-width: 180px;
}
@media (max-width: 768px) {
  .change-coupon-type-bar__btn-change-selected,
  .change-coupon-type-bar__btn-change-all {
    min-width: 140px;
    margin: 5px 0 0 20px;
  }
}
@media (max-width: 768px) {
  .change-coupon-type-bar__btn-cancel {
    width: 120px;
    min-width: 120px;
  }
}
.change-coupon-type-bar__message {
  margin-left: auto;
  font-size: 13px;
  line-height: 1.230769230769231;
  text-transform: uppercase;
  font-weight: 600;
  padding: 11px 0;
}
.change-coupon-type-bar__message .icon-check {
  margin-left: 10px;
  margin-top: -3px;
}
/*# sourceMappingURL=src/js/components/ChangeCouponTypeBar/ChangeCouponTypeBar.css.map */