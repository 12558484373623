@import "~vars"

.auth
	width 100%
	padding 70px 70px 80px
	+tablet()
		padding 50px 30px 60px
	.tooltip__create-password
		ul
			list-style disc inside
	.form
		margin 0
		max-width unset
		.form__field
			margin-bottom 20px
		.btn-submit
			width 200px
			margin-top 30px
			+phone()
				margin-left auto
				margin-right auto
				display block
		&.registration-personal-info-form
			margin 0 auto 20px auto

	.modal-title
		margin-bottom 50px
		display flex
		align-items center
		img
			width 45px
			height 45px
			margin-right 14px
	.modal-description
		font-size 16px
		padding-bottom 10px
		+phone()
			font-size 13px
	&.welcome-modal
		.modal-title
			margin-bottom 20px



