.password-form {
  max-width: 590px;
  margin-bottom: 0;
}
.password-form__tooltip {
  width: 200px;
}
.password-form__links-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.password-form__links-wrapper .password-form__forgot-password,
.password-form__links-wrapper .password-form__go_to_otp {
  font-size: 11px;
  margin-left: auto;
  margin-top: 5px;
  line-height: 1.272727272727273;
  color: #a2a2a2;
  text-decoration: underline;
  cursor: pointer;
}
.password-form__links-wrapper .password-form__forgot-password:hover,
.password-form__links-wrapper .password-form__go_to_otp:hover {
  color: #333;
}
.password-form__links-wrapper .password-form__forgot-password .loader,
.password-form__links-wrapper .password-form__go_to_otp .loader {
  margin-top: 5px;
}
.password-form__links-wrapper .password-form__go_to_otp {
  color: #79b928;
  margin-right: auto;
  margin-left: 0;
}
@media (max-width: 500px) {
  .password-form__links-wrapper .password-form__go_to_otp {
    display: none;
  }
}
.password-form__links-wrapper .password-form__go_to_otp.mobile {
  display: none;
}
@media (max-width: 500px) {
  .password-form__links-wrapper .password-form__go_to_otp.mobile {
    display: block;
    margin: 24px auto 0;
  }
}
.password-form-btn {
  min-width: 194px;
  margin-top: 30px;
}
/*# sourceMappingURL=src/js/components/AuthSteps/components/PasswordEntry/PasswordEntry.css.map */