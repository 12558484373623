@import "~vars"

.bonus-protection__form
	margin 22px auto -25px
	max-width none

	.form__field
		max-width 340px

	.form__body
		max-width 711px
	.form__input-holder
		padding-top 0
	hr
		margin 25px 0
	h1
		margin 1em 0
	.form__text
		margin 0 0 5px
		line-height (24 / 16)

	.old-password__hint
		margin 4px 0 -15px
		text-align right

	.old-password__hint-link
		text-decoration underline
		color inherit

	.tooltip__change-password
		width (320px - 2 * 24px)

		ul
			list-style disc
			padding-left 16px

	.form__footer
		margin-top 37px
		.form__footer-btn
			min-width 240px
		+tablet()
			margin-top 0
			border none
		+phone()
			padding-top 0
			display flex
			flex-direction column
			justify-content center
			align-items center
			.form__footer-btn
				margin-left initial
				margin-top 15px
