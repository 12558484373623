@import "~vars"

.bonus-history-filter
	display flex
	justify-content flex-end
	margin-top $gutter
	.button-switch
		width 280px
		height 34px
		border 1px solid $gray-shop
		border-radius 17px
		background-color white
		overflow hidden
		display flex
		max-width 100%
		+phone()
			width 100%
		&.many
			width auto

		.button-switch-item
			background-color white
			border-right 1px solid $gray-shop
			color black
			font-size 14px
			display flex
			align-items center
			justify-content center
			cursor pointer
			flex 1 1 33%
			&:last-child
				border-right initial

			&.active
				background-color $primary
				color white
				cursor unset
			&.disabled
				background $gray
				color white
				cursor not-allowed
				border-right 1px solid white