@import "~vars"


.menu__nav
	display block
	margin auto
	width 100%

	.menu__nav-list
		.menu__nav-link
			display block
			text-transform uppercase
			font-size 14px
			line-height (16 / 13)
			margin 14px 0
			+tablet()
				font-size 13px

			a
				color black
				&:hover
					color $primary

			span
				cursor pointer
				&:hover
					color $primary
				&.active
					color #949494

			.dropdown-title
				display flex
				align-items baseline
				justify-content space-between

				.dropdown-arrow
					svg
						width 9px
						color $gray-dark

			.icon-faq-arrow
				cursor pointer

			&.open
				.icon-faq-arrow
					transform rotate(180deg)

			.menu__nested-nav-list
				max-height 0
				overflow hidden
				transition max-height 0.5s linear

				.menu__nested-nav-link
					margin-left 20px
					text-transform initial
