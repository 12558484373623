.cancel-modal__dialog {
  max-width: 658px;
  display: inline-block;
  height: auto;
  padding: 30px 40px;
}
@media (max-width: 768px) {
  .cancel-modal__dialog {
    padding: 50px 25px;
    display: block;
    max-width: 500px;
  }
}
@media (max-width: 600px) {
  .cancel-modal__dialog {
    padding: 50px 18px 25px;
    display: block;
    height: auto;
    min-height: 100%;
  }
}
@media (max-width: 500px) {
  .cancel-modal__dialog {
    height: auto;
  }
}
.modal-close .icon-cross {
  width: 9px;
  height: 9px;
  color: #000;
}
.cancel-modal__data {
  padding: 0;
}
.cancel-modal__data .cancel-modal__title {
  text-align: center;
  margin-bottom: 0;
  font-family: $proxima;
  font-weight: bold;
  font-size: 22px;
  color: #111;
}
.cancel-modal__data .paid-text {
  text-align: center;
  padding: 20px 0 0;
  max-width: 360px;
  margin: 0 auto;
  color: #373737;
}
.cancel-modal__data .cancel-data_reasons {
  margin: 30px 0;
}
.cancel-modal__data .cancel-modal__button {
  display: block;
  margin: 0 auto;
  text-transform: initial;
  width: 182px;
  font-size: 16px;
  height: 34px;
  padding: 0;
  font-weight: 500;
}
.cancel-modal__data .radio-label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  color: #373737;
}
.cancel-modal__data .radio-label .radio__icon {
  border: 1px solid #e3e3e3;
  width: 18px;
  height: 18px;
  background-color: #efefef;
}
.cancel-modal__data .radio-label .radio__icon:before {
  width: 12px;
  height: 12px;
}
.cancel-modal__data .radio-label .rounded__input {
  width: 100%;
  line-height: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-top: 8px;
  padding: 4px;
}
/*.cancel-order-modal__dialog
	padding 70px 0 73px 0
	+phone()
		padding-right 20px
		padding-left 20px

.cancel-order-modal__heading
	text-align center

.cancel-order-modal__snippet
	font-size 16px
	line-height 1.375
	text-align center

	&:before
		content ''
		width 28px
		height 2px
		background $orange
		display block
		margin 25px auto 21px

.cancel-order-modal__buttons-group
	display flex
	align-items center
	justify-content center
	margin 55px 0 0 -20px

	button
		min-width 100px
		margin 0 0 0 20px

	.button-no
		background: white;
		color: $primaryHover;
		border: 1px $primaryHover solid;*/
/*# sourceMappingURL=src/js/components/Modals/CancelOrderModal/CancelOrderModal.css.map */