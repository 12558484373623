.personal-proposals-panel {
  font-size: 16px;
  color: #333;
  background: #fff;
  padding: 25px 50px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .personal-proposals-panel {
    padding: 15px;
  }
}
.personal-proposals-panel .mobile-apps-block {
  background-color: transparent;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
}
.personal-proposals-panel .mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__iOS-link-block,
.personal-proposals-panel .mobile-apps-block .mobile-apps-block__links-wrapper .mobile-apps-block__android-link-block {
  max-width: 155px;
}
@media (max-width: 500px) {
  .personal-proposals-panel .personal-proposals__heading {
    font-size: 20px;
    line-height: 1;
    margin: 0;
  }
}
@media (max-width: 500px) {
  .personal-proposals-panel .personal-proposals-description {
    font-size: 14px;
  }
}
.personal-proposals-panel .personal-proposals__sub-heading {
  margin: 25px 0 10px 0;
  text-transform: none;
  font-size: 20px;
}
@media (max-width: 500px) {
  .personal-proposals-panel .personal-proposals__sub-heading {
    font-size: 18px;
  }
}
.personal-proposals-panel .divider {
  height: 1px;
  margin: 50px 0;
  background-color: #f2f2f2;
}
@media (max-width: 500px) {
  .personal-proposals-panel .divider {
    margin: 25px 0;
  }
}
.personal-proposals-panel .advice {
  font-weight: normal;
}
@media (max-width: 500px) {
  .personal-proposals-panel .advice {
    color: #333;
    font-size: 14px;
  }
}
.personal-proposals-panel .link__item {
  margin-top: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.personal-proposals-panel .link__item .link__item-icon {
  width: 40px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .personal-proposals-panel .link__item .link__item-icon {
    width: 45px;
  }
}
.personal-proposals-panel .link__item .link__item-text {
  font-family: $proxima;
  font-size: 14px;
  line-height: 17px;
  color: #333;
}
.personal-proposals-panel .underline-hover:hover {
  text-decoration: underline #79b928;
  color: #79b928;
}
.personal-proposals-panel .underline-hover .link__item-title {
  font-size: 12px;
  line-height: 15px;
  color: #a2a2a2;
}
.personal-proposals-panel .underline-hover .link__item-value {
  line-height: 14px;
}
.personal-proposals-panel .advice {
  color: #000;
}
/*# sourceMappingURL=src/js/components/PersonalProposals/PersonalProposals.base.css.map */