@import "~vars";

.purchases-list-item
	height 56px
	width 100%
	border 1px solid #F7F7F7
	border-radius 6px
	margin-bottom 14px
	position relative
	font-family $exo
	color black
	overflow hidden
	&:hover
		background linear-gradient(0deg, #F8F8F8, #F8F8F8), #FFFFFF
	&.isActive
		height auto
		border 1px solid $primary
		&:hover
			background none
	&:last-child
		margin-bottom 0
	+tablet()
		height 92px
	.purchases-item-delivery
		font-size 20px
		line-height 16px
		margin-right 8px
		+tablet()
			font-size 16px
	.purchases-item-datetime
		+tablet()
			display none
	.purchases-item-date, .purchases-item-order
		color #A2A2A2
		font-size 16px
		line-height 16px
		+tablet()
			font-size 14px
	.purchases-item-order
		font-weight 600
		font-family $exo-bold
		span
			font-family sans-serif
	.purchases-item-sum, .purchases-item-sum-mobile
		flex-basis 90px
		text-align center
		font-size 14px
	.purchases-item-sum-mobile
		+tablet()
			margin-bottom 8px
			display flex

	.purchases-item-sum
		+tablet()
			display none

	.purchases-item-status
		padding 3.5px 10px
		border-radius 4px
		font-size 16px
		line-height 19px
		+tablet()
			font-size 14px
			line-height 17px
		&.new
			border 1px solid #FF9500
			color #FF9500
		&.ready
			border 1px solid $primary
			color $primary
		&.refused
			border 1px solid #747474
			color #747474
	.icon-faq-arrow
		color $primary
		position absolute
		top 16px
		right 14px
		size(24px)
		display flex
		align-items center
		justify-content center
		z-index 0
		+tablet()
			top 21px
			display none
		&.isActive
			transform rotate(180deg)

.purchases-list-item-info
	display flex
	align-items center
	flex-wrap wrap
	padding 14px
	z-index 1
	position relative
	cursor pointer
	column-gap 90px
	+desktop()
		column-gap 50px
	+tablet()
		justify-content space-between
		column-gap auto
		align-items baseline
		height 42px
		padding 8px
.purchases-list-item-info-mobile
	display none
	cursor pointer
	+tablet()
		display block
		height 48px
		padding 0 8px 8px
.purchases-item-main
	display flex
	flex-basis 335px
	+tablet()
		flex-basis auto