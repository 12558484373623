@import "~vars"

.card-lock-modal
	vertical-align top

.card-lock-modal__message
	text-align center
	padding 90px 0

.card-lock-modal__heading
	margin 10px 0 47px
	line-height 1.3

.card-lock-modal__buttons
	margin auto

.card-lock-modal__button
	min-width 100px
	margin 0 10px