@import "nib"
@import "~vars"

.nearest-store
	display flex
	justify-content space-between
	align-items flex-start
	padding 11px 0 13px 10px

	.search-block + &
		border-top 1px solid $gray-2
		padding-top 21px
		margin-bottom 10px

.nearest-store__body
	display flex
	justify-content space-between
	align-items flex-start
	flex 1 1 auto
	+phone()
		flex-direction column

.nearest-store__message
	flex 1 1 683px
	padding-right ($padding/ 2)
	margin-bottom: 20px
	+phone()
		flex 1 1 50px



.nearest-store__message-cell
	display table-cell
	height 40px
	vertical-align middle

.nearest-store__login-block
	margin 11px 0 0

.nearest-store__login-link
	position relative

	&:after
		content ''
		absolute left top 100%
		right 0
		height 2px
		background currentColor
		opacity .2

.is-ie .nearest-store__login-link
	text-decoration underline

	&:after
		display none

.nearest-store__btn-holder
	flex 0 0 auto

.nearest-store__btn
	padding 11px 22px 10px
	font-size 12px
	line-height (16 / 12)

.nearest-store__content
	flex 0 0 auto
	margin-left auto

	.search-block
		margin 6px 0 0 25px


.nearest-store__selected
	margin 8px 0 0
	+tablet()
		width 100%

.nearest-stores-drop-down
	margin 0 0 0 5px
	vertical-align top
	white-space nowrap
	+tablet()
		width 100%
		margin 0
		.drop-down-menu
			max-width 100%
	.drop-down-arrow
		display: none!important
	.drop-down-title
		padding-bottom 5px

.nearest-stores-drop-down__store
	cursor pointer
	padding 6px 25px

	&.active
		cursor default

.nearest-stores-drop-down__edit
	text-transform uppercase
	color $gray
	font-size 12px
	lh(15)
	padding 10px 25px 9px
	cursor pointer
	transition color $tdur

	&:hover
		color $color

	.icon-pencil
		margin -2px 7px 0 0
