@import "~vars"

.social-block
	padding 0 30px
	+tablet()
		padding 0 20px


.menu__footer-icon-wrapper
	width 45px
	flex-shrink 0
	+tablet()
		width 40px
		svg
			width 25px


@import projectStylesheet($project)