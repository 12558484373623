.bonus-protection__form {
  margin: 22px auto -25px;
  max-width: none;
}
.bonus-protection__form .form__field {
  max-width: 340px;
}
.bonus-protection__form .form__body {
  max-width: 711px;
}
.bonus-protection__form .form__input-holder {
  padding-top: 0;
}
.bonus-protection__form hr {
  margin: 25px 0;
}
.bonus-protection__form h1 {
  margin: 1em 0;
}
.bonus-protection__form .form__text {
  margin: 0 0 5px;
  line-height: 1.5;
}
.bonus-protection__form .old-password__hint {
  margin: 4px 0 -15px;
  text-align: right;
}
.bonus-protection__form .old-password__hint-link {
  text-decoration: underline;
  color: inherit;
}
.bonus-protection__form .tooltip__change-password {
  width: 272px;
}
.bonus-protection__form .tooltip__change-password ul {
  list-style: disc;
  padding-left: 16px;
}
.bonus-protection__form .form__footer {
  margin-top: 37px;
}
.bonus-protection__form .form__footer .form__footer-btn {
  min-width: 240px;
}
@media (max-width: 768px) {
  .bonus-protection__form .form__footer {
    margin-top: 0;
    border: none;
  }
}
@media (max-width: 500px) {
  .bonus-protection__form .form__footer {
    padding-top: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .bonus-protection__form .form__footer .form__footer-btn {
    margin-left: initial;
    margin-top: 15px;
  }
}
/*# sourceMappingURL=src/js/forms/BonusProtectionForm/BonusProtectionForm.css.map */