@import "~vars"

.drop-down-title
	border 1px solid rgba($color, .5)
	text-transform uppercase
	font-size 13px
	lh(20)
	display block
	width 100%
	background none
	position relative
	padding 8px 31px 7px 18px
	border-radius 20px
	transition border-color $tdur
	text-align left
	cursor inherit
	background white

.drop-down-title-value
	width 100%

.drop-down-title-caret
	absolute right 8px top
	width 23px
	height 100%
	display none
	align-items center
	justify-content center
	+laptop()
		display flex

.drop-down-title-value
.drop-down-title-caret
	transition color $tdur

.no-border .drop-down-title
	border-color transparent