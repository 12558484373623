.cookie-banner {
  width: 100%;
  padding: 20px 30px 15px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 250px));
  grid-template-rows: repeat(2, min-content);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-line-pack: start;
  -webkit-align-content: start;
  align-content: start;
  justify-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 400;
  font-size: 13px;
  letter-spacing: 0;
  text-align: center;
  -webkit-box-shadow: 0px -3px 6px rgba(0,0,0,0.16);
  box-shadow: 0px -3px 6px rgba(0,0,0,0.16);
}
@media (max-width: 768px) {
  .cookie-banner {
    padding: 10px 30px;
  }
}
.cookie-message {
  grid-column: 1/span 2;
}
.cookie-confirm-btn {
  padding: 7px 19px;
  justify-self: end;
}
.cookie-more-btn {
  padding: 7px 19px;
  justify-self: start;
}
/*# sourceMappingURL=src/js/components/CookieBanner/CookieBanner.css.map */