@import "~vars"

.my-contacts-row-form
	max-width none
	margin 0
	position relative

	.form__label
		&.form__label--focusable
			&:not(.focused):not(.not-empty)
				.form__label-value
					transform translate(0, 30px)


	.inline-row__buttons
		padding 0
		position absolute
		border-bottom none
		right 0
		top 0
		display flex
		flex 0 0 70px
		justify-content flex-end


	.form-row.inline-row
		margin-left 0
		input
			padding 0
			height 40px
