.modal-overlay .modal-window.error__dialog {
  max-width: 590px;
}
.modal-overlay .modal-window.error__dialog .error-modal__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.modal-overlay .modal-window.error__dialog .error-modal__wrapper .error-modal__content {
  display: block;
  text-align: center;
  max-width: 500px;
  margin: 15px auto;
  font-size: 14px;
  line-height: 1.428571428571429;
  padding-top: 64px;
  padding-bottom: 64px;
}
@media (max-width: 500px) {
  .modal-overlay .modal-window.error__dialog .error-modal__wrapper .error-modal__content {
    padding: 0;
    margin: 0;
    max-width: none;
    width: 100%;
  }
  .modal-overlay .modal-window.error__dialog .error-modal__wrapper .error-modal__content .button-ok {
    display: block;
    margin: 0 auto;
  }
}
.modal-overlay .modal-window.error__dialog .error-modal__wrapper .error-modal__content .button-ok {
  width: 200px;
  margin-top: 15px;
}
/*# sourceMappingURL=src/js/components/Modals/ErrorModal/ErrorModal.css.map */