@import "~vars"

.create-password-with-email-verification__form
	margin 0
	max-width 450px
	.create-password-with-email-verification_description
		font-size 16px
		margin-bottom 30px
		+phone()
			font-size 14px
	.btn-submit
		margin-top 40px
	.email_from-field
		display flex
		justify-content space-between
		position relative
		align-items flex-end
		margin-bottom 20px
		+phone()
			flex-wrap wrap
		.form__input-holder
			flex 1
			margin 0 20px 0 0
			+phone()
				width 100%
				flex unset
		.email-controls
			flex 1
			display flex
			align-items flex-end
			margin 0 20px 0 0
			.info-icon__control
				.ui-tooltip
					+tablet()
						display block
				.info-icon
					size(25px)
				+tablet()
					display inline-block
				+phone()
					position absolute
					right 20px
					top 28px
			.create-password-with-email__form-btn
				margin-left 20px

				+phone()
					margin-top 30px
					margin-left 0
				.btn
					height 40px
					transition all 0.20s

	.password-form-field
		display flex
		justify-content space-between
		+phone()
			flex-wrap wrap
		.form__input-holder
			&:first-child
				+phone()
					margin-bottom 20px
			flex 1
			margin 0 20px 0 0
			+phone()
				width 100%
	.otp_form-field
		position relative
		margin 0 20px 0 0

		.try-again-btn
			margin-left auto
			margin-top 5px
			text-align: right
			width: 110px
			font-size 11px
			lh(14)
			color $gray
			&:hover
				color $color
				text-decoration underline
				cursor pointer

	.otp_form-field
		.form__input
			&.disabled
				line-height 28px
				margin-top -11px
				+phone()
					line-height 40px
					margin-top -23px
	.form__label
		&.two-line-label
			&.focused
				.form__label-value
					transform translate(0, 0)
			.form__label-value
				font-size 12px
				transform translate(0, 22px)
				height 33px
				white-space normal
				overflow auto
				text-overflow unset
				+phone()
					height 42px
			&.not-empty
				.form__label-value
					transform translate(0, 0)
				+phone()
					height 42px
